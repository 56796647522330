// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/arrow-right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".b-breadcrumb[data-v-3eff512c]{display:flex;flex-wrap:nowrap;align-items:center}@media(max-width:1023.9px){.b-breadcrumb[data-v-3eff512c]{display:none}}.b-breadcrumb__li[data-v-3eff512c]{margin-right:32px;position:relative;list-style:none!important}.b-breadcrumb__li[data-v-3eff512c]:last-child{margin:0}.b-breadcrumb__li[data-v-3eff512c]:last-child:after{display:none}.b-breadcrumb__li[data-v-3eff512c]:after{content:\"\";width:16px;height:16px;display:block;position:absolute;right:-24px;top:50%;margin-top:1px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;transform:translateY(-50%)}.b-breadcrumb__a[data-v-3eff512c]{font-size:14px;line-height:18px;color:#6a7084;pointer-events:none}.b-breadcrumb__a.--active[data-v-3eff512c]{color:#ffa41b;pointer-events:auto}.b-breadcrumb__a.--active[data-v-3eff512c]:hover{color:#091647}.b-breadcrumb__a.--current[data-v-3eff512c]{font-weight:700}", ""]);
// Exports
module.exports = exports;
