<template>
    <div>
        <app-breadcrumb></app-breadcrumb>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <a href="#" @click.prevent="$router.go(-1)" class="back-link">← {{ $t('base.stepBack') }}</a>
                        <head-title class="mb-16">
                            {{ $t('paymentOptions.headline') }}
                        </head-title>
                        <!-- <info-box class="info-box mt-16 align-items-start">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div class="info-box__text">
                                {{ $t('paymentOptions.info') }}
                            </div>
                        </info-box> -->

                        <PaymentOptionsC @selected="setPaymentOption($event)" />

                        <div class="only-mobile">
                            <app-sidebar></app-sidebar>
                        </div>

                        <!-- BTN -->
                        <div class="d-flex justify-content-end mt-48">
                            <form-button @click.prevent="changePaymentMethod()">
                                {{ $t('paymentOptions.continueToVehicle') }}
                            </form-button>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <div class="only-desktop">
                                <app-sidebar></app-sidebar>
                            </div>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppBreadcrumb from '@/components/base/AppBreadcrumb.vue';
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';
import InfoBox from '@/components/InfoBox.vue';
import PaymentOptionsC from '@/components/payment/PaymentOptionsC';
import KlarnaService from '@/utilities/klarna.js';

export default {
    name: 'PaymentOptions',
    components: {
        AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        FormButton,
        InfoBox,
        PaymentOptionsC,
    },
    data() {
        return {
            paymentData: '',
        };
    },
    async mounted() {
        this.setCurrentStep(3);
        this.setCurrentPath('PaymentOptions');
        this.gtmEventNextPage('Payment Options', 3);

        if (this.laterPayment) {
            this.baseGTM('Payment Options');
        } else {
            this.gtmEventNextPage('Payment Options', 3);
        }

        let calculatePrices = await this.calculateTotalPrice();
        //init klarna with options
        let klarna = new KlarnaService({ order: this.getOrderForKlarna(), orderId: null });
        let initKlarna = await klarna.createSession();

        klarna.getPaymentOptions().forEach(method => {
            this.setPaymentOptions(method);
        });
    },
    created() {},
    computed: {
        ...mapState(['laterPayment']),
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        ...mapMutations('order', ['setPaymentMethod', 'setPaymentOptions', 'test']),
        setPaymentOption(e) {
            this.paymentData = e;
        },
        changePaymentMethod() {
            if (this.paymentData) {
                this.setPaymentMethod(this.paymentData);

                //gtm
                this.gtmDeliveryPaymentMethod(3, this.paymentData);

                this.$router.push({ name: 'VehicleInfo' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

.info-box__text {
    @include less-than(md) {
        font-size: 13px;
        line-height: 18px;
    }
}
</style>
