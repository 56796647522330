<template>
    <div>
        Page not found
    </div>
</template>

<script>
export default {
    name: "PageNotFound",
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    computed: {},
    methods: {}
}
</script>

<style lang="scss" scoped></style>
