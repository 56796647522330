<template>
    <div v-if="errors">
        <template v-if="Array.isArray(errors[field])">
            <span class="error-message" :class="{ '--left': align == 'left' }" v-for="(error, index) in errors[field]" :key="index">
                {{ error }}
            </span>
        </template>
        <template v-else>
            <span class="error-message">
                {{ errors[field] }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    name: "FormGroup",
    props: ["errors", "field", "align"],
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    methods: {}
}
</script>

<style lang="scss">
.error-message {
    color: $red;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: flex-end;

    &.--left {
        justify-content: flex-start;
    }
}
</style>
