<template>
    <label class="form-label">
        <slot></slot>
    </label>
</template>

<style lang="scss">
.form-label {
    display: flex;
    align-items: center;
    font-weight: $fwNormal;
    font-size: 16px;
    line-height: 24px;
    color: $darkGray;
    margin: 0 0 8px 0px;
    position: relative;

    @include less-than(md) {
        display: flex;
    }
}

.form-label__opt {
    color: $lightGray;
    margin-left: 0.6rem;
}
</style>
