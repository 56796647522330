<template>
    <div class="b-market-leader">
        <div class="b-market-leader__header">
            <div
                class="b-market-leader__header-stamp"
                :class="{ '--fr': $i18n.locale == 'fr', '--de': $i18n.locale == 'de', '--it': $i18n.locale == 'it', '--es': $i18n.locale == 'es' }"
            >
                <!-- <img src="{{asset('images/icons/stamp.png')}}" srcset="{{asset('images/icons/stamp@2x.png')}} 2x" alt="" /> -->
            </div>
        </div>

        <h4 class="b-market-leader__title">
            {{ $t('marketLeader.title') }}
        </h4>

        <div class="b-market-leader__line">
            <div class="b-market-leader__icon --money-back"></div>

            <div class="b-market-leader__content">
                <strong>
                    {{ $t('marketLeader.item1Title') }}
                </strong>
                <p v-html="$t('marketLeader.item1Text')"></p>
            </div>
        </div>

        <div class="b-market-leader__line">
            <div class="b-market-leader__icon --secure-payment"></div>

            <div class="b-market-leader__content">
                <strong>
                    {{ $t('marketLeader.item2Title') }}
                </strong>
                <p v-html="$t('marketLeader.item2Text')"></p>
            </div>
        </div>

        <div class="b-market-leader__line">
            <div class="b-market-leader__icon --safe-data"></div>

            <div class="b-market-leader__content">
                <strong>
                    {{ $t('marketLeader.item3Title') }}
                </strong>
                <p v-html="$t('marketLeader.item3Text')"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppMarketLeader',
    components: {},
    data() {
        return {};
    },
    mounted() {},
    created() {},
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/market-leader';
</style>
