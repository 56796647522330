<template>
    <section class="section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ul class="b-breadcrumb" v-if="!laterPayment">
                        <li class="b-breadcrumb__li">
                            <router-link :to="{ name: 'CustomerInfo' }" class="b-breadcrumb__a" :class="{ '--active': currentStep >= 2, '--current': currentStep == 1 }">
                                {{ $t("breadcrumb.customerInfo") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li" v-if="!customer.freeDelivery && customer.type != 'S'">
                            <router-link
                                :to="{ name: 'PaymentOptions' }"
                                class="b-breadcrumb__a"
                                :class="{ '--active': currentStep >= 4, '--current': currentStep == 2 || currentStep == 3 }"
                                v-if="customer.freeDelivery"
                            >
                                {{ $t("breadcrumb.deliveryPaymentOptions") }}
                            </router-link>

                            <router-link
                                :to="{ name: 'DeliveryOptions' }"
                                class="b-breadcrumb__a"
                                :class="{ '--active': currentStep >= 4, '--current': currentStep == 2 || currentStep == 3 }"
                                v-else
                            >
                                {{ $t("breadcrumb.deliveryPaymentOptions") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li" v-else-if="!customer.freeDelivery && customer.type == 'S'">
                            <router-link
                                :to="{ name: 'DeliveryOptions' }"
                                class="b-breadcrumb__a"
                                :class="{ '--active': currentStep >= 4, '--current': currentStep == 2 || currentStep == 3 }"
                            >
                                {{ $t("breadcrumb.deliveryOptions") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li" v-else-if="customer.freeDelivery && customer.type != 'S'">
                            <router-link
                                :to="{ name: 'PaymentOptions' }"
                                class="b-breadcrumb__a"
                                :class="{ '--active': currentStep >= 4, '--current': currentStep == 2 || currentStep == 3 }"
                            >
                                {{ $t("breadcrumb.paymentOptions") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li">
                            <router-link :to="{ name: 'VehicleInfo' }" class="b-breadcrumb__a" :class="{ '--active': currentStep >= 5, '--current': currentStep == 4 }">
                                {{ $t("breadcrumb.vehicleInfo") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li" v-if="customer.type != 'S'">
                            <router-link :to="{ name: 'PaymentInfo' }" class="b-breadcrumb__a" :class="{ '--active': currentStep >= 6, '--current': currentStep == 5 }">
                                {{ $t("breadcrumb.payment") }}
                            </router-link>
                        </li>
                    </ul>
                    <ul class="b-breadcrumb" v-else>
                        <li class="b-breadcrumb__li">
                            <router-link :to="{ name: 'LaterPayment' }" class="b-breadcrumb__a" :class="{ '--active': currentStep == 5, '--current': currentStep == 10 }">
                                {{ $t("breadcrumb.paymentOptions") }}
                            </router-link>
                        </li>
                        <li class="b-breadcrumb__li">
                            <router-link :to="{ name: 'PaymentInfo' }" class="b-breadcrumb__a" :class="{ '--current': currentStep == 5 }">
                                {{ $t("breadcrumb.payment") }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex"
export default {
    name: "AppBreadcrumb",
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    computed: {
        ...mapState(["currentStep", "laterPayment"]),
        ...mapState("customer", ["customer"])
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.b-breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @include less-than(md) {
        display: none;
    }
}

.b-breadcrumb__li {
    margin-right: 32px;
    position: relative;
    list-style: none !important;

    &:last-child {
        margin: 0;

        &:after {
            display: none;
        }
    }

    &:after {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        right: -24px;
        top: 50%;
        margin-top: 1px;
        background: url($basePath+"icons/arrow-right.svg") no-repeat center;
        @include translateY(-50%);
    }
}

.b-breadcrumb__a {
    font-size: 14px;
    line-height: 18px;
    color: $lightGray;
    pointer-events: none;

    &.--active {
        color: $orange;
        pointer-events: auto;

        &:hover {
            color: $darkGray;
        }
    }

    &.--current {
        font-weight: $fwBold;
    }
}
</style>
