<template>
    <modal :show-modal="showPaymentModal" @close="setShowPaymentModal(false)">
        <template v-slot:header>
            {{ $t('paymentOptions.paymentMethodChange') }}
        </template>

        <template v-slot:body>
            <info-box class="info-box mt-16 align-items-start">
                <img src="@/assets/images/icons/info-orange.svg" />
                <div>
                    {{ $t('paymentOptions.info') }}
                </div>
            </info-box>

            <PaymentOptionsC @selected="setPaymentOption($event)" />

            <!-- BTN -->
            <div class="d-flex justify-content-end">
                <form-button class="--secondary mt-24" @click="changePaymentMethod()">
                    {{ $t('paymentOptions.changePayment') }}
                </form-button>
            </div>
        </template>
    </modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import InfoBox from '@/components/InfoBox.vue';
import FormButton from '@/components/form/FormButton.vue';
import PaymentOptionsC from '@/components/payment/PaymentOptionsC';
import Modal from './Modal';

export default {
    name: 'ModalPaymentOptions',
    components: {
        Modal,
        InfoBox,
        FormButton,
        PaymentOptionsC,
    },
    props: ['gotopaymentscreen'],
    data() {
        return {
            paymentData: '',
        };
    },
    methods: {
        ...mapMutations(['setShowPaymentModal']),
        ...mapMutations('order', ['setPaymentMethod']),
        setPaymentOption(e) {
            this.paymentData = e;
        },
        changePaymentMethod() {
            if (this.gotopaymentscreen) {
                this.setPaymentMethod(this.paymentData);
                this.setShowPaymentModal(false);

                if (this.paymentData == 'card') {
                    //this.$router.push({ name: "HiPay" })
                    this.goToHiPay(this.orderId);
                } else if (this.paymentData == 'paypal') {
                    this.$router.push({ name: 'Paypal' });
                } else if (this.paymentData == 'bank') {
                    this.$router.push({ name: 'PaymentInfo' });
                }
            } else {
                this.setPaymentMethod(this.paymentData);
                this.setShowPaymentModal(false);
            }
        },
    },
    computed: {
        ...mapState(['orderId']),
        ...mapState(['showPaymentModal']),
        ...mapState('order', ['order']),
    },
    watch: {
        showPaymentModal(newValue) {
            if (newValue == true) {
                this.paymentData = this.order.order.paymentMethod;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.--secondary {
    background: $orange;
}
</style>
