<template>
    <div>
        <app-breadcrumb></app-breadcrumb>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <div v-if="modelName">
                            <head-title>
                                {{
                                    this.$t('selectModelPopup.headline', {
                                        product: selectedProduct.vehicle.name,
                                        model: modelName,
                                        carType: getCarType(),
                                    })
                                }}
                            </head-title>
                            <div class="model-info-steps">
                                <strong>{{ this.$t('selectModelPopup.stepsToFinishHeadline') }}</strong>
                                <div v-html="this.$t('selectModelPopup.stepsToFinish')"></div>
                            </div>
                        </div>
                        <!-- HEADLINE -->
                        <head-title>
                            {{ $t('customer.title') }}
                        </head-title>
                        <paragraph class="mb-0 mb-md-32">
                            {{ $t('customer.subtitle') }}
                        </paragraph>

                        <customer-form></customer-form>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <div class="only-desktop">
                                <app-sidebar></app-sidebar>
                            </div>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import AppBreadcrumb from '@/components/base/AppBreadcrumb.vue';
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import Paragraph from '@/components/headings/Paragraph.vue';
import CustomerForm from '@/components/customer/CustomerForm.vue';
import { isEmpty } from 'lodash';

export default {
    name: 'CustomerInfo',
    props: ['productId', 'locale', 'affiliateId', 'modelName'],
    components: {
        AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        Paragraph,
        CustomerForm,
    },
    data() {
        return {};
    },
    mounted() {
        this.setCurrentStep(1);
        this.setCurrentPath('CustomerInfo');

        if (this.affiliateId) {
            this.setAffiliateId(this.affiliateId);
        } else if (this.getCookie('cocPartner') != null) {
            this.setAffiliateId(this.getCookie('cocPartner'));
        }
    },
    created() {
        if (this.productId != this.order.order.item.productId) {
            this.resetProductItem();
        }

        //priradim productId z url parametra
        if (this.productId) {
            this.setProductId(this.productId);
        }

        //priradim modelName z url parametra
        if (this.modelName) {
            this.setModelName(this.modelName);
        }

        //nacitam vsetky produkty
        this.getProducts();
    },
    computed: {
        ...mapState(['products', 'currentStep', 'selectedProduct']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setProducts', 'setSelectedProduct', 'setCurrentStep', 'setCurrentPath', 'setModelName']),
        ...mapMutations('order', ['setProductId', 'setAffiliateId', 'resetProductItem']),
        isDesktopResolution() {
            return window.innerWidth >= 1024;
        },
        async getProducts() {
            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_PRODUCTS + this.$i18n.locale);

                this.setProducts(response);

                if (!isEmpty(this.getSelectedProduct())) {
                    this.setSelectedProduct(this.getSelectedProduct());

                    //prepocitam cenu
                    this.calculateTotalPrice();

                    //GTM
                    this.gtmCustomerEvent('Customer info', 1);
                } else {
                    throw this.$t('errors.productNotFound');
                }
            } catch (error) {
                this.handleAxiosError(error);
            }
        },
        getSelectedProduct() {
            let productId = this.order.order.item.productId;

            return this.products.filter(product => {
                if (product.id == productId) {
                    return product;
                }
            });
        },
        getCarType() {
            if (this.selectedProduct.type.toLowerCase() == 'pkw') {
                return 'M1';
            } else if (this.selectedProduct.type.toLowerCase() == 'lkw') {
                return 'N1';
            } else if (this.selectedProduct.type.toLowerCase() == 'moto') {
                return 'L';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;

    @include less-than(md) {
        margin-top: 100px;
    }
}

.model-info-steps {
    padding: 24px 0;
    margin: 20px 0;
    border-top: 0.1rem solid $orange;
    border-bottom: 0.1rem solid $orange;

    &::v-deep(ol) {
        margin: 16px 0 0 0;
        padding: 0 0 0 20px;

        li {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;

            span {
                display: block;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}
</style>
