<template>
    <div class="loader" :class="{ '--low': light }">
        <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: "CustomerInfo",
    props: ["light"],
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    watch: {},
    computed: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 1);

    &.--low {
        background: rgba(255, 255, 255, 0.5);
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;

    & .path {
        stroke: rgba(0, 0, 0, 0.2);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
</style>
