<template>
    <div>
        <app-breadcrumb></app-breadcrumb>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <a href="#" @click.prevent="$router.go(-1)" class="back-link">← {{ $t('base.stepBack') }}</a>
                        <head-title>
                            {{ $t('vehicle.headlineData') }}
                        </head-title>
                        <paragraph class="mt-4 mb-32">
                            {{ $t('vehicle.infoFields') }}
                        </paragraph>

                        <!-- VIN -->
                        <form-group class="mb-0" :class="{ '--invalid': issetObj('vin', errors.item) }">
                            <form-label>
                                {{ $t('vehicle.vin') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.vin') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <input type="text" class="form-input" name="vin" v-model="vehicleInfo.vin" maxlength="17" autocomplete="vin" />
                            <form-error-message :errors="errors.item" field="vin"></form-error-message>
                        </form-group>

                        <!-- Type-Approval No -->
                        <form-group
                            v-if="isInDocumentField('ceh')"
                            class="mb-0"
                            :class="{ '--invalid': issetObj('typeApprovalNumber', errors.item), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.typeApprovalNo') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.typeApprovalNumber') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <input type="text" class="form-input" name="type-approval" v-model="typeApproval" />
                            <form-error-message :errors="errors.item" field="typeApprovalNumber"></form-error-message>
                        </form-group>

                        <!-- Color -->
                        <form-group
                            v-if="isInDocumentField('fv')"
                            class="mb-0"
                            :class="{ '--invalid': issetObj('vehicleColor', errors.item), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.color') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.vehicleColor') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <input type="text" class="form-input" name="color" v-model="vehicleColor" />
                            <form-error-message :errors="errors.item" field="vehicleColor"></form-error-message>
                        </form-group>

                        <!-- Seats -->
                        <form-group
                            v-if="isInDocumentField('pm')"
                            class="mb-0"
                            :class="{ '--invalid': issetObj('pm', errors.item), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.numberOfSeats') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.numberOfSeats') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <input type="text" class="form-input" name="seats" v-model="seatsCount" />
                            <form-error-message :errors="errors.item" field="seatsCount"></form-error-message>
                        </form-group>

                        <!-- Engine Number -->
                        <form-group
                            v-if="isInDocumentField('cm')"
                            class="mb-0"
                            :class="{ '--invalid': issetObj('engineNumber', errors.item), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.engineNumber') }}
                            </form-label>
                            <input type="text" class="form-input" name="engine-number" v-model="engineNumber" />
                            <form-error-message :errors="errors.item" field="engineNumber"></form-error-message>
                        </form-group>

                        <!-- GEAR BOX -->
                        <div class="row" v-if="isInDocumentField('tp')">
                            <div class="col-12 col-md-7">
                                <form-group
                                    class="mb-0"
                                    :class="{ '--invalid': issetObj('gearType', errors.item), '--inactive': order.order.sendDocsLater }"
                                >
                                    <form-label>
                                        {{ $t('vehicle.typeOfGearbox') }}
                                    </form-label>
                                    <select class="form-select" name="gearbox" :value="order.order.item.gearType" @change="updateGearType($event)">
                                        <option value="" disabled>{{ $t('base.select') }}</option>
                                        <option value="manual">{{ $t('vehicle.gearbox.manual') }}</option>
                                        <option value="automatic">{{ $t('vehicle.gearbox.automatic') }}</option>
                                    </select>
                                    <form-error-message :errors="errors.item" field="gearType"></form-error-message>
                                </form-group>
                            </div>
                        </div>

                        <!-- Country of first registration -->
                        <div class="row" v-if="isInDocumentField('fcy')">
                            <div class="col-12 col-md-7">
                                <form-group
                                    class="mb-0"
                                    :class="{
                                        '--invalid': issetObj('firstRegistrationCountry', errors.item),
                                        '--inactive': order.order.sendDocsLater,
                                    }"
                                >
                                    <form-label>
                                        {{ $t('vehicle.firstRegistrationCountry') }}
                                    </form-label>
                                    <!-- <select
                                        class="form-select"
                                        name="first-country"
                                        :value="order.order.item.firstRegistrationCountry"
                                        @change="updateFirstCountry($event)"
                                    >
                                        <option value="" disabled>{{ $t('customer.selectCountry') }}</option>
                                        <option v-for="(country, index) in enums.countries" :key="index" :value="country.id">
                                            {{ country.name }}
                                        </option>
                                    </select> -->
                                    <classic-select-box
                                        name="first-country"
                                        @selected="updateFirstRegistrationCountry"
                                        :options="enums.countries"
                                        :allow-search="true"
                                        label-param="name"
                                        value-param="id"
                                        :preselectedOption="preselectedFirstRegistrationCountry"
                                    />
                                    <form-error-message :errors="errors.item" field="firstRegistrationCountry"></form-error-message>
                                </form-group>
                            </div>
                        </div>

                        <!-- Country of next registration -->
                        <div class="row" v-if="isInDocumentField('ncy')">
                            <div class="col-12 col-md-7">
                                <form-group
                                    class="mb-0"
                                    :class="{ '--invalid': issetObj('nextCountry', errors.item), '--inactive': order.order.sendDocsLater }"
                                >
                                    <form-label>
                                        {{ $t('vehicle.countryOfNextRegistration') }}
                                        <form-label-info>
                                            <div class="tooltip-content">
                                                {{ $t('tooltips.countryNextRegistration') }}
                                            </div>
                                        </form-label-info>
                                    </form-label>
                                    <!-- <select
                                        class="form-select"
                                        name="country"
                                        :value="order.order.item.nextCountry"
                                        @change="updateNextCountry($event)"
                                    >
                                        <option value="" disabled>{{ $t('customer.selectCountry') }}</option>
                                        <option v-for="(country, index) in enums.countries" :key="index" :value="country.id">
                                            {{ country.name }}
                                        </option>
                                    </select> -->
                                    <classic-select-box
                                        name="first-country"
                                        @selected="updateNextRegistrationCountry"
                                        :options="enums.countries"
                                        :allow-search="true"
                                        label-param="name"
                                        value-param="id"
                                        :preselectedOption="preselectedNextRegistrationCountry"
                                    />
                                    <form-error-message :errors="errors.item" field="nextCountry"></form-error-message>
                                </form-group>
                            </div>
                        </div>

                        <!-- FIRST REGISTRATION -->
                        <div class="row">
                            <div class="col-12 col-md-7">
                                <form-group class="mb-0" :class="{ '--invalid': issetObj('firstYearRegistration', errors.item) }">
                                    <form-label>
                                        {{ $t('vehicle.firstRegistration') }}
                                        <form-label-info>
                                            <div class="tooltip-content">
                                                {{ $t('tooltips.yearOfFirstRegistration') }}
                                            </div>
                                        </form-label-info>
                                    </form-label>
                                    <select
                                        class="form-select"
                                        name="registration-year"
                                        :value="order.order.item.firstYearRegistration"
                                        @change="updateFirstYearRegistration($event)"
                                    >
                                        <option :value="0" disabled>{{ $t('vehicle.selectYear') }}</option>
                                        <option :value="2">{{ $t('base.unknown') }}</option>
                                        <option :value="1">{{ $t('base.beforeYear') }} {{ minYear }}</option>
                                        <option v-for="(year, index) in registrationYears" :key="index" :value="year">
                                            {{ year }}
                                        </option>
                                    </select>
                                    <form-error-message :errors="errors.item" field="firstYearRegistration"></form-error-message>
                                    <div v-show="oldVehicle">
                                        <info-box class="d-block mt-16 mb-0" v-if="frCountries.includes(customer.deliveryAddress.country)">
                                            {{ $t('base.notGuaranteeFr') }}
                                        </info-box>
                                        <info-box class="d-block mt-16 mb-0" v-html="$t('base.notGuaranteeNonFr')" v-else></info-box>
                                    </div>
                                </form-group>
                            </div>
                        </div>

                        <div v-show="!emptyRequiredFiles">
                            <head-title class="mt-96">
                                {{ $t('vehicle.headlineDocs') }}
                            </head-title>
                            <paragraph class="mt-4">
                                {{ $t('vehicle.infoDocs') }}
                            </paragraph>
                        </div>

                        <!-- COR -->
                        <form-group
                            v-if="isInDocumentField('ktp')"
                            :class="{ '--invalid': issetObj('ktp', errors.documents), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.docs.cor') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docCor') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="ktp"></drag-drop>
                            <form-error-message :errors="errors.documents" field="ktp"></form-error-message>
                        </form-group>

                        <!-- IDENTITY -->
                        <form-group
                            v-if="isInDocumentField('fhs')"
                            :class="{ '--invalid': issetObj('fhs', errors.documents), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.docs.identificationPlate') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docIdentificationPlate') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="fhs"></drag-drop>
                            <form-error-message :errors="errors.documents" field="fhs"></form-error-message>
                        </form-group>

                        <!-- Contract -->
                        <form-group
                            v-if="isInDocumentField('kz')"
                            :class="{ '--invalid': issetObj('kz', errors.documents), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.docs.contract') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docContract') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="kz"></drag-drop>
                            <form-error-message :errors="errors.documents" field="kz"></form-error-message>
                        </form-group>

                        <!-- Mandat FCA -->
                        <form-group
                            v-if="isInDocumentField('mandat_fca')"
                            :class="{ '--invalid': issetObj('mandat_fca', errors.documents), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.docs.mandat_fca') }}
                                <form-label-info>
                                    <div class="tooltip-content" v-html="$t('tooltips.docMandatFca')"></div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="mandat_fca"></drag-drop>
                            <form-error-message :errors="errors.documents" field="mandat_fca"></form-error-message>
                        </form-group>

                        <!-- Passport -->
                        <form-group
                            v-if="isInDocumentField('op')"
                            :class="{ '--invalid': issetObj('op', errors.documents), '--inactive': order.order.sendDocsLater }"
                        >
                            <form-label>
                                {{ $t('vehicle.docs.passport') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docPassport') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="op"></drag-drop>
                            <form-error-message :errors="errors.documents" field="op"></form-error-message>
                        </form-group>

                        <!-- Speedometer -->
                        <form-group v-if="isInDocumentField('fst')" :class="{ '--inactive': order.order.sendDocsLater }">
                            <form-label>
                                {{ $t('vehicle.docs.speedometer') }}
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="fst"></drag-drop>
                            <form-error-message :errors="errors.documents" field="fst"></form-error-message>
                        </form-group>

                        <!-- Conformite -->
                        <form-group v-if="isInDocumentField('vzh')" :class="{ '--inactive': order.order.sendDocsLater }">
                            <form-label>
                                {{ $t('vehicle.docs.conformite') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docConformite') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="vzh"></drag-drop>
                            <form-error-message :errors="errors.documents" field="vzh"></form-error-message>
                        </form-group>

                        <!-- Autocertification -->
                        <form-group v-if="isInDocumentField('piaggio')" :class="{ '--inactive': order.order.sendDocsLater }">
                            <form-label>
                                {{ $t('vehicle.docs.autocertification') }}
                                <form-label-info>
                                    <div class="tooltip-content" v-html="$t('tooltips.docPiaggio')"></div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="piaggio"></drag-drop>
                            <form-error-message :errors="errors.documents" field="piaggio"></form-error-message>
                        </form-group>

                        <!-- VIN number photo -->
                        <form-group v-if="isInDocumentField('vinf')" :class="{ '--inactive': order.order.sendDocsLater }">
                            <form-label>
                                {{ $t('vehicle.docs.vinNumber') }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t('tooltips.docVinNumber') }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="pushFile($event)" @remove-file="removeFile($event)" docType="vinf"></drag-drop>
                            <form-error-message :errors="errors.documents" field="vinf"></form-error-message>
                        </form-group>

                        <!-- MOTO & FR -->
                        <form-group
                            class="mt-12"
                            v-if="selectedProduct.type == 'MOTO' && frCountries.includes(customer.deliveryAddress.country)"
                            :class="{ '--invalid': issetObj('motoFr', errors) }"
                        >
                            <label class="form-checkbox">
                                <input type="checkbox" name="motofr" value="1" class="form-checkbox__input" v-model="motoFr" />
                                <span class="form-checkbox__checkmark"></span>
                                <span class="form-checkbox__label" v-html="$t('vehicle.motoFrCheck')"></span>
                            </label>
                            <form-error-message :errors="errors" field="motoFr"></form-error-message>
                        </form-group>

                        <!-- AUSTRIA -->
                        <form-group class="mt-12" v-if="customer.deliveryAddress.country == 17" :class="{ '--invalid': issetObj('austria', errors) }">
                            <label class="form-checkbox">
                                <input type="checkbox" name="austria" value="1" class="form-checkbox__input" v-model="austria" />
                                <span class="form-checkbox__checkmark"></span>
                                <span class="form-checkbox__label">
                                    {{ $t('vehicle.austriaRegisteredVin') }}
                                </span>
                            </label>
                            <form-error-message :errors="errors" field="austria"></form-error-message>
                        </form-group>

                        <!-- SEND DOCS LATER -->
                        <div v-show="!emptyRequiredFiles">
                            <form-group class="mt-48">
                                <label class="form-switch">
                                    <span class="form-switch__label">
                                        <label class="form-label mb-4 mt-0">{{ $t('vehicle.sendDocsLater') }}</label>
                                        <span class="smaller-text text-lightgray">
                                            {{ $t('vehicle.sendDocsLaterInfo') }}
                                        </span>
                                    </span>
                                    <input
                                        type="checkbox"
                                        name="senddocslater"
                                        value="1"
                                        class="form-switch__input"
                                        v-model="order.order.sendDocsLater"
                                    />
                                    <span class="form-switch__checkmark"></span>
                                </label>
                            </form-group>

                            <div class="vertical-divider mt-64 mb-24"></div>
                        </div>

                        <!-- MERCEDES BENZ -->
                        <form-group class="mt-12" v-if="selectedProduct.id == '37530699'" :class="{ '--invalid': issetObj('mercedesBenz', errors) }">
                            <label class="form-checkbox">
                                <input type="checkbox" name="mercedesBenz" value="1" class="form-checkbox__input" v-model="mercedesBenz" />
                                <span class="form-checkbox__checkmark"></span>
                                <span class="form-checkbox__label" v-html="$t('vehicle.mercedesBenzCheck')"></span>
                            </label>
                            <form-error-message :errors="errors" field="mercedesBenz"></form-error-message>
                        </form-group>

                        <form-group>
                            <form-label>
                                {{ $t('vehicle.message') }}
                            </form-label>
                            <textarea class="form-textarea --big" v-model="vehicleInfo.note"></textarea>
                        </form-group>

                        <form-group class="mb-0" :class="{ '--invalid': issetObj('generalTerms', errors) }">
                            <label class="form-checkbox">
                                <input type="checkbox" name="general-terms" value="1" class="form-checkbox__input" v-model="generalTerms" />
                                <span class="form-checkbox__checkmark"></span>
                                <span class="form-checkbox__label" v-html="$t('vehicle.agreeWithGeneralTerms')"></span>
                            </label>
                            <form-error-message :errors="errors" field="generalTerms"></form-error-message>
                        </form-group>

                        <form-group class="mt-0" v-show="!isSetSendNotify">
                            <label class="form-checkbox mt-16">
                                <input type="checkbox" name="send-notify" value="1" class="form-checkbox__input" v-model="customer.sendNotify" />
                                <span class="form-checkbox__checkmark"></span>
                                <span class="form-checkbox__label" v-html="$t('vehicle.agreeWithPersonalData')"></span>
                            </label>
                        </form-group>

                        <div class="car-manual">
                            <div class="form-radio__popular">
                                <span>{{ $t('carManual.new') }}</span>
                            </div>
                            <div class="car-manual__image"></div>
                            <div class="car-manual__content">
                                <strong>{{ $t('carManual.title') }}</strong>
                                <span>
                                    {{ $t('carManual.desc') }}
                                </span>
                                <form-group class="mt-0 mb-0">
                                    <label class="form-checkbox">
                                        <input type="checkbox" name="carmanual" value="1" class="form-checkbox__input" v-model="carmanual" />
                                        <span class="form-checkbox__checkmark"></span>
                                        <span class="form-checkbox__label" v-html="$t('carManual.checkbox')"></span>
                                    </label>
                                </form-group>
                            </div>
                        </div>

                        <div class="only-mobile">
                            <app-sidebar></app-sidebar>
                        </div>

                        <!-- BTN -->
                        <div class="d-flex justify-content-end mt-48">
                            <form-button
                                @click="saveData()"
                                :loading="sendingForm"
                                v-if="customer.type != 'S' && order.order.paymentMethod != 'bank'"
                            >
                                {{ $t('vehicle.finishAndPay') }}
                            </form-button>
                            <form-button @click="saveData()" :loading="sendingForm" v-else-if="order.order.paymentMethod == 'bank'">
                                {{ $t('vehicle.finishAndPayViaBankTransfer') }}
                            </form-button>
                            <form-button @click="saveData()" :loading="sendingForm" v-else>
                                {{ $t('vehicle.finish') }}
                            </form-button>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <div class="only-desktop">
                                <app-sidebar></app-sidebar>
                            </div>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppBreadcrumb from '@/components/base/AppBreadcrumb.vue';
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';
import FormGroup from '@/components/form/FormGroup.vue';
import FormLabel from '@/components/form/FormLabel.vue';
import FormLabelInfo from '@/components/form/FormLabelInfo.vue';
import Paragraph from '@/components/headings/Paragraph.vue';
import DragDrop from '@/components/dragdrop/DragDrop';
import FormErrorMessage from '@/components/form/FormErrorMessage.vue';
import InfoBox from '@/components/InfoBox.vue';
import ClassicSelectBox from '@/components/select/ClassicSelectBox';
import { isEmpty } from 'lodash';

export default {
    name: 'VehicleInfo',
    components: {
        AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        FormButton,
        FormGroup,
        FormLabel,
        FormLabelInfo,
        Paragraph,
        DragDrop,
        FormErrorMessage,
        InfoBox,
        ClassicSelectBox,
    },
    data() {
        return {
            errors: {},
            sendingForm: false,
            registrationYears: [],
            minYear: 0,
            austria: false,
            motoFr: false,
            mercedesBenz: false,
            frCountries: [79, 100, 92, 99, 81, 82, 283, 325, 286],
            generalTerms: false,
            //privacyPolicy: false,
            files: [],
            oldVehicle: false,
            emptyRequiredFiles: false,
            isSetSendNotify: false,
            preselectedFirstRegistrationCountry: null,
            preselectedNextRegistrationCountry: null,
            vehicleInfo: {
                vin: '',
                note: '',
                firstRegistrationCountry: '',
                nextRegistrationCountry: '',
            },
            carmanual: false,
        };
    },
    mounted() {
        this.setCurrentStep(4);
        this.setCurrentPath('VehicleInfo');
        this.gtmEventNextPage('Vehicle info', 4);
    },
    created() {
        if (this.order.order.item.vin) {
            this.vehicleInfo.vin = this.order.order.item.vin;
        }

        if (this.order.order.clientNote) {
            this.vehicleInfo.note = this.order.order.clientNote;
        }

        if (this.order.order.item.firstRegistrationCounty) {
            this.vehicleInfo.firstRegistrationCountry = this.order.order.item.firstRegistrationCountry;
        }

        if (this.order.order.item.nextRegistrationCountry) {
            this.vehicleInfo.nextRegistrationCountry = this.order.order.item.nextRegistrationCountry;
        }

        if (this.customer.sendNotify) {
            this.isSetSendNotify = true;
        }

        if (this.selectedProduct.type == 'PKW') {
            this.minYear = 1996;
        } else if (this.selectedProduct.type == 'LKW') {
            this.minYear = 2010;
        } else {
            this.minYear = 2005;
        }

        if (this.selectedProduct.firstRegistrationYear) {
            this.minYear = parseInt(this.selectedProduct.firstRegistrationYear, 10);
        }

        let actualYear = parseInt(new Date().getFullYear(), 10);

        for (let i = actualYear; i >= this.minYear; i--) {
            this.registrationYears.push(i);
        }

        //skontrolujem ci su nejake potrebne dokumenty
        if (this.selectedProduct.requiredOrderAttributes.length == 0) {
            this.emptyRequiredFiles = true;
        } else {
            let files = this.selectedProduct.requiredOrderAttributes.filter(function(elem) {
                if (elem.type == 'file') return elem;
            });

            if (files.length == 0) {
                this.emptyRequiredFiles = true;
            }
        }
    },
    computed: {
        ...mapState(['appLocale', 'currentStep', 'enums', 'selectedProduct', 'selectedInvoiceCountry', 'tmpId', 'modelName']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
        vin: {
            get() {
                return this.order.order.item.vin;
            },
            set(value) {
                this.setVin(value);
            },
        },
        typeApproval: {
            get() {
                return this.order.order.item.typeApprovalNumber;
            },
            set(value) {
                this.setTypeApprovalNumber(value);
            },
        },
        vehicleColor: {
            get() {
                return this.order.order.item.vehicleColor;
            },
            set(value) {
                this.setVehicleColor(value);
            },
        },
        seatsCount: {
            get() {
                return this.order.order.item.seatsCount;
            },
            set(value) {
                let val;

                if (value) val = parseInt(value, 10);
                else val = value;

                this.setSeatsCount(val);
            },
        },
        engineNumber: {
            get() {
                return this.order.order.item.engineNumber;
            },
            set(value) {
                this.setEngineNumber(value);
            },
        },
        clientNote: {
            get() {
                return this.order.order.clientNote;
            },
            set(value) {
                this.setClientNote(value);
            },
        },
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setOrderId', 'setCurrentPath']),
        ...mapMutations('order', [
            'setVin',
            'setTypeApprovalNumber',
            'setRegistrationYear',
            'setClientNote',
            'setDate',
            'setVehicleColor',
            'setSeatsCount',
            'setEngineNumber',
            'setGearType',
            'setFirstRegistrationCountry',
            'setNextCountry',
            'setDocs',
            'setClientId',
        ]),
        updateFirstRegistrationCountry(val) {
            this.vehicleInfo.firstRegistrationCountry = val;
        },
        updateNextRegistrationCountry(val) {
            this.vehicleInfo.nextRegistrationCountry = val;
        },
        removeFile(removedFile) {
            //odstranim file z pola
            let index = this.files.findIndex(file => file.name === removedFile.name && file.type === removedFile.type);
            this.files.splice(index, 1);
        },
        pushFile(files) {
            for (var i = 0; i < files.length; i++) {
                var uploadedFile = files[i];

                //ak objekt existuje v poli, nepridam ho
                if (this.files.some(file => file.name === uploadedFile.name && file.type === uploadedFile.type)) {
                    console.log('Object found inside the files.');
                } else {
                    this.files.push(uploadedFile);
                }
            }
        },
        isInDocumentField(type) {
            let isInArray = this.selectedProduct.requiredOrderAttributes.find(o => o.name === type);

            return isInArray;
        },
        updateGearType(e) {
            let value = e.target.value;
            this.setGearType(value);
        },
        updateNextCountry(e) {
            let value = e.target.value;
            this.setNextCountry(value);
        },
        updateFirstCountry(e) {
            let value = e.target.value;
            this.setFirstRegistrationCountry(value);
        },
        updateFirstYearRegistration(e) {
            let value = e.target.value;
            this.setRegistrationYear(parseInt(value, 10));

            if (e.target.value == 1) {
                this.oldVehicle = true;
            } else {
                this.oldVehicle = false;
            }
        },
        async saveData() {
            this.sendingForm = true;

            //nasetuj vin
            this.setVin(this.vehicleInfo.vin);

            //nasetuj firstReg country
            this.setFirstRegistrationCountry(this.vehicleInfo.firstRegistrationCountry.toString());

            //nasetuj nextReg country
            this.setNextCountry(this.vehicleInfo.nextRegistrationCountry.toString());

            //nasetuj poznamku
            this.setClientNote(this.vehicleInfo.note);

            //nasetuj date
            this.setDate(new Date().toISOString().slice(0, 10));

            //nasetuj dokumenty
            this.setDocs(this.files);

            //resetnem errors
            this.errors = {};

            //ulozim data
            try {
                const order = await this.validateOrder();
                const user = await this.createUser();
                const orderCreate = await this.createOrder();
                const redirectUser = await this.goToNextStep(orderCreate);
            } catch (error) {
                //console.log(error.message);
                if (error && error.message) {
                    this.handleAxiosError(error.message);
                }
            }

            this.sendingForm = false;
        },
        async validateOrder() {
            try {
                let validateData = {
                    lang: this.$i18n.locale,
                    data: this.order.order,
                    fields: {},
                };

                validateData.data.docTmpId = this.tmpId;

                let response = await this.$axios.post(process.env.VUE_APP_URL_ORDER_VALIDATE, validateData);

                if (response.status == '400') {
                    this.errors = response.errors;
                    this.sendErrorsToGTM(this.errors, 4);
                    this.checkRequiredCheckboxes();
                    this.showValidationMessages();
                    throw '';
                } else if (response.status == '200') {
                    this.checkRequiredCheckboxes();

                    if (!isEmpty(this.errors)) {
                        this.showValidationMessages();
                        throw '';
                    } else {
                        if (this.customer.sendNotify) {
                            this.newsletterGTM();
                        }

                        return response;
                    }
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        async createUser() {
            let data = this.copyObj(this.customer);

            //doplnim prefix do vatId
            if (this.customer.personType == 'company' && this.customer.invoiceData.vatId) {
                data.invoiceData.vatId = this.selectedInvoiceCountry.vatCode + this.customer.invoiceData.vatId;
            }

            //parsujem cislo kvoli backendu
            data.registred = parseInt(data.registred);

            try {
                let response = await this.$axios.post(process.env.VUE_APP_URL_USER_CREATE, data);
                if (response.status == '200') {
                    //ulozim id
                    this.setClientId(response.data.id);

                    return response;
                } else {
                    throw `${this.$t('errors.somethingWentWrong')}: ${process.env.VUE_APP_URL_USER_CREATE}`;
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        async createOrder() {
            try {
                //vytvaram objednavku
                let validateData = this.copyObj(this.order.order);
                validateData.docTmpId = this.tmpId;
                validateData.gaId = this.getCookie('_ga');

                if (this.modelName != null) {
                    validateData.clientNote = `Model: ${this.selectedProduct.vehicle.name} ${this.modelName}; ${validateData.clientNote}`;
                }

                if (this.carmanual) {
                    validateData.clientNote = `Interested in Car Manual; ${validateData.clientNote}`;
                }

                let response = await this.$axios.post(process.env.VUE_APP_URL_ORDER_CREATE, validateData);

                if (response.status == '200') {
                    this.setOrderId(response.data.id);
                    return response;
                } else {
                    throw `${this.$t('errors.somethingWentWrong')}: ${process.env.VUE_APP_URL_ORDER_CREATE}`;
                }
            } catch (error) {
                throw new Error(error);
            }
        },
        async goToNextStep(order) {
            //GTM
            if (this.emptyRequiredFiles) {
                this.gtmSendDocuments(4, 'Docs not required');
            } else if (this.order.order.sendDocsLater) {
                this.gtmSendDocuments(4, 'Docs send later');
            } else {
                this.gtmSendDocuments(4, 'Docs attached');
            }

            // END GTM
            if (this.customer.type == 'S') {
                this.$router.push({ name: 'ThankYou' });
            } else if (this.order.order.paymentMethod == 'bank') {
                this.$router.push({ name: 'PaymentInfo' });
            } else if (this.order.order.paymentMethod == 'paypal') {
                this.$router.push({ name: 'Paypal' });
            } else if (this.order.order.paymentMethod == 'card') {
                this.goToHiPay(order.data.id);
            } else if (this.order.order.paymentMethod.includes('klarna')) {
                this.$router.push({ name: 'Klarna' });
            } else {
                this.$router.push({ name: 'ThankYou' });
            }
        },
        checkRequiredCheckboxes() {
            if (!this.generalTerms) {
                this.errors.generalTerms = this.$t('base.generalTermsCheckIsrequired');
            }

            //validujem checkbox ak je country Austria
            if (this.customer.deliveryAddress.country == 17 && !this.austria) {
                this.errors.austria = this.$t('base.austriaCheckIsrequired');
            }

            //validujem checkbox ak moto a FR krajina/oblast
            if (this.frCountries.includes(this.customer.deliveryAddress.country) && this.selectedProduct.type == 'MOTO' && !this.motoFr) {
                this.errors.motoFr = this.$t('base.motoFrCheckIsrequired');
            }

            //validujem mercedesBenz
            if (this.selectedProduct.id == '37530699' && !this.mercedesBenz) {
                this.errors.mercedesBenz = this.$t('base.motoFrCheckIsrequired');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

.form-group {
    @include transition();
}

.--inactive {
    pointer-events: none;
    @include opacity(0.4);
}

.car-manual {
    position: relative;
    padding: 24px 48px 24px 24px;
    background: #ffffff !important;
    border: 1px solid #ffd79a;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 24px;
}

.car-manual__image {
    width: 120px;
    height: 120px;
    min-width: 120px;
    background: url($basePath+'icons/car-manual.jpg') no-repeat center;
    background-size: contain;
}

.car-manual__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > span {
        font-size: 14px;
        line-height: 1.4;
    }
}
</style>
