<template>
    <div class="b-delivery-options mt-24">
        <label class="form-radio" v-for="(delivery, index) in possibleDeliveryOptions" :key="index">
            <div class="form-radio__popular" v-if="delivery.popular">
                <span>{{ $t('base.mostPopular') }}</span>
            </div>
            <input type="radio" name="delivery" :value="delivery.value" class="form-radio__input" v-model="deliveryData" />
            <span class="form-radio__bg"></span>
            <span class="form-radio__checkmark"></span>
            <div class="form-radio__label">
                <div class="b-delivery-options__title">
                    <div>
                        {{ $t(delivery.name) }}
                        <span class="b-delivery-options__divider">|</span>
                        {{ order.deliveryOptionsPrices[delivery.value] }} €
                        <span class="b-delivery-options__divider">|</span>
                        <span class="b-deliver-options__duration">+ {{ $t(delivery.deliveryDuration) }}</span>
                    </div>
                </div>

                <div class="b-delivery-options__benefits mt-16">
                    <div class="b-delivery-options__benefit" v-if="delivery.value == 'digital' || delivery.value == 'fast+car_history'">
                        <div class="b-delivery-options__benefit-icon --email"></div>
                        <div class="b-delivery-options__benefit-content">
                            <strong>{{ $t('delivery.digitalCopyByEmail') }}</strong>
                            <span>{{ $t('delivery.digitalCopyByEmailDesc') }}</span>
                            <div class="span">
                                {{ $t('delivery.estimatedDeliveryBetween') }}
                                <div class="b-delivery-options__benefit-badge">
                                    {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime)) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="b-delivery-options__benefit"
                        v-if="delivery.value == 'digital' || delivery.value == 'fast' || delivery.value == 'fast+car_history'"
                    >
                        <div class="b-delivery-options__benefit-icon --ups"></div>
                        <div class="b-delivery-options__benefit-content">
                            <strong>{{ $t('delivery.physicalCOCUPS') }}</strong>
                            <div class="span">
                                {{ $t('delivery.estimatedDeliveryBetween') }}
                                <div class="b-delivery-options__benefit-badge">
                                    {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 1) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="b-delivery-options__benefit" v-if="delivery.value == 'fast+car_history'">
                        <div class="b-delivery-options__benefit-icon --car-history"></div>
                        <div class="b-delivery-options__benefit-content">
                            <div class="d-flex align-items-center mb-4">
                                <strong>{{ $t('delivery.historyTitle') }}</strong>
                                <img :src="require('@/assets/images/icons/logo-carvertical.svg')" alt="car vertical logo" width="100" class="ml-8" />
                                <form-label-info static>
                                    <div class="tooltip-content">
                                        {{ $t('delivery.historyTooltip') }}
                                    </div>
                                </form-label-info>
                            </div>
                            <span>{{ $t('delivery.historyDesc') }}</span>
                        </div>
                    </div>

                    <div class="b-delivery-options__benefit" v-if="delivery.value == 'economy'">
                        <div class="b-delivery-options__benefit-icon --physical"></div>
                        <div class="b-delivery-options__benefit-content">
                            <strong>{{ $t('delivery.physicalCOC') }}</strong>
                            <div class="span">
                                {{ $t('delivery.estimatedDeliveryBetween') }}
                                <div class="b-delivery-options__benefit-badge">
                                    {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 3) }}
                                    -
                                    {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 7) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FormLabelInfo from '@/components/form/FormLabelInfo.vue';

export default {
    props: {},
    components: {
        FormLabelInfo,
    },
    emits: ['selected'],
    data() {
        return {
            deliveryData: '',
            isDigitalOrFast: false,
        };
    },
    created() {},
    mounted() {
        this.deliveryData = this.order.order.deliveryMethod;

        // ak nie je zvolena ziadna moznost, predvolim prvu
        setTimeout(() => {
            if (!this.deliveryData) {
                this.deliveryData = this.possibleDeliveryOptions[0]?.value;
            }
        }, 500);
    },
    computed: {
        ...mapState(['selectedProduct']),
        ...mapState('order', ['order']),
        possibleDeliveryOptions() {
            let deliveries = this.order.deliveryOptions.filter(delivery => {
                if (this.order.deliveryOptionsPrices[delivery.value] !== undefined) return delivery;
            });

            return deliveries;
        },
    },
    methods: {},
    watch: {
        deliveryData() {
            this.$emit('selected', this.deliveryData);
        },
        //sledujem kvoli moznej zmene v popupe, ked zmenim deliveryAddress
        'order.order.deliveryMethod': function() {
            this.deliveryData = this.order.order.deliveryMethod;
        },
        possibleDeliveryOptions() {
            for (var i = 0; i < this.possibleDeliveryOptions.length; i++) {
                if (this.possibleDeliveryOptions[i].value == 'fast' || this.possibleDeliveryOptions[i].value == 'digital') {
                    this.isDigitalOrFast = true;
                    break;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
