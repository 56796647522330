<template>
	<div class="info-box">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "InfoBox",
	props: [],
	components: {},
	data() {
		return {}
	},
	mounted() {},
	created() {},
	methods: {}
}
</script>

<style lang="scss">
.info-box {
	display: inline-flex;
	align-items: center;
	padding: 13px 24px;
	font-size: 16px;
	background: $selectBg;
	color: $lightGray;
	@include border-radius($rad);

	@include less-than(md) {
		padding: 12px;
	}

	a {
		color: $orange;
		text-decoration: underline !important;
		margin-right: 6px;

		&:hover {
			color: $darkGray;
		}
	}

	img {
		margin-right: 12px;
	}
}
</style>
