<template>
    <info-box class="mb-0 d-flex flex-column align-items-start">
        <div v-html="$t('delivery.processingTimeInfo', { days: selectedProduct.deliveryTime })"></div>
        <div v-show="showInfo" class="small-text">
            {{ $t('delivery.processingTimeInfoMore') }}
        </div>
        <a href="#" class="read-more" @click.prevent="showInfo = !showInfo">
            {{ !showInfo ? $t('base.readMore') : $t('base.readLess') }}
        </a>
    </info-box>
</template>

<script>
import { mapState } from 'vuex';
import InfoBox from '@/components/InfoBox.vue';
export default {
    props: {},
    components: {
        InfoBox,
    },
    data() {
        return {
            showInfo: false,
        };
    },
    created() {},
    mounted() {},
    computed: {
        ...mapState(['selectedProduct']),
    },
    methods: {},
    watch: {},
};
</script>

<style lang="scss" scoped>
.read-more {
    color: $blue;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;

    &:hover {
        color: $orange;
    }
}
</style>
