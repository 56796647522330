<template>
    <div>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <info-box class="mb-32 align-items-start">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div>
                                {{ $t("onlinePayment.info") }}
                            </div>
                        </info-box>

                        <head-title class="mb-8">
                            <span class="d-flex align-items-center text-center justify-content-center">
                                {{ $t("onlinePayment.headline") }}
                            </span>
                        </head-title>

                        <div class="logo-hipay">
                            <div class="logo-hipay__wrapper">
                                <img :src="require(`@/assets/images/icons/hipay.svg`)" class="d-inline-flex align-items-center ml-8" width="75" />
                            </div>
                        </div>

                        <div class="mt-16">
                            <div class="btns mx-auto">
                                <hi-pay-form></hi-pay-form>
                            </div>
                        </div>

                        <div class="mt-40">
                            <div class="hipay__footer">
                                <div v-html="$t('hipay.footerInfo')"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <app-sidebar></app-sidebar>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <app-loader v-show="loading" :light="true"></app-loader>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"
//import AppBreadcrumb from "@/components/base/AppBreadcrumb.vue"
import AppSidebar from "@/components/base/AppSidebar.vue"
import AppMarketLeader from "@/components/base/AppMarketLeader.vue"
import HeadTitle from "@/components/headings/HeadTitle.vue"
import InfoBox from "@/components/InfoBox.vue"
import AppLoader from "@/components/base/AppLoader.vue"
import HiPayForm from "@/components/payments/HiPayForm.vue"

export default {
    name: "Paypal",
    components: {
        //AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        InfoBox,
        AppLoader,
        HiPayForm
    },
    data() {
        return {
            loaded: false,
            loading: false
        }
    },
    mounted() {
        this.setCurrentStep(5)
        this.setCurrentPath("HiPay")
        this.gtmEventPayment("HiPay")
    },
    created() {
        // let self = this
        // window.onbeforeunload = function() {
        //     return self.$t("error.confirmAlertBeforeLeaveSite")
        // }
    },
    computed: {
        ...mapState(["orderId"]),
        ...mapState("order", ["order"]),
        ...mapState("customer", ["customer"])
    },
    methods: {
        ...mapMutations(["setCurrentStep", "setCurrentPath"])
    }
}
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-orange {
    color: $orange;
}

.order {
    font-size: 18px;
    line-height: 27px;
    color: $darkGray;

    i {
        font-style: normal;
        color: $orange;
    }
}

.logo-hipay {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        left: 0;
        top: 45%;
    }
}

.logo-hipay__wrapper {
    position: relative;
    z-index: 2;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    background: $white;
}

.btns {
    max-width: 300px;
}

p {
    font-size: 16px;
    line-height: 24px;
}

.loading {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
}
</style>
