export default {
    namespaced: true,
    state: {
        order: {
            prices: {},
            order: {},
            deliveryOptions: [
                {
                    name: 'delivery.history',
                    value: 'fast+car_history',
                    //price: 10,
                    badge: '',
                    highlight: '',
                    info: 'delivery.infos.history',
                    popular: true,
                    deliveryDuration: 'delivery.deliveryDuration.history',
                    durationFrom: 1,
                    durationTo: 1,
                },
                {
                    name: 'delivery.digital',
                    value: 'digital',
                    //price: 10,
                    badge: 'delivery.badges.digital',
                    highlight: 'delivery.highlights.digital',
                    info: 'delivery.infos.digital',
                    popular: false,
                    deliveryDuration: 'delivery.deliveryDuration.digital',
                    durationFrom: 1,
                    durationTo: 1,
                },
                {
                    name: 'delivery.fast',
                    value: 'fast',
                    //price: 10,
                    badge: '',
                    highlight: '',
                    info: 'delivery.infos.fast',
                    popular: false,
                    deliveryDuration: 'delivery.deliveryDuration.fast',
                    durationFrom: 1,
                    durationTo: 1,
                },
                {
                    name: 'delivery.economy',
                    value: 'economy',
                    //price: 10,
                    badge: '',
                    highlight: '',
                    info: 'delivery.infos.economy',
                    popular: false,
                    deliveryDuration: 'delivery.deliveryDuration.economy',
                    durationFrom: 3,
                    durationTo: 7,
                },
                {
                    name: 'delivery.noneu',
                    value: 'noneu',
                    //price: 10,
                    badge: '',
                    highlight: '',
                    info: 'delivery.infos.noneu',
                    popular: false,
                    deliveryDuration: 'delivery.deliveryDuration.noneu',
                    durationFrom: 3,
                    durationTo: 7,
                },
            ],
            deliveryOptionsPrices: {},
            paymentOptions: [
                {
                    name: 'payment.card',
                    value: 'card',
                    badge: '', //'base.recommended',
                    logo: 'hipay.svg',
                    info: 'payment.infos.card',
                },
                {
                    name: 'payment.paypal',
                    value: 'paypal',
                    badge: '',
                    logo: 'paypal.svg',
                    info: '',
                },
                {
                    name: 'payment.payUsingBankTransfer',
                    value: 'bank',
                    badge: '',
                    logo: '',
                    info: '',
                },
            ],
        },
    },
    mutations: {
        setAddressNote(state, payload) {
            state.order.order.addressNote = payload;
        },
        setOrder(state, payload) {
            state.order.order = payload;
        },
        setPrices(state, payload) {
            state.order.prices = payload;
        },
        resetProductItem(state) {
            state.order.order.item = {};
        },
        setProductId(state, payload) {
            state.order.order.item.productId = parseInt(payload, 10);
        },
        setVin(state, payload) {
            state.order.order.item.vin = payload;
        },
        setRegistrationYear(state, payload) {
            state.order.order.item.firstYearRegistration = parseInt(payload, 10);
        },
        setTypeApprovalNumber(state, payload) {
            state.order.order.item.typeApprovalNumber = payload;
        },
        setVehicleColor(state, payload) {
            state.order.order.item.vehicleColor = payload;
        },
        setSeatsCount(state, payload) {
            state.order.order.item.seatsCount = payload;
        },
        setEngineNumber(state, payload) {
            state.order.order.item.engineNumber = payload;
        },
        setGearType(state, payload) {
            state.order.order.item.gearType = payload;
        },
        setFirstRegistrationCountry(state, payload) {
            state.order.order.item.firstRegistrationCountry = payload;
        },
        setNextCountry(state, payload) {
            state.order.order.item.nextCountry = payload;
        },
        setClientNote(state, payload) {
            state.order.order.clientNote = payload;
        },
        setDeliveryMethod(state, payload) {
            state.order.order.deliveryMethod = payload;
        },
        setPaymentMethod(state, payload) {
            state.order.order.paymentMethod = payload;
        },
        setVoucher(state, payload) {
            state.order.order.voucher = payload;
        },
        setDeliveryOptionsPrices(state, payload) {
            state.order.deliveryOptionsPrices = payload;
        },
        setIpAddress(state, payload) {
            state.order.order.ipAddress = payload;
        },
        setDate(state, payload) {
            state.order.order.date = payload;
        },
        setDocs(state, payload) {
            state.order.order.documents = payload;
        },
        setOrderType(state, payload) {
            state.order.order.type = payload;
        },
        setClientId(state, payload) {
            state.order.order.clientId = payload;
        },
        setOrderLang(state, payload) {
            state.order.order.language = payload;
        },
        setAffiliateId(state, payload) {
            state.order.order.affiliateId = payload;
        },
        setPaymentOptions(state, payload) {
            const isFound = state.order.paymentOptions.some(element => {
                if (element.name === payload.name) {
                    return true;
                }

                return false;
            });

            if (!isFound) {
                state.order.paymentOptions.push(payload);
            }
        },
    },
    actions: {},
    modules: {},
};
