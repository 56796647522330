<template>
    <transition name="fade-modal">
        <div class="modal" v-show="showModal">
            <div class="modal__mask" @click.prevent="$emit('close')"></div>
            <div class="modal__dialog-scroll-wrapper">
                <div class="modal__dialog-wrapper">
                    <div class="modal__dialog">
                        <div class="modal__close" @click.prevent="$emit('close')" v-show="!customClose">
                            <span class="modal__close-icon"></span>
                        </div>

                        <div class="modal__header" v-if="hasHeaderSlot">
                            <slot name="header"></slot>
                        </div>

                        <div class="modal__body" v-if="hasBodySlot">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <slot name="body"></slot>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal__footer" v-if="hasFooterSlot">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <slot name="footer"></slot>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",
    props: ["show-modal", "custom-close"],
    methods: {
        close() {
            if (!this.customClose) {
                this.$emit("input")
            }
        }
    },
    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header
        },

        hasBodySlot() {
            return !!this.$slots.body
        },

        hasFooterSlot() {
            return !!this.$slots.footer
        }
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    //overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition();
}

.modal__dialog-scroll-wrapper {
    position: relative;
    width: 750px;
    height: calc(100% - 3rem);
    z-index: 1000;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include less-than(md) {
        width: 100%;
        max-width: 750px;
    }
}

::-webkit-scrollbar {
    width: 6px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
    @include border-radius(1rem);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    cursor: pointer;
    @include border-radius(1rem);
    @include transition();
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999;
}

.modal__mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $ultraLightGray;
    @include opacity(0.7);
}

.modal__close {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 2;
    top: 20px;
    right: 20px;
    cursor: pointer;
    overflow: hidden;

    @include less-than(md) {
        right: 10px;
        top: 30px;
    }
}

.modal__close-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    background-color: $ultraLightGray;
    -webkit-mask: url($basePath+"icons/modal-close.svg") no-repeat center;
    mask: url($basePath+"icons/modal-close.svg") no-repeat center;
    @include transition();

    .modal__close:hover & {
        @include rotate(90deg);
    }
}

.modal__dialog-wrapper {
    //max-height: 100%;
    padding: 2rem 0;

    @include less-than(md) {
        width: calc(100% - 30px);
    }
}

.modal__dialog {
    width: 696px;
    background: $white;
    position: relative;
    z-index: 2;
    //margin: 20px 0;
    @include transition();
    @include shadow(7px, 7px, 14px, 0, rgba(53, 57, 73, 0.08));
    @include border-radius($rad);

    @include less-than(md) {
        width: 100%;
        max-width: 696px;
    }
}

.modal__header {
    padding: 48px 48px 32px;
    color: $orange;
    font-weight: $fwSemi;
    font-size: 24px;
    line-height: 32px;

    @include less-than(md) {
        padding: 32px 24px;
        font-size: 18px;
        line-height: 27px;
    }
}

.modal__body {
    color: $black;
    font-size: 18px;
    font-family: $font;
    padding: 0 32px 64px;

    @include less-than(md) {
        padding: 0 0px 32px;
    }
}

.modal__footer {
    padding: 20px 20px 30px;
    //border-top: 0.1rem solid $border;
}

//*****************MODAL ANIMATION
//********************************
.fade-modal-enter-active {
    @include opacity(0);

    .modal__dialog {
        @include translateY(-30px);
    }
}

.fade-modal-enter-to {
    @include opacity(1);

    .modal__dialog {
        @include translateY(0);
    }
}

.fade-modal-leave {
    @include opacity(1);

    .modal__dialog {
        @include translateY(0);
    }
}

.fade-modal-leave-to {
    @include opacity(0);

    .modal__dialog {
        @include translateY(-30px);
    }
}
</style>
