import { createApp } from 'vue';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import globalMixins from './mixins/globalMixins.js';
import { setupI18n } from './i18n';
import $axios from './utilities/axios.js';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@/scss/app.scss';

let langs = ['en', 'fr', 'de', 'es', 'it'];

//zoberiem lang z url
let lang =
    window.location.href.split('/')[3].length > 1 && langs.includes(window.location.href.split('/')[3]) ? window.location.href.split('/')[3] : 'en';

moment.locale(lang);

//nastavim lang do i18n
let i18n = setupI18n(lang);

let options = {
    position: POSITION.TOP_CENTER,
    closeButton: false,
};

window.$axios = $axios;
window.$store = store;

let app = createApp(App);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$axios = $axios;

app.mixin(globalMixins)
    .use(store)
    .use(router)
    .use(i18n)
    .use(Toast, options)
    .mount('#app');
