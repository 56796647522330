<template>
    <div class="form-group" :class="{ '--loading': loading }">
        <slot></slot>

        <svg class="spinner" viewBox="0 0 50 50" v-show="loading">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: "FormGroup",
    props: ["loading"],
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    methods: {}
}
</script>

<style lang="scss">
.form-group {
    position: relative;
    margin: 24px 0;
    width: 100%;

    &.--loading {
        input {
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.05);
            @include opacity(0.5);
        }
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    bottom: 15px;
    right: 20px;
    width: 20px;
    height: 20px;

    & .path {
        stroke: rgba(0, 0, 0, 0.1);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
</style>
