<template>
    <div class="form-label__info-wrapper" @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
        <span class="form-label__info"></span>
        <div class="form-label__tooltip" :class="{ '--visible': showTooltip }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showTooltip: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.form-label__info-wrapper {
    display: inline-block;
    position: relative;

    @include less-than(md) {
        margin-left: auto;
        margin-right: 10px;
    }
}

.form-label__info {
    width: 16px;
    height: 16px;
    display: block;
    cursor: pointer;
    margin-left: 8px;
    background: $ultraLightGray;
    -webkit-mask: url($basePath+'icons/info.svg') no-repeat center;
    mask: url($basePath+'icons/info.svg') no-repeat center;
    @include transition();

    &:hover {
        background: $darkGray;
    }
}

.form-label__tooltip {
    position: absolute;
    z-index: 100;
    background: $selectBg;
    width: 300px;
    padding: 16px;
    border: 1px solid $orange;
    font-family: $font;
    font-size: 12px;
    line-height: 16px;
    color: $darkGray;
    left: 50%;
    bottom: 35px;
    //word-break: break-all;
    @include translate(-50%, 0, 0);
    @include border-radius($rad);
    @include shadow(2px, 4px, 6px, 0, rgba(38, 37, 35, 0.1));
    @include opacity(0);
    pointer-events: none;
    bottom: 20px;
    @include transition();

    @include less-than(md) {
        left: auto;
        right: -20px;
        @include translate(0, 0, 0);
    }

    &.--visible {
        @include opacity(1);
        pointer-events: auto;
        bottom: 35px;
    }

    &:after {
        content: '';
        width: 31px;
        height: 15px;
        background: url($basePath+'icons/tooltip-pin.svg') no-repeat center;
        position: absolute;
        bottom: -14px;
        left: 50%;
        margin-left: 4px;
        @include translateX(-50%);

        @include less-than(md) {
            left: auto;
            right: 12px;
            @include translateX(0);
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 25px;
        background: transparent;
        position: absolute;
        bottom: -25px;
        left: 50%;
        @include translateX(-50%);
    }
}
</style>
