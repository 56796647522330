<template>
    <div
        class="drag-drop"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover.prevent=""
        @drop="onDrop"
        :class="{ '--dragging': isDragging }"
    >
        <div class="drag-drop__inner">
            <div class="drag-drop__drag-container">
                <label :for="`gallery-${docType}`">
                    <div class="upload-icon"></div>
                    <div>
                        {{ $t('base.dragAndDrop') }}
                        <br />
                        <span class="text-blue">{{ $t('base.uploadFromDevice') }}</span>
                    </div>
                </label>
                <input type="file" :id="`gallery-${docType}`" @change="onInputChange" multiple />
            </div>
            <div class="drag-drop__images" v-if="files.length > 0">
                <div class="drag-drop__image-wrapper">
                    <div class="position-relative drag-drop__image-wrapper-inner" v-for="(file, idx) in files" :key="idx">
                        <span class="name">{{ names[idx] }}</span>
                        <span class="drag-drop__image-delete" @click.prevent="removeFile(idx)" v-if="states[idx] == 'done'"></span>
                        <span class="drag-drop__image-uploading" v-else>
                            <svg class="spinner" viewBox="0 0 10 10">
                                <circle class="path" cx="5" cy="5" r="4" fill="none" stroke-width="2"></circle>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'DragDrop',

    components: {},

    props: ['docType'],
    emits: ['files', 'removeFile'],

    data() {
        return {
            isDragging: false,
            dragCount: 0,
            files: [],
            names: [],
            states: [],
            extensions: ['jpg', 'jpeg', 'doc', 'docx', 'pdf', 'zip', '7zip', 'rar', 'png', 'bmp', 'gif'],
            maxFileSize: 5, //MB
            //image: ""
        };
    },

    created() {},

    computed: {
        ...mapState(['tmpId']),
    },

    methods: {
        onDragEnter(e) {
            e.preventDefault();

            this.dragCount++;
            this.isDragging = true;
        },

        onDragLeave(e) {
            e.preventDefault();
            this.dragCount--;

            if (this.dragCount <= 0) this.isDragging = false;
        },

        onInputChange(e) {
            //EXTRACT FILES FROM INPUT
            const files = e.target.files;
            Array.from(files).forEach(file => this.addFile(file));
        },

        onDrop(e) {
            e.preventDefault();
            e.stopPropagation();

            this.isDragging = false;

            //EXTRACT FILES FROM EVENT
            const files = e.dataTransfer.files;
            Array.from(files).forEach(file => this.addFile(file));
        },

        addFile(file) {
            //IMAGE BASIC VALIDATION /* REAL VALIDATION ON BACKEND
            let ext = file.name
                .split('.')
                .pop()
                .toLowerCase();

            if (!this.extensions.includes(ext)) {
                toast(this.$t('errors.badFileType'));
                return;
            }

            if (file.size / 1000000 > this.maxFileSize) {
                toast(this.$t('errors.maxFileSize'));
                return;
            }

            //ADD IMAGE TO FILES
            //this.file = file

            //CREATE IMAGE THUMB
            const reader = new FileReader();

            //this.names.push(file.name)

            var fileName = file.name;
            var i = 0;

            while (i < 5) {
                if (this.names.includes(fileName)) {
                    var extension = fileName.split('.').pop();
                    var name = fileName
                        .split('.')
                        .slice(0, -1)
                        .join('.');

                    fileName = name + i + '.' + extension;
                } else {
                    this.names.push(fileName);
                    break;
                }

                i++;
            }

            reader.onload = e => {
                this.files.push(e.target.result);
                this.states.push('uploading');
                this.uploadFile(e.target.result, fileName, this.files.length - 1);
            };
            reader.readAsDataURL(file);
        },

        getFileSize(index) {
            return (this.files[index].size / 1000000).toFixed(2);
        },

        async uploadFile(file, name, idx) {
            let self = this;

            let fileToUpload = {
                tmpId: this.tmpId,
                data: {
                    name: name,
                    data: file,
                    type: this.docType,
                },
            };

            try {
                let response = await this.$axios.post(process.env.VUE_APP_URL_ORDER_UPLOAD, fileToUpload);

                if (response.status == '200') {
                    setTimeout(() => {
                        self.states[idx] = 'done';
                    }, 400);
                } else {
                    self.files.splice(idx, 1);
                    self.names.splice(idx, 1);
                    self.states.splice(idx, 1);
                    throw new Error(`Cant upload document: ${name}`);
                }
            } catch (error) {
                toast(error.message);
            }
        },

        removeFile(index) {
            var currentFile = {
                name: this.names[index],
                //data: this.files[i],
                type: this.docType,
            };

            this.$emit('remove-file', currentFile);

            this.files.splice(index, 1);
            this.names.splice(index, 1);
            this.states.splice(index, 1);
            // this.$delete(this.files, index)
            // this.$delete(this.names, index)
        },

        updateFiles() {
            let currentFiles = [];

            for (var i = 0; i < this.files.length; i++) {
                var obj = {
                    name: this.names[i],
                    //data: this.files[i],
                    type: this.docType,
                };

                currentFiles.push(obj);
            }
            //console.log(currentFiles)

            this.$emit('files', currentFiles);
        },
    },

    watch: {
        files: {
            deep: true,
            handler: function() {
                this.updateFiles();
            },
        },
        // files() {
        //     console.log("teraz")
        //     this.updateFiles()
        // }
    },
};
</script>

<style lang="scss" scoped>
.drag-drop {
    padding: 0;
    border: 1px solid $inputBorder;
    background: $selectBg;
    @include border-radius($rad);

    &.--dragging {
        border: 1px dashed $inputBorder;
    }

    .form-group.--invalid & {
        border-color: $red;
    }
}

.drag-drop__drag-container {
    display: flex;
    position: relative;

    label {
        display: flex;
        align-items: center;
        padding: 24px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        color: $lightGray;

        .upload-icon {
            width: 48px;
            height: 48px;
            display: block;
            margin-right: 12px;
            background: url($basePath+'icons/upload.svg') no-repeat center;
        }
    }

    input {
        width: 0;
        height: 0;
        visibility: hidden;
        position: absolute;
        @include opacity(0);
    }
}

.drag-drop__images {
    display: flex;
    //overflow: auto;
    padding: 0 24px 24px;
}

.drag-drop__image-wrapper {
    position: relative;
    margin: 0 12px;
    font-size: 12px;
    color: $white;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &:nth-last-child(-n + 4) {
        margin-bottom: 0;
    }
}

.drag-drop__image-wrapper-inner {
    min-height: 30px;
    width: auto;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 40px 8px 16px;
    //border: 1px solid $inputBorder;
    margin: 10px 10px 0 0;
    background: $orange;
    @include border-radius($rad);

    span.name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: block;
        @include border-radius($rad);
    }
}

.drag-drop__img {
    display: block;
}

.drag-drop__image-delete {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    background: $white;
    display: flex;
    justify-content: center;
    font-size: 15px;
    line-height: 18px;
    font-weight: $fwBold;
    color: $white;
    cursor: pointer;
    @include border-radius(2rem);
    //@include shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.2));
    @include transition();

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: $orange;
        mask: url($basePath+'icons/modal-close.svg') no-repeat center;
        mask-size: 10px auto;
        @include transition();
    }

    &:hover {
        background: $black;

        &:before {
            background: $white;
        }
    }
}

.drag-drop__image-uploading {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    background: $white;
    display: flex;
    justify-content: center;
    font-size: 15px;
    line-height: 18px;
    font-weight: $fwBold;
    color: $white;
    cursor: pointer;
    @include border-radius(2rem);
    //@include shadow(0, 5px, 10px, 0, rgba(0, 0, 0, 0.2));
}

.text-blue {
    color: $blue;
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;

    & .path {
        stroke: $orange;
        stroke-linecap: round;
        animation: dash 2s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 100;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 30, 100;
        stroke-dashoffset: -10;
    }
    100% {
        stroke-dasharray: 1, 100;
        stroke-dashoffset: -30;
    }
}
</style>
