<template>
    <modal :show-modal="showDeliveryModal" @close="setShowDeliveryModal(false)">
        <template v-slot:header>
            {{ $t('delivery.deliveryMethodChange') }}
        </template>

        <template v-slot:body>
            <DeliveryInfoBox />

            <DeliveryOptionsC @selected="setDeliveryOption($event)" />

            <!-- BTN -->
            <div class="d-flex justify-content-end">
                <form-button class="--secondary mt-24" @click="changeDeliveryMethod()">
                    {{ $t('delivery.changeDelivery') }}
                </form-button>
            </div>
        </template>
    </modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FormButton from '@/components/form/FormButton.vue';
import DeliveryOptionsC from '@/components/delivery/DeliveryOptionsC';
import DeliveryInfoBox from '@/components/delivery/DeliveryInfoBox';

import Modal from './Modal';

export default {
    name: 'ModalDeliveryOptions',
    components: {
        Modal,
        FormButton,
        DeliveryOptionsC,
        DeliveryInfoBox,
    },
    data() {
        return {
            deliveryData: '',
        };
    },
    methods: {
        ...mapMutations(['setShowDeliveryModal']),
        ...mapMutations('order', ['setDeliveryMethod']),
        setDeliveryOption(e) {
            this.deliveryData = e;
        },
        changeDeliveryMethod() {
            this.setDeliveryMethod(this.deliveryData);
            this.setShowDeliveryModal(false);
        },
    },
    computed: {
        ...mapState(['showDeliveryModal']),
        ...mapState('order', ['order']),
    },
    watch: {
        // showDeliveryModal(newValue) {
        //     if (newValue == true) {
        //         this.deliveryData = this.order.order.deliveryMethod;
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped></style>
