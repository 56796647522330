// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/modal-close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".text-small-label[data-v-0c41da57]{color:#6a7084;font-size:12px;line-height:15px}.login-form[data-v-0c41da57]{background:#fffbf5;padding:24px;display:block;position:relative;border-radius:4px}.login-form__headline[data-v-0c41da57]{color:#ffa41b;font-size:18px;font-weight:700;margin-bottom:16px}.login-form__close[data-v-0c41da57]{position:absolute;width:30px;height:30px;top:24px;right:24px;cursor:pointer;background-color:#b9bbc0;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;transition:all .6s cubic-bezier(.22,1,.36,1)}.login-form__close[data-v-0c41da57]:hover{transform:rotate(90deg)}.login-form__link[data-v-0c41da57]{color:#6882fb;font-size:13px;height:100%}@media(max-width:1023.9px){.login-form__link[data-v-0c41da57]{line-height:16px}}.vat-prefix[data-v-0c41da57]{position:absolute;left:16px;top:45px}.info-box__signin[data-v-0c41da57]{display:flex}@media(max-width:1023.9px){.info-box__signin[data-v-0c41da57]{display:block}}", ""]);
// Exports
module.exports = exports;
