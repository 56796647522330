<template>
    <div class="hotline">
        <h3 class="hotline__header">
            <span>{{ $t("base.customerSupport") }}</span>
            <strong>{{ $t("base.customerSupportTime") }}</strong>
        </h3>
        <div class="dropdown">
            <div class="dropdown__current">
                <img :src="require(`@/assets/images/icons/flags/${activePhoneNumber.flag}.svg`)" :alt="activePhoneNumber.flag" />
                <a :href="`tel:${activePhoneNumber.phone}`">{{ activePhoneNumber.phone }}</a>
                <div class="dropdown__arrow" @click="showList = !showList"></div>
            </div>
            <div class="dropdown__list" v-show="showList">
                <div class="dropdown__wrapper-inside" @click="close($event)">
                    <div class="dropdown__item" v-for="(item, index) in inactivePhoneNumbers" :key="index">
                        <img :src="require(`@/assets/images/icons/flags/${item.flag}.svg`)" :alt="item.flag" />
                        <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showList: false,
            phoneNumbers: [
                {
                    phone: "+33 187 650 076",
                    country: "fr",
                    flag: "fr"
                },
                {
                    phone: "+421 2 32 603 163",
                    country: "en",
                    flag: "en"
                },
                {
                    phone: "+39 05 411 725 610",
                    country: "it",
                    flag: "it"
                },
                {
                    phone: "+34 951 820 028",
                    country: "es",
                    flag: "es"
                },
                {
                    phone: "+49 8 920 190 128",
                    country: "de",
                    flag: "de"
                }
            ]
        }
    },
    mounted() {
        document.addEventListener("click", this.close)
    },
    unmounted() {
        document.removeEventListener("click", this.close)
    },
    computed: {
        inactivePhoneNumbers() {
            let numbers = this.phoneNumbers.filter(elem => {
                if (elem.country != this.$i18n.locale) return elem
            })

            return numbers
        },
        activePhoneNumber() {
            let number = this.phoneNumbers.filter(elem => {
                if (elem.country == this.$i18n.locale) return elem
            })

            return number[0]
        }
    },
    methods: {
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.showList = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.hotline {
    margin-left: auto;
    text-align: right;

    @include less-than(md) {
        position: fixed;
        z-index: 100;
        background: $white;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: left;
        padding: 10px;
        @include shadow(0, 0, 16px, 0, rgba(0, 0, 0, 0.1));
    }
}

.hotline__header {
    font-size: 12px;
    line-height: 18px;
    color: $lightGray;
    font-weight: $fwSemi;
    text-transform: uppercase;

    span {
        @include less-than(md) {
            display: none;
        }
    }

    strong {
        font-size: 16px;
        font-weight: $fwBold;
        display: block;
        color: $darkBlue;
        text-transform: none;

        @include less-than(md) {
            font-size: 13px;
        }
    }
}

.dropdown {
    position: relative;
    margin-top: 5px;

    @include less-than(md) {
        margin-top: 0;
    }
}

.dropdown__current {
    display: flex;
    align-items: center;

    @include less-than(md) {
        font-size: 14px;
    }

    img {
        width: 16px;
        height: auto;
        margin-right: 10px;
    }

    a {
        color: $orange;

        &:hover {
            color: $darkBlue;
        }
    }
}

.dropdown__arrow {
    width: 30px;
    height: 20px;
    cursor: pointer;
    position: relative;

    @include less-than(md) {
        width: 20px;
    }

    &:hover:before {
        background: $orange;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: $darkBlue;
        mask: url($basePath+"icons/dropdown.svg") no-repeat center right;
        @include transition();
    }
}

.dropdown__item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    padding-right: 10px;

    @include less-than(md) {
        font-size: 14px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    img {
        width: 16px;
        height: auto;
        margin-right: 10px;
    }

    a {
        white-space: nowrap;
        color: $orange;

        &:hover {
            color: $darkBlue;
        }
    }
}

.dropdown__list {
    position: absolute;
    top: calc(100% + 3px);
    left: -10px;
    min-width: calc(100% + 10px);
    z-index: 10;
    background: $white;
    padding: 10px;
    @include shadow(0, 0.2rem, 0.6rem, 0, rgba(0, 0, 0, 0.08));

    @include less-than(md) {
        top: auto;
        bottom: calc(100% + 5px);
        padding-right: 30px;
        right: auto;
        min-width: auto;
    }
}
</style>
