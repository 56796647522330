export default {
    namespaced: true,
    state: {
        baseCustomer: {
            registerId: '',
            lang: 'en',
            type: 'N',
            title: '',
            name: '',
            surname: '',
            email: '',
            password: '',
            cellPhonePrefix: '',
            cellPhoneNumber: '',
            registred: 0,
            personType: '',
            sendNotify: false,
            freeDelivery: false,
            deliveryIsSame: true,
            deliveryAddress: {
                companyName: '',
                name: '',
                surname: '',
                cellPhonePrefix: '',
                cellPhoneNumber: '',
                street: '',
                streetNumberNone: false,
                postalCode: '',
                city: '',
                country: 0,
            },
            invoiceData: {
                companyName: '',
                companyId: '',
                street: '',
                streetNumberNone: false,
                postalCode: '',
                city: '',
                vatId: '',
                country: 0,
            },
        },
        baseCustomerAdditional: {
            //deliveryIsSame: true,
            createAccount: false,
        },
        baseOrder: {
            clientId: '',
            date: '',
            status: 'new',
            substatus: '200',
            deliveryMethod: '',
            paymentMethod: '',
            language: 'en',
            type: 'N',
            voucher: '',
            clientNote: '',
            addressNote: '',
            sendDocsLater: false,
            affiliateId: '',
            item: {
                productId: 0,
                firstYearRegistration: 0,
                vin: '',
                firstRegistrationCountry: '',
                nextCountry: '',
                seatsCount: '',
                typeApprovalNumber: '',
                vehicleColor: '',
                gearType: '',
                engineNumber: '',
            },
            documents: [],
        },
    },
    mutations: {},
    getters: {},
    actions: {},
    modules: {},
};
