<template>
    <h3 class="title mb-4">
        <slot></slot>
    </h3>
</template>

<style lang="scss" scoped>
.title {
    font-weight: $fwSemi;
    font-size: 24px;
    line-height: 32px;
}
</style>
