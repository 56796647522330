<template>
    <header class="header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="header__wrapper" :class="{ '--shadow': shadow }">
                        <a
                            :href="linkToFrontPage()"
                            class="header__logo"
                            :class="{ '--fr': $i18n.locale == 'fr', '--de': $i18n.locale == 'de', '--it': $i18n.locale == 'it', '--es': $i18n.locale == 'es' }"
                        ></a>
                        <h2 class="header__title" v-if="currentPath == 'PaymentInfo'" v-html="$t('base.headlinePaymentInfo')"></h2>
                        <h2 class="header__title" v-else-if="currentPath == 'HiPay' || currentPath == 'PayPal'">{{ $t("base.headlineOnlinePayment") }} {{ orderId }}</h2>
                        <h2 class="header__title" v-else-if="currentPath == 'ThankYou'"></h2>
                        <h2 class="header__title" v-else>
                            {{ $t("base.headline") }}
                        </h2>

                        <app-hotline></app-hotline>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState } from "vuex"
import AppHotline from "@/components/base/AppHotline.vue"
export default {
    name: "AppHeader",
    components: {
        AppHotline
    },
    data() {
        return {
            shadow: false
        }
    },
    mounted() {},
    created() {
        window.addEventListener("scroll", this.handleScroll)
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll)
    },
    computed: {
        ...mapState(["currentPath", "orderId"]),
        ...mapState("customer", ["customer"])
    },
    methods: {
        linkToFrontPage() {
            //window.sessionStorage.clear()
            if (this.customer.type != "N") {
                return process.env.VUE_APP_PRIVATE_ZONE
            } else {
                return process.env.VUE_APP_BASE_URL + this.$i18n.locale
            }
        },
        handleScroll() {
            if (window.scrollY > 100) {
                this.shadow = true
            } else {
                this.shadow = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.header__wrapper {
    display: flex;
    align-items: center;
    padding: 32px 0;

    @include less-than(md) {
        padding: 12px 16px;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        background: $white;
        z-index: 100;

        &.--shadow {
            @include shadow(0, 0, 16px, 0, rgba(0, 0, 0, 0.1));
        }
    }
}

.header__logo {
    width: 165px;
    height: 103px;
    display: block;
    margin-right: 45px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url($basePath+"base/logo-en.svg");
    background-size: contain;

    &.--fr {
        background-image: url($basePath+"base/logo-fr.svg");
    }

    &.--de {
        background-image: url($basePath+"base/logo-de.svg");
    }

    &.--it {
        background-image: url($basePath+"base/logo-it.svg");
    }

    &.--es {
        background-image: url($basePath+"base/logo-es.svg");
    }

    @include less-than(md) {
        width: 76px;
        height: 49px;
        background-size: 100% auto;
        margin-right: 12px;
    }
}

.header__title {
    font-weight: $fwSemi;
    font-size: 36px;
    line-height: 54px;
    margin: 0;
    padding: 0;
    color: $darkGray;

    @include less-than(md) {
        font-size: 18px;
        line-height: 27px;
    }
}
</style>
