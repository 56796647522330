<template>
    <div class="btn --primary" :class="{ '--loading': loading }">
        <slot></slot>

        <svg class="spinner" viewBox="0 0 50 50" v-show="loading">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: "FormButton",
    props: ["loading"],
    components: {},
    data() {
        return {}
    },
    mounted() {},
    created() {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.btn {
    cursor: pointer;
    border: 0;
    width: auto;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include transition();
    @include appearance(none);

    &.--large {
        height: 60px;
    }

    &.--loading {
        position: relative;
        padding-right: 48px !important;
        pointer-events: none !important;
    }

    &.--primary {
        color: $white;
        background: $green;
        text-transform: uppercase;
        font-weight: $fwSemi;
        padding: 8px 32px;
        @include border-radius($rad);

        @include less-than(md) {
            display: flex;
            width: 100%;
            height: auto;
            min-height: 40px;
        }

        &:hover {
            background: $darkGray;
        }
    }

    &.--secondary {
        color: $white;
        background: $orange;
        text-transform: uppercase;
        font-weight: $fwSemi;
        padding: 0 32px;
        display: inline-flex;
        @include border-radius($rad);

        @include less-than(md) {
            display: flex;
            width: 100%;
            height: auto;
            min-height: 40px;
        }

        &:hover {
            background: $darkGray;
        }
    }
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 20px;
    height: 20px;

    .path {
        stroke: rgba(255, 255, 255, 1);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
</style>
