<template>
    <div class="b-order-summary">
        <h3 class="b-order-summary__title" :class="{ '--long-title': $i18n.locale == 'de' }">
            {{ $t('summary.orderSummary') }}
        </h3>

        <div class="show-hide-btn" @click="showSummary = !showSummary" :class="{ '--hide': showSummary }">
            <template v-if="showSummary">
                {{ $t('base.hide') }}
            </template>
            <template v-else>
                {{ $t('base.show') }}
            </template>
        </div>
        <div class="show-hide-content" v-show="showSummary">
            <div class="b-order-summary__email" v-show="customer.email">
                <strong>{{ customer.email }}</strong>
                <span class="b-order-summary__premium" v-if="customer.type == 'S'">
                    {{ $t('summary.premiumUser') }}
                </span>
                <!-- <span class="b-order-summary__email-edit" @click="setShowEmailModal(true)" v-show="currentStep != 1 && currentStep != 5"></span> -->
            </div>
            <div class="b-order-summary__section --vehicle">
                <div class="b-order-summary__header">
                    <strong>
                        {{ $t('summary.vehicle') }}
                    </strong>
                    <!-- <div class="b-order-summary__link">
                        {{ $t("summary.btnChange") }}
                    </div> -->
                </div>

                <div class="b-order-summary__body">
                    <div class="b-order-summary__line">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.brand') }}
                        </div>
                        <div class="b-order-summary__val">
                            <img :src="selectedProduct.logo" :alt="selectedProduct.vehicle.name" class="b-order-summary__val-logo" />
                            {{ selectedProduct.vehicle.name }}
                        </div>
                    </div>

                    <div class="b-order-summary__line">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.type') }}
                        </div>
                        <div class="b-order-summary__val">
                            <span class="b-order-summary__val-icon" :class="[getCarType(selectedProduct.type)[0].class]"></span>
                            {{ getCarType(selectedProduct.type)[0].name }}
                        </div>
                    </div>

                    <div class="b-order-summary__line pb-0">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.processingTime') }}
                        </div>
                        <div class="b-order-summary__val">{{ selectedProduct.deliveryTime }} {{ $t('summary.days') }}</div>
                    </div>
                    <div class="processing-info">{{ $t('summary.afterOrderPayment') }}</div>

                    <info-box class="mt-16 product-note" v-show="selectedProduct.note">
                        <img src="@/assets/images/icons/info-orange.svg" />
                        <div>
                            {{ selectedProduct.note }}
                        </div>
                    </info-box>
                </div>
            </div>

            <div class="b-order-summary__section --costs">
                <div class="b-order-summary__header">
                    <strong>
                        {{ $t('summary.costs') }}
                    </strong>
                    <div class="b-order-summary__link ml-auto" @click="setShowDiscount()" v-show="currentStep != 5 && !laterPayment">
                        {{ $t('summary.haveDiscountCode') }}
                    </div>
                </div>

                <div class="add-discount-code mt-8" v-show="showDiscount">
                    <form-label>
                        {{ $t('summary.discountCode') }}
                    </form-label>
                    <div class="coupon_wrapper">
                        <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('voucher', errors) }">
                            <input type="text" name="discount-code" class="form-input" v-model="voucher" @keyup.enter="validateVoucher" />
                            <form-error-message :errors="errors" field="voucher"></form-error-message>
                        </form-group>
                        <form-button
                            class="ml-auto ml-md-16 mt-8 mt-md-0 d-inline-flex d-md-flex --secondary "
                            @click="validateVoucher"
                            :loading="sendingForm"
                        >
                            {{ $t('base.apply') }}
                        </form-button>
                    </div>
                </div>

                <div class="b-order-summary__body">
                    <div class="b-order-summary__line">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.cocDocument') }}
                        </div>
                        <div class="b-order-summary__val">{{ order.prices.product }} €</div>
                    </div>

                    <div class="b-order-summary__line" v-if="order.prices.discount > 0">
                        <div class="b-order-summary__prop d-flex align-items-center">
                            <template v-if="customer.type == 'S'">
                                {{ $t('summary.discountPremium') }}
                                <span class="b-order-summary__star"></span>
                            </template>
                            <template v-else>
                                {{ $t('summary.discount') }}
                            </template>
                        </div>
                        <div class="b-order-summary__val">- {{ order.prices.discount }} €</div>
                    </div>

                    <div class="b-order-summary__line" v-if="order.prices.voucher > 0">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.voucher') }}
                        </div>
                        <div class="b-order-summary__val">- {{ order.prices.voucher }} €</div>
                    </div>

                    <div class="b-order-summary__line">
                        <div class="b-order-summary__prop d-flex align-items-center">
                            {{ $t('summary.shipping') }}
                            <span class="ml-8" v-show="order.order.deliveryMethod">
                                {{ $t(getDelivery('name')) }}
                            </span>
                            <div
                                class="b-order-summary__link"
                                v-show="order.order.deliveryMethod && currentStep != 5 && !laterPayment"
                                @click="setShowDeliveryModal(true)"
                            >
                                {{ $t('summary.btnChange') }}
                            </div>
                        </div>
                        <div class="b-order-summary__val">
                            <template v-if="customer.freeDelivery">{{ $t('summary.freeDelivery') }}</template>
                            <template v-else>
                                <template v-if="order.order.deliveryMethod">{{ order.prices.delivery }} €</template>
                                <template v-else-if="laterPayment">{{ order.prices.delivery }} €</template>
                                <span v-else>
                                    {{ $t('summary.notSpecified') }}
                                </span>
                            </template>
                        </div>
                    </div>

                    <div class="b-order-summary__choosed" v-show="customer.name">
                        <div class="d-flex align-items-start mb-8">
                            <strong>
                                {{ $t('summary.invoicingAddress') }}
                            </strong>
                            <span>
                                <template v-if="customer.invoiceData.companyName !== ''">
                                    {{ customer.invoiceData.companyName }}, {{ customer.invoiceData.street }}, {{ customer.invoiceData.postalCode }},
                                    {{ customer.invoiceData.city }} {{ selectedInvoiceCountry.name }}
                                </template>
                                <template v-else>
                                    {{ customer.name }} {{ customer.surname }}, {{ customer.invoiceData.street }},
                                    {{ customer.invoiceData.postalCode }}, {{ customer.invoiceData.city }} {{ selectedInvoiceCountry.name }}
                                </template>
                            </span>
                            <span
                                class="b-order-summary__email-edit"
                                @click="changeInvoiceAddress()"
                                v-show="currentStep != 1 && currentStep != 5 && !laterPayment"
                            ></span>
                        </div>
                        <div class="d-flex align-items-start">
                            <strong>
                                {{ $t('summary.shippingAddress') }}
                            </strong>
                            <span>
                                <template v-if="customer.deliveryIsSame">
                                    {{ $t('summary.sameAsInvoicing') }}
                                </template>
                                <template v-else>
                                    {{ customer.deliveryAddress.street }}, {{ customer.deliveryAddress.postalCode }},
                                    {{ customer.deliveryAddress.city }}
                                    {{ selectedDeliveryCountry.name }}
                                </template>
                            </span>
                            <span
                                class="b-order-summary__email-edit"
                                @click="changeDeliveryAddress()"
                                v-show="currentStep != 1 && currentStep != 5 && !laterPayment"
                            ></span>
                        </div>
                    </div>

                    <div class="b-order-summary__line" v-if="order.order.paymentMethod" v-show="currentStep != 5 && !laterPayment">
                        <div class="b-order-summary__prop d-flex align-items-center">
                            {{ $t('summary.paymentMethod') }}
                            <span class="ml-8">
                                {{ $t(getPayment('name')) }}
                            </span>
                            <div class="b-order-summary__link" @click="setShowPaymentModal(true)">
                                {{ $t('summary.btnChange') }}
                            </div>
                        </div>
                    </div>

                    <div class="b-order-summary__line">
                        <div class="b-order-summary__prop">
                            {{ $t('summary.vat') }}
                        </div>
                        <div class="b-order-summary__val">
                            <!-- <template v-if="customer.invoiceData.country != 0 && customer.personType != ''"> {{ order.prices.vat }} € </template>
                            <span v-else>{{ $t("summary.notSpecified") }}</span> -->
                            {{ order.prices.vat }} €
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="b-order-summary__total">
            <strong>
                {{ $t('summary.totalCosts') }}
                <!-- <span>
                    {{ $t("summary.inclVat") }}
                </span> -->
            </strong>

            <h3>{{ order.prices.price }} €</h3>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import FormLabel from '@/components/form/FormLabel.vue';
import FormGroup from '@/components/form/FormGroup.vue';
import FormButton from '@/components/form/FormButton.vue';
import FormErrorMessage from '@/components/form/FormErrorMessage.vue';
import InfoBox from '@/components/InfoBox.vue';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'AppSidebar',
    components: {
        InfoBox,
        FormLabel,
        FormGroup,
        FormButton,
        FormErrorMessage,
    },
    data() {
        return {
            carTypes: [
                {
                    name: this.$t('summary.vehiclePKW'),
                    type: 'PKW',
                    class: '--pkw',
                },
                {
                    name: this.$t('summary.vehicleLKW'),
                    type: 'LKW',
                    class: '--lkw',
                },
                {
                    name: this.$t('summary.vehicleMOTO'),
                    type: 'MOTO',
                    class: '--moto',
                },
            ],
            showSummary: true,
            showDiscount: false,
            vehicle: {},
            errors: {},
            sendingForm: false,
            voucher: '',
            resized: false,
        };
    },
    mounted() {
        //posuniem summary kvoli sticky position
        let summary = document.querySelector('.b-order-summary'),
            marketLeader = document.querySelector('.b-market-leader');

        if (marketLeader) {
            summary.style.paddingBottom = parseInt(marketLeader.clientHeight) + 50 + 'px';
        }
    },
    created() {
        this.resizeHandler();
        window.addEventListener('resize', this.resizeHandler);
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    computed: {
        ...mapState(['selectedProduct', 'currentStep', 'selectedInvoiceCountry', 'selectedDeliveryCountry', 'laterPayment']),
        ...mapState('customer', ['customer']),
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapMutations(['setShowDeliveryModal', 'setShowPaymentModal', 'setShowEmailModal', 'setShowAddressModal', 'setChangeDeliveryAddress']),
        ...mapMutations('order', ['setPrices', 'setVoucher']),
        resizeHandler() {
            if (!this.resized) {
                if (window.innerWidth < 1024) {
                    this.showSummary = false;
                    this.resized = true;
                } else {
                    this.showSummary = true;
                    this.resized = true;
                }
            }
        },
        getCarType(currentType) {
            return this.carTypes.filter(car => {
                if (car.type == currentType) {
                    return car;
                }
            });
        },
        getDelivery(param = null) {
            if (this.order.order.deliveryMethod) {
                let delivery = this.order.deliveryOptions.filter(delivery => {
                    if (delivery.value == this.order.order.deliveryMethod) {
                        return delivery;
                    }
                })[0];

                if (param) {
                    return delivery[param];
                } else {
                    return delivery;
                }
            } else {
                return '';
            }
        },
        getPayment(param = null) {
            if (this.order.order.paymentMethod) {
                let payment = this.order.paymentOptions.filter(payment => {
                    if (payment.value == this.order.order.paymentMethod) {
                        return payment;
                    }
                })[0];

                if (param) {
                    return payment[param];
                } else {
                    return payment;
                }
            }
        },
        setShowDiscount() {
            this.errors = {};
            this.showDiscount = !this.showDiscount;

            if (this.showDiscount == false) {
                this.voucher = '';
            }
        },
        changeInvoiceAddress() {
            this.setChangeDeliveryAddress(false);
            this.setShowAddressModal(true);
        },
        changeDeliveryAddress() {
            this.setChangeDeliveryAddress(true);
            this.setShowAddressModal(true);
        },
        async validateVoucher() {
            if (this.voucher) {
                this.sendingForm = true;

                let params = {
                    code: this.voucher,
                    clientId: this.customer.registerId,
                    mark: this.selectedProduct.vehicle.name,
                };

                try {
                    let response = await this.$axios.get(process.env.VUE_APP_URL_VOUCHER_VALIDATE, { params: params });

                    if (response.status == '200') {
                        //vymazem errory
                        this.errors = {};

                        //ulozim kupon do vuex
                        this.setVoucher(response.data.name);

                        //zavriem pole na voucher
                        this.setShowDiscount();

                        //zobrazim info o pridanom voucheri
                        toast(this.$t('summary.voucherAdded'));
                    }

                    if (response.status == '404' || response.status == '400') {
                        this.errors = response.errors;
                    }
                } catch (error) {}

                this.sendingForm = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/components/sidebar';

.--secondary {
    background: $orange;

    @include less-than(md) {
        width: auto !important;
    }
}

.processing-info {
    text-align: right;
    font-size: 14px;
    line-height: 18px;
    color: #6a7084;
}

.coupon_wrapper {
    display: flex;

    @include less-than(md) {
        flex-direction: column;
    }
}

.show-hide-btn {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 13px;
    line-height: 16px;
    color: $blue;
    margin-left: 8px;
    cursor: pointer;
    padding-right: 16px;
    display: none;
    @include transition();

    @include less-than(md) {
        display: block;
    }

    &.--hide {
        &:after {
            @include rotate(180deg);
        }
    }

    &:after {
        content: '';
        width: 11px;
        height: 6px;
        background: url($basePath+'icons/arrow-blue-down.svg') no-repeat center;
        position: absolute;
        right: 0;
        top: 5px;
    }
}

.product-note {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    line-height: 16px;
    padding: 16px;

    img {
        width: 20px;
        height: auto;
    }
}
</style>
