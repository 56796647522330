<template>
    <div class="classic-selectbox" :class="{ '--small': isSmall, '--search': allowSearch }">
        <div class="classic-selectbox__vue-select">
            <div
                class="classic-selectbox__selected-item"
                @click="showOptions"
                @keydown.space.prevent="showOptions"
                @keydown.down.prevent="selectNextOption"
                @keydown.up.prevent="selectPrevOption"
                @keydown.enter.prevent="chooseOption"
                @blur="parentBlur"
                :class="{ disabled: isDisabled, opened: optionsVisible, '--small': isSmall }"
                :tabindex="isDisabled ? '' : 0"
            >
                {{ showActiveText }}
            </div>

            <!-- <transition name="fade-select"> -->
            <div class="classic-selectbox__item-list" v-show="optionsVisible">
                <div class="classic-selectbox__searchbox-wrapper" v-if="allowSearch">
                    <div class="classic-selectbox__searchbox-wrapper-inner">
                        <input
                            class="classic-selectbox__searchbox"
                            type="text"
                            @keydown.down.prevent="selectNextOption"
                            @keydown.up.prevent="selectPrevOption"
                            @keydown.enter.prevent="chooseOption"
                            @blur="closeOptions"
                            v-model="search"
                            ref="search"
                        />
                    </div>
                </div>

                <ul class="classic-selectbox__item-list-inner" ref="scrollContainer">
                    <li
                        class="classic-selectbox__item"
                        ref="options"
                        v-for="(option, index) in filteredOptions"
                        :key="index"
                        :class="{ disabled: option.id == '0' || option.value == '' || option.disabled == true, 'is-active': index === arrowCounter }"
                        @click="chooseItem(option)"
                    >
                        <span v-if="option.prefix" class="classic-selectbox__item-prefix">
                            {{ option.prefix }}
                        </span>

                        <strong v-if="option.highlighted">
                            {{ option[labelParam] }}
                        </strong>
                        <template v-else>
                            {{ option[labelParam] }}
                        </template>

                        <span v-if="option.suffix" class="classic-selectbox__item-suffix">
                            {{ option.suffix }}
                        </span>
                    </li>
                </ul>
            </div>
            <!-- </transition> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClassicSelectBox',

    props: {
        name: String,
        options: Array,
        preselectedOption: Object,
        isDisabled: Boolean,
        isSmall: Boolean,
        allowSearch: Boolean,
        activeText: {
            type: String,
            default: '',
        },
        labelParam: {
            type: String,
            default: 'text',
        },
        valueParam: {
            type: String,
            default: 'value',
        },
    },
    emits: ['selected'],
    data() {
        return {
            search: '',
            selected: this.preselectedOption ? this.preselectedOption : this.options[0],
            optionsVisible: false,
            arrowCounter: -1,
        };
    },

    watch: {
        options(options) {
            var optionsWithSelectedValue = options.concat([]).filter(item => {
                return item[this.valueParam] == this.selected[this.valueParam];
            });

            //If options has been changed, and selected value is missing
            if (optionsWithSelectedValue.length == 0) {
                this.selected = this.options[0];
                this.$emit('selected', this.selected[this.valueParam]);
            }
        },
        preselectedOption(data) {
            if (data) {
                this.selected = data;
            }
        },
    },

    methods: {
        closeOptions() {
            setTimeout(() => {
                this.optionsVisible = false;
            }, 300);
        },
        chooseItem(option) {
            this.selected = option;
            this.optionsVisible = false;

            this.$nextTick(this.select);
        },

        parentBlur() {
            if (!this.allowSearch) {
                this.arrowCounter = -1;
                this.optionsVisible = false;
            }
        },

        showOptions() {
            //reset arrowCounter to default value
            this.arrowCounter = -1;
            this.isDisabled ? null : (this.optionsVisible = !this.optionsVisible);

            if (this.optionsVisible && this.allowSearch) {
                setTimeout(() => {
                    this.$refs.search.focus();
                }, 200);
            }
        },

        selectNextOption() {
            if (this.optionsVisible) {
                if (this.arrowCounter < this.filteredOptions.length - 1) {
                    this.arrowCounter = this.arrowCounter + 1;
                } else {
                    this.arrowCounter = 0;
                }

                this.fixScrolling();
            }
        },

        selectPrevOption() {
            if (this.optionsVisible) {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                } else {
                    this.arrowCounter = this.filteredOptions.length - 1;
                }

                this.fixScrolling();
            }
        },

        chooseOption() {
            if (this.optionsVisible) {
                let selOp = this.filteredOptions[this.arrowCounter];

                if (!selOp) {
                    selOp = this.filteredOptions[0];
                }

                if (selOp && selOp[this.valueParam] !== '') {
                    this.selected = selOp;

                    if (this.allowSearch) {
                        this.$refs.search.blur();
                    } else {
                        this.optionsVisible = false;
                    }
                }

                this.select();
                this.optionsVisible = false;
                this.search = '';
            }
        },

        //scroll fix, pri aktivnej polozke mimo visible areu
        fixScrolling() {
            //const itemHeight = this.$refs.options[this.arrowCounter].clientHeight;
            //this.$refs.scrollContainer.scrollTop = itemHeight * this.arrowCounter;
        },
        onChange(e) {
            var selectedOption = this.options.filter(option => option[this.valueParam] == e.target[this.valueParam]);

            if (selectedOption.length > 0) {
                this.selected = selectedOption[0];

                this.$emit('selected', selectedOption[0][this.valueParam]);
            }
        },
        select() {
            this.$emit('selected', this.selected[this.valueParam]);
        },
    },

    computed: {
        filteredOptions() {
            return this.options.filter(option => {
                //nevraciam optiony bez value
                if (option[this.valueParam] != '' && option[this.valueParam] != '0') {
                    if (this.search != '') {
                        if (option.searchable !== false) {
                            if (option.suffix) {
                                return (
                                    (option[this.labelParam] + '').toLowerCase().includes(this.search.toLowerCase()) ||
                                    (option.suffix + '').toLowerCase().includes(this.search.toLowerCase())
                                );
                            } else {
                                return option[this.labelParam].toLowerCase().includes(this.search.toLowerCase());
                            }
                        }
                    } else {
                        if (option.suffix) {
                            return (
                                (option[this.labelParam] + '').toLowerCase().includes(this.search.toLowerCase()) ||
                                (option.suffix + '').toLowerCase().includes(this.search.toLowerCase())
                            );
                        } else {
                            return option[this.labelParam].toLowerCase().includes(this.search.toLowerCase());
                        }
                    }
                }
            });
        },

        showActiveText() {
            if (this.activeText == 'suffix') {
                return this.selected.suffix;
            } else if (this.activeText == 'prefix') {
                return this.selected.prefix;
            } else {
                return this.selected[this.labelParam];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
select,
.classic-selectbox__selected-item {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid $inputBorder !important;
    height: 50px;
    padding: 0 16px;
    font-size: 16px;
    color: $gray;
    cursor: pointer;
    font-family: $font;
    font-weight: $fwNormal;
    background: url($basePath+'icons/dropdown.svg') $selectBg no-repeat center right 16px !important;
    @include border-radius($rad);
    @include box-sizing(border-box);
    @include appearance(none);
    @include transition();

    @include less-than(md) {
        height: 40px;
    }

    &:hover:not(.disabled),
    &:focus:not(.disabled) {
        border-color: $orange;
    }

    &.opened {
        padding: 0 16px;
        background: url($basePath+'icons/dropdown.svg') $selectBg no-repeat center right 16px !important;
    }

    .classic-selectbox.--short & {
        height: 30px;
        padding: 1px 20px 0;
        font-size: 16px;

        &.opened {
            padding: 0.1rem 1.9rem 0;
        }
    }
}

select::-moz-focus-inner {
    border: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $gray;
}

.classic-selectbox__vue-select {
    position: relative;
}

.classic-selectbox__item-list {
    position: absolute;
    z-index: 950;
    top: 52px;
    width: 100%;
    min-width: 340px;
    left: 0;
    background: $white;
    @include shadow(2px, 4px, 10px, 0, rgba(0, 0, 0, 0.1));
    @include translateY(0%);
    @include border-radius($rad);
    @include transition-property(all);
    @include transition-duration($dur);

    .classic-selectbox.--small & {
        top: 6rem;
        min-width: 0rem;
    }

    .classic-selectbox.--short & {
        top: 4.5rem;
        min-width: 0rem;
    }

    .classic-selectbox.--search & {
        overflow: hidden;
        top: 52px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -0.8rem;
        left: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background: $white;
        border-top: 0.1rem solid $inputBorder;
        border-left: 0.1rem solid $inputBorder;
        @include transform(translateX(-50%) rotate(45deg));

        .classic-selectbox.--search & {
            display: none;
        }
    }

    .classic-selectbox__searchbox-wrapper {
        position: relative;
        padding: 8px 16px 0;
    }

    .classic-selectbox__searchbox-wrapper-inner {
        position: relative;

        &:after {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            z-index: 2;
            background: url($basePath+'icons/search.svg') no-repeat center;
            background-size: contain;
            top: 50%;
            right: 8px;
            @include translateY(-50%);
        }
    }

    .classic-selectbox__searchbox {
        padding: 0;
        width: 100% !important;
        border: 0;
        height: 40px;
        font-size: 16px;
        font-family: $font;
        border-bottom: 1px solid #b9bbc0;
        @include border-radius(0);
    }
}

.classic-selectbox__item-list-inner {
    max-height: 230px;
    overflow: auto;
    position: relative;
}

.classic-selectbox__item {
    height: 38px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 16px;
    color: $gray;
    font-size: 16px;
    cursor: pointer;

    &:hover,
    &.is-active {
        background-color: $selectBg;
    }
}

.classic-selectbox__native-select {
    display: none;
}

.classic-selectbox__item-suffix {
    font-style: italic;
    @include opacity(0.6);
    margin-left: 5px;
}
</style>
