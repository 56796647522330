import axios from 'axios';
import { useToast } from 'vue-toastification';

const toast = useToast();

const globalVueMixins = {
    methods: {
        goToHiPay(orderId) {
            window.open(process.env.VUE_APP_HIPAY_URL + '?oid=' + orderId + '&lng=' + this.$i18n.locale, '_self');
        },
        getCookie(cname) {
            var name = cname + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
            }
            return '';
        },
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = 'expires=' + d.toUTCString();
            document.cookie = cname + '=' + cvalue + ';path=/;' + expires;

            return this;
        },
        checkIsAfterTwoClock() {
            var now = this.$moment();
            var hourToCheck = 14;
            var dateToCheck = now.hour(hourToCheck);

            return this.$moment().isAfter(dateToCheck);
        },
        addBusinessDays(date, daysToAdd) {
            var cnt = 0;
            var tmpDate = this.$moment(date).locale('en');

            if (this.checkIsAfterTwoClock()) {
                daysToAdd = parseInt(daysToAdd) + 1;
            }

            while (cnt < daysToAdd) {
                tmpDate = tmpDate.add(1, 'days');
                if (
                    tmpDate.weekday() !=
                        this.$moment()
                            .locale('en')
                            .day('Sunday')
                            .weekday() &&
                    tmpDate.weekday() !=
                        this.$moment()
                            .locale('en')
                            .day('Saturday')
                            .weekday()
                ) {
                    cnt = cnt + 1;
                }
            }

            return tmpDate.format('DD.MM.YYYY');
        },
        generateToken() {
            var rand = function() {
                return Math.random()
                    .toString(36)
                    .substr(2);
            };

            var token = function() {
                return rand() + new Date().getTime();
            };

            return token();
        },
        capitalizeString(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        isObject(a) {
            return !!a && a.constructor === Object;
        },
        isArray(a) {
            return !!a && a.constructor === Array;
        },
        baseGTM(pageTitle) {
            let self = this;
            window.dataLayer = window.dataLayer || [];
            //window.dataLayer = []
            window.dataLayer.push({
                page: { type: 'Cart', uri: window.location.pathname, title: `Checkout | ${pageTitle}` },
                visitorLoginState: self.$store.state.customer['customer'].type == 'N' ? 'anonymous' : 'loggedIn',
                event: 'checkoutPageview',
            });
        },
        loginGTM() {
            window.dataLayer.push({
                event: 'userLogin',
                eventCategory: 'User',
                eventAction: 'Login',
                eventLabel: '',
            });
        },
        personTypeGTM(type) {
            window.dataLayer.push({
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: 1,
                            option: type == 'company' ? 'Company' : 'Private individual',
                        },
                    },
                },
                event: 'ec.checkout_option',
                eventCategory: 'Checkout',
                eventAction: 'Step 1 | Options',
                eventLabel: type == 'company' ? 'Company' : 'Private individual',
            });
        },
        offerLoginGTM() {
            window.dataLayer.push({
                event: 'userLogin',
                eventCategory: 'User',
                eventAction: 'Login offered',
                eventLabel: '',
            });
        },
        registerGTM() {
            window.dataLayer.push({
                event: 'userRegistration',
                eventCategory: 'User',
                eventAction: 'Registration',
                eventLabel: '',
            });
        },
        errorGTM(currentStep, label, errorMessage) {
            window.dataLayer.push({
                event: 'checkoutValidationError',
                eventCategory: 'Checkout Validation Error',
                eventAction: `Step ${currentStep} | ${label}`,
                eventLabel: errorMessage,
            });
        },
        newsletterGTM() {
            window.dataLayer.push({
                event: 'userNewsletterSubscription',
                eventCategory: 'User',
                eventAction: 'Newsletter subscription',
                eventLabel: '',
            });
        },
        getGTMCarString() {
            let brand = this.$store.state.selectedProduct.vehicle.name;
            let type = this.$store.state.selectedProduct.type;
            let gtmType = '';

            if (type == 'PKW') {
                gtmType = 'CAR';
            } else if (type == 'LKW') {
                gtmType = 'LIGHT GOODS VEHICLE';
            } else {
                gtmType = 'MOTORCYCLE';
            }

            return `COC ${brand} ${gtmType} Any EU country`;
        },
        gtmCustomerEvent(pageTitle, currentStep) {
            let self = this;
            this.baseGTM(pageTitle);

            window.dataLayer.push({
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: currentStep,
                            option: self.$store.state.selectedProduct.requiredOrderAttributes.length > 0 ? 'Docs Required' : 'Docs NOT Required',
                        },
                        products: [
                            {
                                name: self.getGTMCarString(),
                                id: self.$store.state.selectedProduct.id,
                                price: self.$store.state.selectedProduct.priceVat,
                                brand: self.$store.state.selectedProduct.vehicle.name,
                                quantity: 1,
                            },
                        ],
                    },
                },
                eventLabel: self.$store.state.selectedProduct.requiredOrderAttributes.length > 0 ? 'Docs Required' : 'Docs NOT Required',
                eventCategory: 'Checkout',
                eventAction: 'Step ' + currentStep,
                event: 'ec.checkout',
            });
        },
        gtmEventNextPage(pageTitle, currentStep) {
            this.baseGTM(pageTitle);

            window.dataLayer.push({
                ecommerce: {
                    checkout: {
                        actionField: { step: currentStep },
                    },
                },
                eventCategory: 'Checkout',
                eventAction: 'Step ' + currentStep,
                event: 'ec.checkout',
                eventLabel: '',
            });
        },
        getCountryName(val) {
            let cntry;
            if (val) {
                cntry = this.$store.state.enums.countries.filter(country => {
                    if (country.id == val) {
                        return country;
                    }
                });
            }

            if (cntry) {
                return cntry[0]['name'];
            } else {
                return '';
            }
        },
        gtmDeliveryPaymentMethod(currentStep, option) {
            window.dataLayer.push({
                event: 'ec.checkout_option',
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: currentStep,
                            option: option,
                        },
                    },
                },
                eventCategory: 'Checkout',
                eventAction: `Step ${currentStep} | Options`,
                eventLabel: option,
            });
        },
        gtmEventPayment(pageTitle) {
            let self = this;
            this.baseGTM(pageTitle);

            window.dataLayer.push({
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: self.$store.state.orderId,
                            revenue: self.$store.state.order['order'].prices.price,
                            tax: self.$store.state.order['order'].prices.vat,
                            shipping: self.$store.state.order['order'].prices.delivery,
                            coupon: self.$store.state.order['order'].order.voucher,
                        },
                        products: [
                            {
                                name: self.getGTMCarString(),
                                id: self.$store.state.selectedProduct.id,
                                price: self.$store.state.selectedProduct.priceVat,
                                brand: self.$store.state.selectedProduct.vehicle.name,
                                quantity: 1,
                            },
                        ],
                    },
                },
                eventCategory: 'Checkout',
                eventAction: 'Purchase',
                eventValue: self.$store.state.order['order'].prices.price,
                event: 'ec.checkout',
                eventLabel: '',
            });
        },
        gtmSendDocuments(currentStep, option) {
            window.dataLayer.push({
                event: 'ec.checkout_option',
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            step: currentStep,
                            option: option,
                        },
                    },
                },
                eventCategory: 'Checkout',
                eventAction: `Step ${currentStep} | Options`,
                eventLabel: option,
            });
        },
        gtmThankYou(pageTitle) {
            let self = this;
            this.baseGTM(pageTitle);

            window.dataLayer.push({
                eventCategory: 'Checkout',
                eventAction: 'Thank you',
                eventLabel: self.$store.state.order['order'].order.paymentMethod == 'card' ? 'HiPay' : 'PayPal',
                event: 'ec.checkout',
            });
        },
        gtmThankYouCustomerS(pageTitle) {
            let self = this;
            this.baseGTM(pageTitle);

            window.dataLayer.push({
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: self.$store.state.orderId,
                            revenue: self.$store.state.order['order'].prices.price,
                            tax: self.$store.state.order['order'].prices.vat,
                            shipping: self.$store.state.order['order'].prices.delivery,
                            coupon: self.$store.state.order['order'].order.voucher,
                        },
                        products: [
                            {
                                name: self.getGTMCarString(),
                                id: self.$store.state.selectedProduct.id,
                                price: self.$store.state.selectedProduct.priceVat,
                                brand: self.$store.state.selectedProduct.vehicle.name,
                                quantity: 1,
                            },
                        ],
                    },
                },
                eventCategory: 'Checkout',
                eventAction: 'Thank you',
                eventLabel: self.$store.state.order['order'].order.deliveryMethod ? self.$store.state.order['order'].order.deliveryMethod : 'free',
                event: 'ec.checkout',
            });
        },
        sendErrorsToGTM(errors, currentStep, deliveryIsSame = null) {
            for (const [key, value] of Object.entries(errors)) {
                if (key == 'delivery' && deliveryIsSame) {
                    //neposielam error ak ide o delivery a je zaskrtnute ze je rovnaka ako invoice.
                } else {
                    if (this.isObject(value)) {
                        for (const [label, error] of Object.entries(value)) {
                            if (this.isArray(error)) {
                                for (var i = 0; i < error.length; i++) {
                                    this.errorGTM(currentStep, label, error[i]);
                                }
                            }
                        }
                    } else {
                        this.errorGTM(currentStep, key, value);
                    }
                }
            }
        },
        apiErrorGTM(message) {
            window.dataLayer.push({
                eventCategory: 'Checkout',
                eventAction: 'Error',
                event: 'checkoutValidationError',
                eventLabel: message,
            });
        },
        asset($path) {
            if (process.env.NODE_APP === 'production') {
                return process.env.VUE_APP_ASSET_URL + $path;
            } else {
                return require(`@/assets/images/${$path}`);
            }
        },
        string_to_slug(str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
            var to = 'aaaaeeeeiiiioooouuuunc------';
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }

            str = str
                .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        },
        // axiosCall(method, service, data = null, params = null, auth = null) {
        //     return window
        //         .$axios({
        //             method: method,
        //             url: service,
        //             data: JSON.stringify(data),
        //             params: params,
        //             auth: auth,
        //         })
        //         .catch(function(error) {
        //             if (error.response) {
        //                 this.apiErrorGTM(`Service ${service} returned an unknown error.`);
        //                 toast(`Service ${service} returned an unknown error.`);
        //             }
        //         });
        // },
        handleAxiosError(message) {
            toast(message);
            this.apiErrorGTM(message);
        },
        showValidationMessages() {
            this.$nextTick(() => {
                const el = document.querySelector('.form-group.--invalid');

                if (el) {
                    el.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                    let newEl = el.querySelector('input, select, textarea');

                    if (newEl) newEl.focus({ preventScroll: true });
                }
            });
        },
        issetObj(param, obj) {
            if (!obj) {
                return false;
            }

            if (param in obj) {
                return true;
            } else {
                return false;
            }
        },
        copyObj(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        async calculateTotalPrice() {
            var $order = this.$store.state.order['order'],
                $customer = this.$store.state.customer['customer'],
                data = {
                    productId: parseInt($order.order.item.productId),
                    userData: {
                        id: $customer.registerId,
                        invoiceCountryId: $customer.invoiceData.country,
                        personType: $customer.personType,
                        vatNumber:
                            this.$store.state.selectedInvoiceCountry.vatCode && $customer.invoiceData.vatId
                                ? this.$store.state.selectedInvoiceCountry.vatCode + $customer.invoiceData.vatId
                                : '',
                    },
                    voucherCode: $order.order.voucher,
                    deliveryType: $order.order.deliveryMethod,
                };

            try {
                let response = await window.$axios.post(process.env.VUE_APP_URL_CALCULATE_PRICE, JSON.stringify(data));

                this.$store.commit('order/setPrices', response.data);
            } catch (error) {
                this.handleAxiosError(`${this.$t('errors.somethingWentWrong')}: ${process.env.VUE_APP_URL_CALCULATE_PRICE} `);
            }
        },
        getOrderForKlarna(updateSession) {
            const $order = this.$store.state.order['order'];
            const $customer = this.$store.state.customer['customer'];
            const $product = this.$store.state.selectedProduct;
            const $countryCode = this.$store.state.selectedInvoiceCountry.code;

            let order = {
                order: {
                    purchase_country: $countryCode,
                    purchase_currency: 'EUR',
                    locale: `${this.$i18n.locale}-${this.$i18n.locale.toUpperCase()}`,
                    order_amount: parseInt(parseFloat($order.prices.price) * 100),
                    order_lines: [
                        {
                            name: `${$product.type} ${$product.vehicle.name}`,
                            quantity: 1,
                            unit_price: parseInt(parseFloat($order.prices.product_price_after_discounts) * 100),
                            tax_rate: 2000,
                            total_amount: parseInt(parseFloat($order.prices.product_price_after_discounts) * 100),
                            total_tax_amount: parseInt(parseFloat($order.prices.vat_product_after_discounts) * 100),
                            product_url: `https://www.eurococ.eu/en/checkout/?product=${$product.id}`,
                        },
                        {
                            name: 'shipping',
                            quantity: 1,
                            unit_price: parseInt((parseFloat($order.prices.delivery) + parseFloat($order.prices.vat_delivery)) * 100),
                            tax_rate: 2000,
                            total_amount: parseInt((parseFloat($order.prices.delivery) + parseFloat($order.prices.vat_delivery)) * 100),
                            total_tax_amount: parseInt(parseFloat($order.prices.vat_delivery) * 100),
                        },
                    ],
                    // billing_address: {
                    //     given_name: $customer.name,
                    //     family_name: $customer.surname,
                    //     email: $customer.email,
                    //     title: $customer.title == 1 ? 'Mr' : 'Mrs',
                    //     street_address: $customer.invoiceData.street,
                    //     postal_code: $customer.invoiceData.postalCode,
                    //     city: $customer.invoiceData.city,
                    //     phone: `${$customer.cellPhonePrefix}${$customer.cellPhoneNumber}`,
                    //     country: $countryCode,
                    // },
                },
            };

            if (updateSession) {
                order.order.billing_address = {
                    given_name: $customer.name,
                    family_name: $customer.surname,
                    email: $customer.email,
                    title: $customer.title == 1 ? 'Mr' : 'Mrs',
                    street_address: $customer.invoiceData.street,
                    postal_code: $customer.invoiceData.postalCode,
                    city: $customer.invoiceData.city,
                    phone: `${$customer.cellPhonePrefix}${$customer.cellPhoneNumber}`,
                    country: $countryCode,
                };
            }

            return order;
        },
    },
};

export default globalVueMixins;
