import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import base from './base';
import customer from './customer';
import order from './order';

export default createStore({
    state: {
        klarnaFailed: false,
        tmpId: false,
        laterPayment: false,
        currentStep: 1,
        currentPath: '',
        orderId: 0,
        modelName: null,
        products: [],
        selectedProduct: {
            id: 0,
            prodType: 0,
            type: 'PKW',
            country: {
                id: 0,
                name: '',
            },
            vehicle: {
                id: 0,
                name: '',
                type: '',
            },
            deliveryTime: '0',
            note: '',
            cocScan: '',
            example: '',
            priceVat: 0,
            logo: '',
            firstRegistrationYear: 0,
            requiredOrderAttributes: [],
        },
        selectedInvoiceCountry: {},
        selectedDeliveryCountry: {},
        enums: {
            cellPhonePrefixes: [],
            countries: [],
        },
        showDeliveryModal: false,
        showPaymentModal: false,
        showEmailModal: false,
        showAddressModal: false,
        changeDeliveryAddress: false,
    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale;
        },
        setCurrentStep(state, step) {
            state.currentStep = step;
        },
        setCurrentPath(state, path) {
            state.currentPath = path;
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        setCellPhonePrefixes(state, payload) {
            state.enums.cellPhonePrefixes = payload;
        },
        setCountries(state, payload) {
            state.enums.countries = payload;
        },
        setSelectedProduct(state, payload) {
            state.selectedProduct = payload[0];
        },
        setShowDeliveryModal(state, payload) {
            state.showDeliveryModal = payload;
        },
        setShowPaymentModal(state, payload) {
            state.showPaymentModal = payload;
        },
        setShowEmailModal(state, payload) {
            state.showEmailModal = payload;
        },
        setShowAddressModal(state, payload) {
            state.showAddressModal = payload;
        },
        setChangeDeliveryAddress(state, payload) {
            state.changeDeliveryAddress = payload;
        },
        setSelectedInvoiceCountry(state, payload) {
            state.selectedInvoiceCountry = payload;
        },
        setSelectedDeliveryCountry(state, payload) {
            state.selectedDeliveryCountry = payload;
        },
        setOrderId(state, payload) {
            state.orderId = payload;
        },
        setLaterPayment(state, payload) {
            state.laterPayment = payload;
        },
        setTmpId(state, payload) {
            state.tmpId = payload;
        },
        setModelName(state, payload) {
            state.modelName = payload;
        },
        setKlarnaFailed(state, payload) {
            state.klarnaFailed = payload;
        },
    },
    modules: {
        customer,
        order,
        base,
    },
    plugins: [
        createPersistedState({
            key: 'vuex-checkout',
            storage: window.sessionStorage,
        }),
    ],
});
