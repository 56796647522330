import { createRouter, createWebHistory } from 'vue-router';
import CustomerInfo from '../views/CustomerInfo.vue';
import DeliveryOptions from '../views/DeliveryOptions.vue';
import PaymentOptions from '../views/PaymentOptions.vue';
import VehicleInfo from '../views/VehicleInfo.vue';
import PaymentInfo from '../views/PaymentInfo.vue';
import LaterPayment from '../views/laterPayment/LaterPayment.vue';
import ThankYou from '../views/ThankYou.vue';
import ThankYouNew from '../views/ThankYouNew.vue';
import Paypal from '../views/Paypal.vue';
import Klarna from '../views/Klarna.vue';
import HiPay from '../views/HiPay.vue';
import PaymentNotSuccessful from '../views/PaymentNotSuccessful.vue';
import PaymentError from '../views/PaymentError.vue';
import PageNotFound from '../views/PageNotFound.vue';

import store from '../store';

const routes = [
    {
        path: '/:locale/checkout/',
        name: 'CustomerInfo',
        component: CustomerInfo,
        props: route => ({ productId: route.query.product, affiliateId: route.query.partner, modelName: route.query.model }),
        beforeEnter: (to, from, next) => {
            if (store.state.orderId != 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/delivery-options',
        name: 'DeliveryOptions',
        component: DeliveryOptions,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId != 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/payment-options',
        name: 'PaymentOptions',
        component: PaymentOptions,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId != 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/vehicle-info',
        name: 'VehicleInfo',
        component: VehicleInfo,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId != 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/payment-info',
        name: 'PaymentInfo',
        component: PaymentInfo,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId == 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/payment',
        name: 'LaterPayment',
        component: LaterPayment,
        props: route => ({ order_id: route.query.order }),
    },
    {
        path: '/:locale/checkout/thank-you',
        name: 'ThankYou',
        component: ThankYouNew,
        props: route => ({ order_id: route.query.oid, customer_email: route.query.customerEmail }),
    },
    // {
    //     path: '/:locale/checkout/thank-you-new',
    //     name: 'ThankYouNew',
    //     component: ThankYouNew,
    //     props: route => ({ order_id: route.query.oid, customer_email: route.query.customerEmail }),
    // },
    {
        path: '/:locale/checkout/paypal',
        name: 'Paypal',
        component: Paypal,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId == 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/klarna',
        name: 'Klarna',
        component: Klarna,
        props: route => ({ new: route.query.new }),
        beforeEnter: (to, from, next) => {
            if (store.state.orderId == 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/hipay',
        name: 'HiPay',
        component: HiPay,
        beforeEnter: (to, from, next) => {
            if (store.state.orderId == 0) {
                removeData();
            } else {
                next();
            }
        },
    },
    {
        path: '/:locale/checkout/payment-declined',
        name: 'PaymentDeclined',
        component: PaymentNotSuccessful,
    },
    {
        path: '/:locale/checkout/hipay-payment-success',
        name: 'HiPayPaymentSuccess',
        component: ThankYou,
        props: route => ({ order_id: route.query.oid }),
    },
    {
        path: '/:locale/checkout/hipay-payment-declined',
        name: 'HiPayPaymentDeclined',
        component: PaymentNotSuccessful,
        props: route => ({ order_id: route.query.oid }),
    },
    {
        path: '/:locale/checkout/hipay-payment-pending',
        name: 'HiPayPaymentPending',
        component: PaymentNotSuccessful,
        props: route => ({ order_id: route.query.oid }),
    },
    {
        path: '/:locale/checkout/hipay-payment-error',
        name: 'HiPayPaymentError',
        component: PaymentNotSuccessful,
        props: route => ({ order_id: route.query.oid }),
    },
    {
        path: '/:locale/checkout/hipay-payment-cancel',
        name: 'HiPayPaymentCancel',
        component: PaymentNotSuccessful,
        props: route => ({ order_id: route.query.oid }),
    },
    {
        path: '/:locale/checkout/payment-error',
        name: 'PaymentError',
        component: PaymentError,
        props: route => ({ order_id: route.query.oid, message: route.query.msg }),
    },
    {
        path: '/:locale/checkout/:catchAll(.*)',
        name: 'NotFound',
        component: PageNotFound,
    },
];

function removeData() {
    //Create the element using the createElement method.
    var myDiv = document.createElement('div');

    //Set its unique ID.
    myDiv.id = 'loader-not';

    //Add your content to the DIV
    myDiv.innerHTML =
        '<svg class="spinner-not" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>';

    //Finally, append the element to the HTML body
    document.body.appendChild(myDiv);

    window.sessionStorage.clear();
    window.location.replace(process.env.VUE_APP_BASE_URL);
}

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_PATH_URL),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes,
});

export default router;
