<template>
    <p class="text">
        <slot></slot>
    </p>
</template>

<style lang="scss" scoped>
.text {
    color: $lightGray;
}
</style>
