<template>
    <div>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <head-title class="mb-8">
                            <!-- {{ $t("payment.thankYouForOrder") }}  -->
                            {{ $t("payment.payUsingBankTransfer") }}
                        </head-title>
                        <strong class="order-number">
                            <span class="text-lightgray">{{ $t("payment.orderNumber") }} </span>&nbsp;
                            <span class="text-orange"> {{ orderId }}</span>
                        </strong>
                        <info-box class="mt-16 mb-32 align-items-start info-box__text">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div>
                                {{ $t("payment.info") }}
                            </div>
                        </info-box>

                        <head-title class="mt-48">
                            {{ $t("payment.bankTransferInfo") }}
                        </head-title>

                        <div class="warning mt-4">
                            {{ $t("payment.bankInfoChanged") }}
                        </div>

                        <table class="data-table mt-32" cellspacing="0" cellspadding="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.iban") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">DE74760700240694466400</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.bicSwift") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">DEUTDEDB760</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.variableSymbol") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">{{ orderId }}</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.amount") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">{{ order.prices.price }} €</strong>
                                        <span class="small-text text-lightgray d-block">
                                            {{ $t("payment.exactPrice", { price: order.prices.price }) }}
                                        </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.accountOwner") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">EuroCoc, spol. s r. o.</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.address") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">Bratislava, Slovakia</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.bank") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">Deutsche Bank AG, Bayreuth, Germany</strong>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <span class="text-lightgray">{{ $t("payment.bankCharges") }}</span>
                                    </td>
                                    <td>
                                        <strong class="text-darkgray">{{ $t("payment.allChargesInfo") }}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--  <head-title class="mt-64">
                            <span class="text-orange">{{ $t("payment.uploadPaymentConfirmation") }}</span>
                        </head-title> -->

                        <!-- <form-group>
                            <form-label>
                                {{ $t("payment.docs.paymentConfirmation") }}
                                <form-label-info>
                                    <div class="tooltip-content">
                                        {{ $t("tooltips.docConfirmPayment") }}
                                    </div>
                                </form-label-info>
                            </form-label>
                            <drag-drop @files="userData.certificate = $event"></drag-drop>
                        </form-group> -->

                        <!-- BTN -->
                        <!-- <div class="d-flex justify-content-end mt-48">
                            <router-link :to="{ name: 'ThankYou' }">
                                <form-button>
                                    {{ $t("base.upload") }}
                                </form-button>
                            </router-link>
                        </div> -->
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <app-sidebar></app-sidebar>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"

// import AppBreadcrumb from "@/components/base/AppBreadcrumb.vue"
import AppSidebar from "@/components/base/AppSidebar.vue"
import AppMarketLeader from "@/components/base/AppMarketLeader.vue"
import HeadTitle from "@/components/headings/HeadTitle.vue"
//import FormButton from "@/components/form/FormButton.vue"
import InfoBox from "@/components/InfoBox.vue"
//import DragDrop from "@/components/dragdrop/DragDrop"
//import FormGroup from "@/components/form/FormGroup.vue"
//import FormLabel from "@/components/form/FormLabel.vue"
//import FormLabelInfo from "@/components/form/FormLabelInfo.vue"

export default {
    name: "PaymentInfo",
    components: {
        // AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        //FormButton,
        InfoBox
        //DragDrop,
        //FormGroup,
        //FormLabel,
        //FormLabelInfo
    },
    data() {
        return {}
    },
    mounted() {
        this.setCurrentStep(5)
        this.setCurrentPath("PaymentInfo")

        this.gtmEventPayment("Bank Transfer")
    },
    created() {},
    computed: {
        ...mapState(["orderId"]),
        ...mapState("order", ["order"])
    },
    methods: {
        ...mapMutations(["setCurrentStep", "setCurrentPath"])
        //...mapMutations("order", ["setVin", "setTypeApprovalNumber", "setRegistrationYear", "setClientNote"])
    }
}
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

.text-orange {
    color: $orange;
}

strong {
    font-weight: $fwSemi;
}

.text-lightgray {
    color: $lightGray;
}

.data-table {
    border-top: 1px solid $orange;
    border-bottom: 1px solid $orange;

    tr {
        &:last-child {
            td {
                border: 0;
            }
        }
    }

    td {
        vertical-align: top;
        border-bottom: 1px solid $inputBorder;
        padding: 8px 0;

        &:first-child {
            max-width: 180px;
            min-width: 180px;
            padding-right: 16px;

            @include less-than(md) {
                min-width: 150px;
            }
        }

        @include less-than(md) {
            font-size: 13px;
        }
    }
}

.info-box__text {
    @include less-than(md) {
        font-size: 13px;
        line-height: 18px;
    }
}
</style>
