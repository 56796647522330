<template>
    <div>
        <app-breadcrumb></app-breadcrumb>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <head-title class="mb-16">
                            {{ $t('paymentOptions.headline') }}
                        </head-title>
                        <!-- <info-box class="info-box mt-16 align-items-start">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div>
                                {{ $t('paymentOptions.info') }}
                            </div>
                        </info-box> -->

                        <PaymentOptionsC @selected="setPaymentOption($event)" />

                        <!-- BTN -->
                        <div class="d-flex justify-content-end mt-48">
                            <form-button @click.prevent="changePaymentMethod()">
                                {{ $t('paymentOptions.continueToVehicle') }}
                            </form-button>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <app-sidebar></app-sidebar>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div id="mask" v-show="mask"></div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppBreadcrumb from '@/components/base/AppBreadcrumb.vue';
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';
import InfoBox from '@/components/InfoBox.vue';
import PaymentOptionsC from '@/components/payment/PaymentOptionsC';
import KlarnaService from '@/utilities/klarna.js';
import { isEmpty } from 'lodash';

export default {
    name: 'LaterPayment',
    props: ['order_id'],
    components: {
        AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        InfoBox,
        FormButton,
        PaymentOptionsC,
    },
    data() {
        return {
            mask: false,
            paymentData: '',
        };
    },
    async mounted() {
        this.setCurrentStep(10);

        this.baseGTM('LaterPayment');

        this.paymentData = this.order.order.paymentMethod;
    },
    created() {
        this.setLaterPayment(true);

        //tu nacitam objednavku podla id
        if (this.order_id) {
            this.getOrderData();
        } else {
            this.initKlarna();
        }
    },
    computed: {
        ...mapState(['orderId', 'laterPayment', 'enums']),
        ...mapState(['products', 'currentStep', 'orderId']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setProducts', 'setSelectedProduct', 'setCurrentStep', 'setLaterPayment', 'setOrderId', 'setSelectedInvoiceCountry']),
        ...mapMutations('order', ['setPaymentMethod', 'setProductId', 'setPrices', 'setPaymentOptions']),
        ...mapMutations('customer', ['setCustomer']),
        async initKlarna() {
            if (!this.order_id) {
                let calculatePrices = await this.calculateTotalPrice();
            }
            //init klarna with options
            let klarna = new KlarnaService({ order: this.getOrderForKlarna(), orderId: null });
            let initKlarna = await klarna.createSession();

            klarna.getPaymentOptions().forEach(method => {
                this.setPaymentOptions(method);
            });
        },
        async getProducts() {
            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_PRODUCTS + this.$i18n.locale);

                this.setProducts(response);

                if (!this.order.order.item.productId) {
                    throw new Error(this.$t('errors.productNotFound'));
                }

                if (!isEmpty(this.getSelectedProduct())) {
                    this.setSelectedProduct(this.getSelectedProduct());

                    //prepocitam cenu
                    // this.calculateTotalPrice();
                } else {
                    throw new Error(this.$t('errors.productNotFound'));
                }
            } catch (error) {
                this.handleAxiosError(error.message);
            }

            this.pageLoading = false;
        },
        getSelectedProduct() {
            let productId = this.order.order.item.productId;

            return this.products.filter(product => {
                if (product.id == productId) {
                    return product;
                }
            });
        },
        setPaymentOption(e) {
            this.paymentData = e;
        },
        changePaymentMethod() {
            if (this.paymentData) {
                this.setPaymentMethod(this.paymentData);
            }

            if (this.paymentData == 'card') {
                //this.$router.push({ name: "HiPay" })
                this.goToHiPay(this.orderId);
            } else if (this.paymentData == 'paypal') {
                this.$router.push({ name: 'Paypal' });
            } else if (this.paymentData == 'bank') {
                this.$router.push({ name: 'PaymentInfo' });
            } else if (this.paymentData == 'klarna_pay_now') {
                this.$router.push({ name: 'Klarna', query: { new: '1' } });
            } else if (this.paymentData == 'klarna_pay_over_time') {
                this.$router.push({ name: 'Klarna', query: { new: '1' } });
            } else if (this.paymentData == 'klarna_pay_later') {
                this.$router.push({ name: 'Klarna', query: { new: '1' } });
            }
        },
        async getOrderData() {
            this.mask = false;

            let params = {
                id: this.order_id,
            };

            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_ORDER_DATA, { params: params });

                if (response.status == '200') {
                    let respObj = response.data;

                    let cannotPay = [1500, 1200, 1400, 1300, 400];
                    let pay = [300, 200, 1050, 1150];

                    //ak sa da platit
                    if (pay.includes(respObj.state)) {
                        this.setProductId(respObj.product_id);
                        this.setOrderId(respObj.order_id);

                        let obj = respObj.user_data;
                        let objAdditional = this.copyObj(this.$store.state.base.baseCustomerAdditional);
                        let newObj = Object.assign(obj, objAdditional);

                        //Ak sa nenachadza personType, doplnim ho
                        if (newObj.personType == '') {
                            newObj.personType = newObj.invoiceData.companyName ? 'company' : 'individual';
                        }

                        //odstranim prve dva znaky z vat ak su pismena
                        let letters = /^[a-zA-Z]+$/;
                        if (newObj.invoiceData.vatId) {
                            if (letters.test(newObj.invoiceData.vatId.substring(0, 2))) {
                                newObj.invoiceData.vatId = newObj.invoiceData.vatId.substring(2);
                            }
                        }

                        // //zmenim adresu na nie je rovnaka ako invoice
                        // newObj.deliveryIsSame = false

                        //vlozim data do VUEX
                        this.setCustomer(newObj);

                        //nacitam vsetky produkty
                        this.getProducts();

                        //nasetujem ceny
                        this.setPrices(respObj.prices);

                        setTimeout(() => {
                            //setnem vybranu invoice country
                            this.enums.countries.filter(country => {
                                if (country.id == respObj.user_data.invoiceData.country) {
                                    //setujem selectnutu krajinu kvoli prefixu
                                    this.setSelectedInvoiceCountry(country);
                                }
                            });

                            this.initKlarna();
                        }, 1000);
                    } else if (cannotPay.includes(respObj.state)) {
                        this.mask = true;
                        throw new Error(this.$t('errors.canceledOrder', { email: 'info@eurococ.eu' }), { timeout: false });
                    } else {
                        this.mask = true;
                        throw new Error(this.$t('errors.alreadyPaid'), { timeout: false });
                    }
                }
            } catch (error) {
                this.handleAxiosError(error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

#mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background: $white;
}
</style>
