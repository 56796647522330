<template>
    <div>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 pt-0 pt-md-32 text-center">
                        <!-- HEADLINE -->
                        <head-title class="mb-8">
                            <!-- <span class="text-orange"> -->
                            {{ $t('paymentErrors.somethingWentWront') }}
                            <!-- </span> -->
                        </head-title>
                        <div class="row">
                            <div class="col-12 col-md-6 ml-auto mr-auto">
                                <p class="mt-32">{{ $t('onlinePayment.infoDeclined') }}</p>
                            </div>
                        </div>

                        <div class="row mt-16">
                            <div class="col-12 col-md-6 ml-auto mr-auto">
                                <span class="order">
                                    {{ $t('payment.orderNumber') }}
                                    <i>{{ order_id }}</i>
                                </span>
                            </div>
                        </div>

                        <div class="row mt-16">
                            <div class="col-12 col-md-6 ml-auto mr-auto">
                                <span class="order">
                                    {{ $t('paymentErrors.errorMessage') }}
                                    <br />
                                </span>
                                <p>
                                    {{ message }}
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-6 ml-auto mr-auto"></div>
                        </div>

                        <div class="d-flex justify-content-center mt-48">
                            <form-button @click="goToPayment()">
                                {{ $t('base.tryAgain') }}
                            </form-button>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-6 ml-auto mr-auto">
                                <p class="small-text mt-24" v-html="$t('paymentErrors.problemPersists')"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';

export default {
    name: 'PaymentError',
    props: ['order_id', 'message'],
    components: {
        HeadTitle,
        FormButton,
    },
    data() {
        return {};
    },
    mounted() {
        this.setCurrentStep(6);
        this.setCurrentPath('PaymentError');
    },
    created() {},
    computed: {
        ...mapState(['orderId']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        goToPayment() {
            if (this.order.order.paymentMethod == 'paypal') {
                this.$router.push({ name: 'Paypal' });
            } else if (this.order.order.paymentMethod == 'card') {
                this.goToHiPay(this.orderId);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-orange {
    color: $orange;
}

.order {
    font-size: 18px;
    line-height: 27px;
    color: $darkGray;

    i {
        font-style: normal;
        color: $orange;
    }
}

p {
    font-size: 16px;
    line-height: 24px;
}

.small-text {
    color: $lightGray;
    font-size: 13px;

    &::v-deep(a) {
        color: $orange;

        &:hover {
            color: $darkBlue;
        }
    }
}
</style>
