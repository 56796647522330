<template>
    <div class="b-delivery-options mt-24">
        <label class="form-radio" v-for="(payment, index) in order.paymentOptions" :key="index">
            <div class="form-radio__popular" v-if="payment.popular">
                <span>{{ $t('base.mostPopular') }}</span>
            </div>
            <input type="radio" name="payment" :value="payment.value" class="form-radio__input" v-model="paymentData" />
            <span class="form-radio__bg"></span>
            <span class="form-radio__checkmark"></span>
            <div class="form-radio__label">
                <div class="b-delivery-options__title">
                    <div class="d-flex align-items-center">
                        {{ $t(payment.name) }}
                        <img
                            v-if="payment.logo"
                            :src="require(`@/assets/images/icons/${payment.logo}`)"
                            class="d-inline-flex align-items-center ml-8"
                        />
                    </div>

                    <div class="b-delivery-options__badge" v-if="payment.badge">
                        {{ $t(payment.badge) }}
                    </div>
                </div>
                <div class="b-delivery-options__info" v-if="payment.info">
                    {{ $t(payment.info) }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    props: {},
    components: {},
    emits: ['selected'],
    data() {
        return {
            paymentData: '',
        };
    },
    created() {},
    mounted() {
        this.paymentData = this.order.order.paymentMethod;
    },
    computed: {
        ...mapState('order', ['order']),
    },
    methods: {},
    watch: {
        paymentData() {
            this.$emit('selected', this.paymentData);
        },
        //sledujem kvoli moznej zmene v popupe, ked zmenim deliveryAddress
        'order.order.paymentMethod': function() {
            this.paymentData = this.order.order.paymentMethod;
        },
    },
};
</script>

<style lang="scss" scoped></style>
