<template>
    <div class="app-container">
        <transition name="fade">
            <app-loader v-show="pageLoading"></app-loader>
        </transition>
        <app-header></app-header>

        <div class="app-content">
            <router-view />
        </div>

        <modal-delivery-options v-if="!laterPayment"></modal-delivery-options>
        <modal-payment-options v-if="!laterPayment"></modal-payment-options>

        <template v-if="addCustomerModals()">
            <modal-email v-if="!laterPayment"></modal-email>
            <modal-address v-if="currentStep > 1 && !laterPayment"></modal-address>
        </template>

        <app-footer></app-footer>
        <div id="customCheckoutDiv"></div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ModalDeliveryOptions from '@/components/modals/ModalDeliveryOptions.vue';
import ModalPaymentOptions from '@/components/modals/ModalPaymentOptions.vue';
import ModalEmail from '@/components/modals/ModalEmail.vue';
import ModalAddress from '@/components/modals/ModalAddress.vue';
import AppLoader from '@/components/base/AppLoader.vue';
import AppHeader from '@/components/base/AppHeader.vue';
import AppFooter from '@/components/base/AppFooter.vue';
import { mapKeys, isEmpty } from 'lodash';

export default {
    name: 'App',
    props: [],
    components: {
        ModalDeliveryOptions,
        ModalPaymentOptions,
        ModalEmail,
        ModalAddress,
        AppHeader,
        AppFooter,
        AppLoader,
    },
    data() {
        return {
            pageLoading: true,
            userId: null,
        };
    },
    mounted() {
        //ak je customer prazdny objekt vlozim tam base strukturu
        if (isEmpty(this.customer)) {
            let obj = this.copyObj(this.$store.state.base.baseCustomer);
            let objAdditional = this.copyObj(this.$store.state.base.baseCustomerAdditional);
            this.setCustomer(Object.assign(obj, objAdditional));
            this.setCustomerLang(this.$i18n.locale);
        }

        //ak je order prazdny objekt vlozim tam base strukturu
        if (isEmpty(this.order.order)) {
            this.setOrder(this.copyObj(this.$store.state.base.baseOrder));
            this.setOrderLang(this.$i18n.locale);
        }

        //get initial data
        this.getInitialData();

        //if de version, generate trusted badge
        this.generateTrustedBadge();
    },
    created() {
        //Start of Zendesk Chat Script
        let script = document.createElement('script');
        script.innerHTML =
            'window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");$.src="https://v2.zopim.com/?rpl2whkQxHUURQ2tYt87OcBmvZYxEZ8W";z.t=+new Date;$.type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");';
        document.body.appendChild(script);
    },
    computed: {
        ...mapState(['selectedProduct', 'currentStep', 'laterPayment', 'tmpId']),
        ...mapState('base', ['baseOrder', 'baseCustomer']),
        ...mapState('customer', ['customer']),
        ...mapState('order', ['order']),
        currentRouteName() {
            return this.$route.name;
        },
    },
    watch: {
        'order.order': {
            deep: true,

            handler() {
                if (!this.laterPayment) {
                    this.calculateTotalPrice();
                }
            },
        },
        selectedProduct: {
            deep: true,

            handler() {
                if (!this.laterPayment) {
                    this.calculateTotalPrice();
                }
            },
        },
    },
    methods: {
        ...mapMutations(['setCellPhonePrefixes', 'setCountries', 'setTmpId']),
        ...mapMutations('customer', ['setCustomer', 'setCustomerLang']),
        ...mapMutations('order', ['setOrder', 'setDeliveryOptionsPrices', 'setOrderLang', 'setOrderType', 'setClientId']),
        addCustomerModals() {
            var notAllowed = [
                'ThankYou',
                'HiPayPaymentSuccess',
                'HiPayPaymentDeclined',
                'HiPayPaymentPending',
                'HiPayPaymentError',
                'HiPayPaymentCancel',
                'PaymentError',
            ];
            if (notAllowed.includes(this.currentRouteName)) {
                return false;
            } else {
                return true;
            }
        },
        async getInitialData() {
            try {
                const enumPhones = await this.getCellPhonePrefixes();
                const enumCountries = await this.getCountries();
                const deliveryPrices = await this.getDeliveryPrices();

                if (!this.tmpId) {
                    this.setTmpId(this.generateToken());
                }

                //nacitam usera
                if (window.userId) {
                    this.userId = window.userId;
                    this.getUserData();
                }

                this.pageLoading = false;
            } catch (error) {
                if (error && error.message) {
                    this.handleAxiosError(error.message);
                }
            }
        },
        async getCellPhonePrefixes() {
            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_ENUM_CELL_PHONE_PREFIX + this.$i18n.locale);

                let data = [],
                    map = { prefix: 'value', country: 'text' };

                //pushnem default option
                data.push({ suffix: this.$t('base.select'), text: '', value: '' });

                //pretransformujem hodnoty do potrebnej struktury pre custom select
                for (var i = 0; i < response.data.items.length; i++) {
                    /*eslint no-unused-vars: ["error", { "args": "none" }]*/
                    var item = mapKeys(response.data.items[i], function(value, key) {
                        return map[key];
                    });

                    item.suffix = item.value;
                    data.push(item);
                }

                //ulozim hodnoty do VUEX
                this.setCellPhonePrefixes(data);

                return data;
            } catch (error) {
                throw new Error(this.$t('errors.enumsPrefixesNotLoaded'));
            }
        },
        async getCountries() {
            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_ENUM_COUNTRY + this.$i18n.locale),
                    mostUsedSort = [79, 116, 86, 350, 24, 357],
                    mostUsed = [];

                for (let i = 0; i < response.data.items.length; i++) {
                    let item = response.data.items[i];

                    if (mostUsedSort.includes(item.id)) {
                        //non reactive hodnoty
                        mostUsed.push(this.copyObj(item));
                    }
                }

                //zosortujem podla definovaneho pola
                mostUsed.sort(function(a, b) {
                    return mostUsedSort.indexOf(a.id) - mostUsedSort.indexOf(b.id);
                });

                //pridam param searchable = false, kvoli selectu (aby nehladalo duplicity)
                mostUsed.forEach(function(obj) {
                    obj.searchable = false;
                    obj.highlighted = true;
                });

                //vlozim default option
                mostUsed.unshift({ name: this.$t('customer.selectCountry'), id: '0', eu: '', vatCode: '' });

                //spojim polia
                let finalData = [...mostUsed, ...response.data.items];

                this.setCountries(finalData);

                return finalData;
            } catch (error) {
                throw new Error(this.$t('errors.enumsCountriesNotLoaded'));
            }
        },
        async getDeliveryPrices() {
            let params = {
                country: 10,
            };

            if (this.customer.deliveryAddress !== undefined) {
                params.country = this.customer.deliveryAddress.country;
            }

            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_ENUM_DELIVERY, { params: params });
                this.setDeliveryOptionsPrices(response.data);

                return response.data;
            } catch (error) {
                throw new Error(this.$t('errors.enumsDeliveryNotLoaded'));
            }
        },
        async getUserData() {
            let params = {
                id: this.userId,
            };

            try {
                let response = await this.$axios.get(process.env.VUE_APP_URL_USER_DATA, { params: params });
                let obj = response,
                    objAdditional = this.copyObj(this.$store.state.base.baseCustomerAdditional),
                    newObj = Object.assign(obj, objAdditional);

                //Ak sa nenachadza personType, doplnim ho
                if (newObj.personType == '') {
                    newObj.personType = newObj.invoiceData.companyName ? 'company' : 'individual';
                }

                //odstranim prve dva znaky z vat ak su pismena
                let letters = /^[a-zA-Z]+$/;
                if (newObj.invoiceData.vatId) {
                    if (letters.test(newObj.invoiceData.vatId.substring(0, 2))) {
                        newObj.invoiceData.vatId = newObj.invoiceData.vatId.substring(2);
                    }
                }

                //vlozim data do VUEX
                this.setCustomer(newObj);

                //nastavim udaje v objednavke
                this.setOrderType(newObj.type);
                this.setClientId(newObj.registerId);
            } catch (error) {
                throw new Error(this.$t('errors.somethingWentWrong'));
            }
        },
        generateTrustedBadge() {
            if (this.$i18n.locale == 'de') {
                var _tsid = 'X02A2421908AC1FBE9876C612204436B8';
                window._tsConfig = {
                    variant: 'custom_reviews',
                    responsive: { variant: 'floating', position: 'left', yOffset: '0' },
                    customElementId: 'customCheckoutDiv',
                };
                var _ts = document.createElement('script');
                _ts.type = 'text/javascript';
                _ts.charset = 'utf-8';
                _ts.async = true;
                _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';
                var __ts = document.getElementsByTagName('script')[0];
                __ts.parentNode.insertBefore(_ts, __ts);
            }
        },
    },
};
</script>

<style lang="scss">
.app-content {
    margin-bottom: 128px;

    @include less-than(md) {
        margin-bottom: 96px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.Vue-Toastification__toast--default {
    background-color: $orange !important;
    @include border-radius($rad !important);
}

.Vue-Toastification__toast {
    padding: 12px 16px !important;
    min-height: 48px !important;
    align-items: center !important;
    min-width: auto;
}

.Vue-Toastification__progress-bar {
    height: 2px !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.Vue-Toastification__toast-body {
    font-size: 14px !important;
    font-family: $font !important;
    line-height: 21px !important;
}

#customCheckoutDiv {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 200;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
}
</style>
