<template>
    <div>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 pt-0 pt-md-32 text-center">
                        <!-- HEADLINE -->
                        <head-title class="mb-8">
                            <!-- <span class="text-orange"> -->
                            {{ $t("onlinePayment.headlineDeclined") }}
                            <!-- </span> -->
                        </head-title>
                        <span class="order">
                            Order number: <i>{{ orderId ? orderId : order_id }}</i>
                        </span>

                        <div class="row">
                            <div class="col-12 col-md-6 ml-auto mr-auto">
                                <p class="mt-32">{{ $t("onlinePayment.infoDeclined") }}</p>
                            </div>
                        </div>

                        <div class="d-flex justify-content-center mt-48">
                            <form-button class="ml-16 --secondary" @click="setShowPaymentModal(true)">
                                {{ $t("base.changePaymentMethod") }}
                            </form-button>
                            <form-button class="ml-16" @click="goToPayment()">
                                {{ $t("base.tryAgain") }}
                            </form-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <modal-payment-options :gotopaymentscreen="true"></modal-payment-options>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"

import HeadTitle from "@/components/headings/HeadTitle.vue"
import FormButton from "@/components/form/FormButton.vue"
import ModalPaymentOptions from "@/components/modals/ModalPaymentOptions.vue"

export default {
    name: "PaymentInfo",
    props: ["order_id"],
    components: {
        HeadTitle,
        FormButton,
        ModalPaymentOptions
    },
    data() {
        return {}
    },
    mounted() {
        this.setCurrentStep(6)
        this.setCurrentPath("PaymentNotSuccessful")

        if (this.order_id) {
            this.setOrderId(this.order_id)
        }
    },
    created() {},
    computed: {
        ...mapState(["orderId"]),
        ...mapState("order", ["order"]),
        ...mapState("customer", ["customer"])
    },
    methods: {
        ...mapMutations(["setCurrentStep", "setShowPaymentModal", "setCurrentPath", "setOrderId"]),
        goToPayment() {
            if (this.order.order.paymentMethod == "paypal") {
                this.$router.push({ name: "Paypal" })
            } else {
                //this.$router.push({ name: "HiPay" })
                this.goToHiPay(this.orderId)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-orange {
    color: $orange;
}

.order {
    font-size: 18px;
    line-height: 27px;
    color: $darkGray;

    i {
        font-style: normal;
        color: $orange;
    }
}

p {
    font-size: 16px;
    line-height: 24px;
}
</style>
