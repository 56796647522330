export default {
    namespaced: true,
    state: {
        customer: {}
    },
    mutations: {
        setCustomer(state, payload) {
            state.customer = payload
        },
        setEmail(state, email) {
            state.customer.email = email
        },
        setType(state, type) {
            state.customer.type = type
        },
        setPersonType(state, type) {
            state.customer.personType = type
        },
        setVatID(state, payload) {
            state.customer.invoiceData.vatId = payload
        },
        setSalutation(state, payload) {
            state.customer.title = payload
        },
        setFirstName(state, payload) {
            state.customer.name = payload
        },
        setLastName(state, payload) {
            state.customer.surname = payload
        },
        setCellPhonePrefix(state, payload) {
            state.customer.cellPhonePrefix = payload
        },
        setSellPhoneNumber(state, payload) {
            state.customer.cellPhoneNumber = payload
        },
        setCompany(state, payload) {
            state.customer.invoiceData.companyName = payload
        },
        setStreet(state, payload) {
            state.customer.invoiceData.street = payload
        },
        setNoHouseNumbers(state, payload) {
            state.customer.invoiceData.streetNumberNone = payload
        },
        setZip(state, payload) {
            state.customer.invoiceData.postalCode = payload
        },
        setCity(state, payload) {
            state.customer.invoiceData.city = payload
        },
        setCountry(state, payload) {
            state.customer.invoiceData.country = payload
        },
        setDeliveryName(state, payload) {
            state.customer.deliveryAddress.name = payload
        },
        setDeliveryStreet(state, payload) {
            state.customer.deliveryAddress.street = payload
        },
        setDeliveryNoHouseNumbers(state, payload) {
            state.customer.deliveryAddress.streetNumberNone = payload
        },
        setDeliveryZip(state, payload) {
            state.customer.deliveryAddress.postalCode = payload
        },
        setDeliveryCity(state, payload) {
            state.customer.deliveryAddress.city = payload
        },
        setDeliveryCountry(state, payload) {
            state.customer.deliveryAddress.country = payload
        },
        setDeliveryIsSame(state, payload) {
            state.customer.deliveryIsSame = payload
        },
        setAddressNote(state, payload) {
            state.customer.addressNote = payload
        },
        setCreateAccount(state, payload) {
            state.customer.createAccount = payload
        },
        setSignToNewsletter(state, bool) {
            state.customer.sendNotify = bool
        },
        setRegisterId(state, payload) {
            state.customer.registerId = payload
        },
        setCustomerLang(state, payload) {
            state.customer.lang = payload
        }
    },
    getters: {
        getAddressNoteLength(state) {
            return state.customer.addressNote.length
        }
    },
    actions: {},
    modules: {}
}
