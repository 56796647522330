<template>
    <modal :show-modal="showAddressModal" @close="setShowAddressModal(false)">
        <template v-slot:header>
            {{ $t("customer.addressChangeTitle") }}
        </template>

        <template v-slot:body>
            <customer-form :inmodal="true"></customer-form>
        </template>
    </modal>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"
import CustomerForm from "@/components/customer/CustomerForm.vue"

import Modal from "./Modal"

export default {
    name: "ModalAddress",
    components: {
        Modal,
        CustomerForm
    },
    data() {
        return {}
    },
    methods: {
        ...mapMutations(["setShowAddressModal"])
    },
    computed: {
        ...mapState(["showAddressModal"])
    }
}
</script>
