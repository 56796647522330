<template>
	<footer class="footer">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<ul class="footer__menu">
						<li class="footer__li" v-html="$t('footer.privacyStatement')"></li>
						<li class="footer__li" v-html="$t('footer.personalData')"></li>
						<li class="footer__li" v-html="$t('footer.termsAndConditions')"></li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<span class="footer__copy">{{ $t("footer.copy", { currentYear: new Date().getFullYear() }) }}</span>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "AppFooter",
	components: {},
	data() {
		return {}
	},
	mounted() {},
	created() {},
	computed: {},
	methods: {}
}
</script>

<style lang="scss">
.footer {
	margin-top: auto;
	padding: 32px 0;
	background: $darkBlue;
}

ul.footer__menu {
	display: flex;
	justify-content: center;

	@include less-than(md) {
		display: block;
		text-align: center;
	}

	.footer__li {
		@include less-than(md) {
			margin: 5px 0;
		}

		a {
			margin: 0 24px;
			font-size: 16px;
			line-height: 24px;
			color: #ffecd0;

			&:hover {
				color: $orange;
			}
		}
	}
}

.footer__copy {
	display: flex;
	justify-content: center;
	font-size: 16px;
	line-height: 24px;
	color: #6a7084;
	margin-top: 16px;
}
</style>
