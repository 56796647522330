<template>
    <div>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <app-loader v-show="pageLoading"></app-loader>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->

                        <info-box class="d-flex mb-16 text-center justify-content-center" v-if="klarnaFailed">
                            <div>
                                <router-link :to="{ name: 'LaterPayment' }">{{ $t('paymentOptions.changePayment') }}</router-link>
                            </div>
                        </info-box>
                        <head-title class="mb-8">
                            <span class="d-flex align-items-center">
                                {{ $t('klarna.headline') }}
                                <!-- <img :src="require(`@/assets/images/icons/paypal.svg`)" class="d-inline-flex align-items-center ml-8" width="85" /> -->
                            </span>
                        </head-title>
                        <!-- <info-box class="mt-16 mb-48 align-items-start">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div>
                                {{ $t('onlinePayment.info') }}
                            </div>
                        </info-box> -->

                        <div id="klarna_container"></div>

                        <form-button @click.prevent="authorize()" class="mt-48">
                            {{ $t('klarna.button_pay') }}
                        </form-button>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <app-sidebar></app-sidebar>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';
//import AppBreadcrumb from "@/components/base/AppBreadcrumb.vue"
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import InfoBox from '@/components/InfoBox.vue';
import AppLoader from '@/components/base/AppLoader.vue';
import KlarnaService from '@/utilities/klarna.js';
import FormButton from '@/components/form/FormButton.vue';

export default {
    name: 'Paypal',
    props: ['new'],
    components: {
        //AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        InfoBox,
        AppLoader,
        FormButton,
        AppLoader,
    },
    data() {
        return {
            paymentMethodId: null,
            klarna: null,
            pageLoading: false,
        };
    },
    async mounted() {
        this.setCurrentStep(5);
        this.setCurrentPath('Klarna');

        let calculatePrices = await this.calculateTotalPrice();

        // if (this.new == '1') {
        //     delete window.klarnaAsyncCallback;
        //     delete window.klarna;
        // }

        this.klarna = new KlarnaService({ order: this.getOrderForKlarna(true), orderId: this.orderId });

        if (this.klarna.isScriptAlreadyInDOM() && this.new == '1') {
            this.pageLoading = true;
            location.reload();
        }

        let initKlarna;

        if (window.klarna) {
            initKlarna = await this.klarna.createSession(true);
        } else {
            initKlarna = await this.klarna.createSession();
        }

        await this.klarna.initKlarnaSDK(this.order.order.paymentMethod.replace(/klarna_/g, ''));
    },
    computed: {
        ...mapState(['orderId', 'klarnaFailed']),
        ...mapState('order', ['order']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        authorize() {
            this.klarna.authorize(this.order.order.paymentMethod.replace(/klarna_/g, ''), {
                authFailed: this.$t('klarna.authFailed'),
                orderFailed: this.$t('klarna.orderFailed'),
                success: this.$t('klarna.success'),
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
