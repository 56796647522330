<template>
    <div>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-10 mx-auto text-center">
                        <head-title>
                            {{ $t('thankyouNew.headline') }}
                            <br />
                            <strong v-html="$t('thankyouNew.subHeadline')"></strong>
                        </head-title>
                    </div>

                    <div class="col-12 col-md-8 mx-auto mt-48 mt-md-64">
                        <div class="number-items">
                            <div class="number-item">
                                <div class="number-item__number">
                                    1
                                </div>
                                <div class="number-item__content">
                                    <strong class="number-item__content-title">{{ $t('thankyouNew.summary') }}</strong>
                                    <p>
                                        {{ $t('thankyouNew.summaryInfo', { email: customer.email ? customer.email : customer_email }) }}
                                    </p>
                                    <div class="warning --orange mt-16">
                                        {{ $t('thankyouNew.summaryAlert') }}
                                    </div>
                                </div>
                            </div>

                            <div class="number-item">
                                <div class="number-item__number">
                                    2
                                </div>
                                <div class="number-item__content">
                                    <strong class="number-item__content-title">{{ $t('thankyouNew.number') }}</strong>
                                    <p>
                                        {{ $t('thankyouNew.numberInfo') }}
                                    </p>
                                    <div class="warning --orange mt-16">
                                        {{ $t('thankyouNew.numberAlert', { order: orderId ? orderId : order_id }) }}
                                    </div>
                                </div>
                            </div>

                            <div class="number-item">
                                <div class="number-item__number">
                                    3
                                </div>
                                <div class="number-item__content">
                                    <strong class="number-item__content-title">{{ $t('thankyouNew.time') }}</strong>
                                    <p>
                                        {{ $t('thankyouNew.timeInfo', { delivery: $t(getDelivery('name')) }) }}
                                    </p>

                                    <div class="number-item__content-info mt-16" v-if="getDelivery('value') == 'digital'">
                                        <div class="number-item__content-info-icon">
                                            <img src="@/assets/images/icons/email-delivery.svg" alt="" />
                                        </div>
                                        {{ $t('delivery.digitalCopyByEmail') }}
                                        <strong class="ml-8">{{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime)) }}</strong>
                                    </div>

                                    <div
                                        class="number-item__content-info mt-16"
                                        v-if="getDelivery('value') == 'digital' || getDelivery('value') == 'fast'"
                                    >
                                        <div class="number-item__content-info-icon">
                                            <img src="@/assets/images/icons/ups.svg" alt="" />
                                        </div>
                                        {{ $t('delivery.physicalCOCUPS') }}
                                        <strong class="ml-8">{{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 1) }}</strong>
                                    </div>

                                    <div class="number-item__content-info mt-16" v-if="getDelivery('value') == 'economy'">
                                        <div class="number-item__content-info-icon">
                                            <img src="@/assets/images/icons/physical-delivery.svg" alt="" />
                                        </div>
                                        {{ $t('delivery.physicalCOC') }}
                                        <strong class="ml-8">
                                            {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 3) }}
                                            -
                                            {{ addBusinessDays($moment(), parseInt(selectedProduct.deliveryTime) + 7) }}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 pt-0 pt-md-48 text-center">
                        <!-- HEADLINE -->
                        <head-title class="mb-8 big-title">
                            <strong>{{ $t('thankyouNew.footerHeadline') }}</strong>
                        </head-title>

                        <span class="subtitle">
                            {{ $t('thankyouNew.footerSubHeadline') }}
                        </span>

                        <div class="d-flex justify-content-center">
                            <form-button class="mt-32 --secondary" @click.prevent="goToHomePage()">
                                {{ $t('thankyou.placeAnotherOrder') }}
                            </form-button>
                            <form-button class="ml-16 mt-32" v-if="customer.type != 'N'" @click.prevent="goToPrivateZone()">
                                {{ $t('thankyou.seeAllMyOrders') }}
                            </form-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';

export default {
    name: 'PaymentInfo',
    props: ['order_id', 'customer_email'],
    components: {
        HeadTitle,
        FormButton,
    },
    data() {
        return {};
    },
    mounted() {
        this.setCurrentStep(5);
        this.setCurrentPath('ThankYou');

        if (this.laterPayment) {
            this.baseGTM('Thank You');
        } else if (this.customer.type == 'S') {
            this.gtmThankYouCustomerS('Thank You');
        } else {
            this.gtmThankYou('Thank You');
        }

        // console.log(this.order_id)
        // console.log(this.customer)
    },
    created() {},
    computed: {
        ...mapState(['orderId', 'selectedProduct']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        goToHomePage() {
            if (this.customer.registerId) {
                window.location.href = `https://my.eurococ.eu/${this.$i18n.locale}/myzone/new-order?registerId=${this.customer.registerId}`;
            } else {
                window.location.href = process.env.VUE_APP_BASE_URL + this.$i18n.locale;
            }
        },
        goToPrivateZone() {
            window.location.href = process.env.VUE_APP_PRIVATE_ZONE;
        },
        getDelivery(param = null) {
            if (this.order.order.deliveryMethod) {
                let delivery = this.order.deliveryOptions.filter(delivery => {
                    if (delivery.value == this.order.order.deliveryMethod) {
                        return delivery;
                    }
                })[0];

                if (param) {
                    return delivery[param];
                } else {
                    return delivery;
                }
            } else {
                return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

strong {
    &::v-deep {
        .text-orange {
            color: $orange;
            margin: 0 5px;
        }
    }
}

.text-orange {
    color: $orange;
}

.subtitle {
    font-size: 20px;
    line-height: 30px;
    color: $darkGray;
    font-weight: bold;

    i {
        font-style: normal;
        color: $orange;
    }
}

p {
    font-size: 16px;
    line-height: 24px;
}

.number-item {
    display: flex;
}

.number-item__content {
    flex-grow: 1;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $ultraLightGray;

    p {
        @include opacity(0.7);
    }
}

.number-item__content-title {
    font-size: 18px;
}

.number-item__number {
    width: 70px;
    min-width: 70px;
    height: 70px;
    border: 0.1rem solid $orange;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $orange;
    margin-right: 24px;
    @include border-radius(50%);

    @include less-than(md) {
        width: 50px;
        min-width: 50px;
        height: 50px;
    }
}

.number-item__content-info {
    font-size: 14px;
    line-height: 18px;
    color: $orange;
    display: flex;
    align-items: center;
}

.number-item__content-info-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.big-title {
    font-size: 30px;
}
</style>
