// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/info.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/icons/tooltip-pin.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".form-label__info-wrapper[data-v-4dc9cb4c]{display:inline-block;position:relative}@media(max-width:1023.9px){.form-label__info-wrapper[data-v-4dc9cb4c]{margin-left:auto;margin-right:10px}}.form-label__info[data-v-4dc9cb4c]{width:16px;height:16px;display:block;cursor:pointer;margin-left:8px;background:#b9bbc0;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;transition:all .6s cubic-bezier(.22,1,.36,1)}.form-label__info[data-v-4dc9cb4c]:hover{background:#091647}.form-label__tooltip[data-v-4dc9cb4c]{position:absolute;z-index:100;background:#fffbf5;width:300px;padding:16px;border:1px solid #ffa41b;font-family:Open Sans,Helvetica,Arial;font-size:12px;line-height:16px;color:#091647;left:50%;bottom:35px;transform:translate(-50%);border-radius:4px;box-shadow:2px 4px 6px 0 rgba(38,37,35,.1);filter:alpha(opacity=0);-moz-opacity:0;-khtml-opacity:0;opacity:0;pointer-events:none;bottom:20px;transition:all .6s cubic-bezier(.22,1,.36,1)}@media(max-width:1023.9px){.form-label__tooltip[data-v-4dc9cb4c]{left:auto;right:-20px;transform:translate(0)}}.form-label__tooltip.--visible[data-v-4dc9cb4c]{filter:alpha(opacity=1);-moz-opacity:1;-khtml-opacity:1;opacity:1;pointer-events:auto;bottom:35px}.form-label__tooltip[data-v-4dc9cb4c]:after{content:\"\";width:31px;height:15px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;position:absolute;bottom:-14px;left:50%;margin-left:4px;transform:translateX(-50%)}@media(max-width:1023.9px){.form-label__tooltip[data-v-4dc9cb4c]:after{left:auto;right:12px;transform:translateX(0)}}.form-label__tooltip[data-v-4dc9cb4c]:before{content:\"\";width:100%;height:25px;background:transparent;position:absolute;bottom:-25px;left:50%;transform:translateX(-50%)}", ""]);
// Exports
module.exports = exports;
