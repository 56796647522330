<template>
    <div>
        <app-breadcrumb></app-breadcrumb>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <a href="#" @click.prevent="$router.go(-1)" class="back-link">← {{ $t('base.stepBack') }}</a>
                        <head-title class="mb-16">
                            {{ $t('delivery.headline') }}
                        </head-title>

                        <DeliveryInfoBox />

                        <DeliveryOptionsC @selected="setDeliveryOption($event)" />

                        <div class="only-mobile">
                            <app-sidebar></app-sidebar>
                        </div>

                        <!-- BTN -->
                        <div class="d-flex justify-content-end mt-48" v-if="customer.type == 'S'">
                            <form-button @click.prevent="changeDeliveryMethod()">
                                {{ $t('customer.continueToVehicle') }}
                            </form-button>
                        </div>
                        <div class="d-flex justify-content-end mt-48" v-else>
                            <form-button @click.prevent="changeDeliveryMethod()">
                                {{ $t('delivery.continueToPayment') }}
                            </form-button>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <div class="only-desktop">
                                <app-sidebar></app-sidebar>
                            </div>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppBreadcrumb from '@/components/base/AppBreadcrumb.vue';
import AppSidebar from '@/components/base/AppSidebar.vue';
import AppMarketLeader from '@/components/base/AppMarketLeader.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';
import DeliveryOptionsC from '@/components/delivery/DeliveryOptionsC';
import DeliveryInfoBox from '@/components/delivery/DeliveryInfoBox';

export default {
    name: 'DeliveryOptions',
    components: {
        AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        FormButton,
        DeliveryOptionsC,
        DeliveryInfoBox,
    },
    data() {
        return {
            deliveryData: '',
            isDigitalOrFast: false,
        };
    },
    mounted() {
        this.setCurrentStep(2);
        this.setCurrentPath('DeliveryOptions');
        this.gtmEventNextPage('Delivery Options', 2);
    },
    created() {},
    watch: {},
    computed: {
        ...mapState(['appLocale', 'currentStep']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        ...mapMutations('order', ['setDeliveryMethod']),
        setDeliveryOption(e) {
            this.deliveryData = e;
        },
        changeDeliveryMethod() {
            this.setDeliveryMethod(this.deliveryData);

            //gtm
            this.gtmDeliveryPaymentMethod(2, this.deliveryData);

            if (this.deliveryData) {
                if (this.customer.type == 'S') {
                    this.$router.push({ name: 'VehicleInfo' });
                } else {
                    this.$router.push({ name: 'PaymentOptions' });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

.read-more {
    color: $blue;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;

    &:hover {
        color: $orange;
    }
}
</style>
