<template>
    <div>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 ml-auto pt-0 pt-md-32">
                        <!-- HEADLINE -->
                        <head-title class="mb-8">
                            <span class="d-flex align-items-center">
                                {{ $t("onlinePayment.headline") }}
                                <img :src="require(`@/assets/images/icons/paypal.svg`)" class="d-inline-flex align-items-center ml-8" width="85" />
                            </span>
                        </head-title>
                        <info-box class="mt-16 mb-32 align-items-start">
                            <img src="@/assets/images/icons/info-orange.svg" />
                            <div>
                                {{ $t("onlinePayment.info") }}
                            </div>
                        </info-box>

                        <div class="mt-16">
                            <div class="btns">
                                <div ref="paypal"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-5">
                        <div class="sidebar">
                            <app-sidebar></app-sidebar>
                            <app-market-leader></app-market-leader>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <app-loader v-show="loading" :light="true"></app-loader>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"
//import AppBreadcrumb from "@/components/base/AppBreadcrumb.vue"
import AppSidebar from "@/components/base/AppSidebar.vue"
import AppMarketLeader from "@/components/base/AppMarketLeader.vue"
import HeadTitle from "@/components/headings/HeadTitle.vue"
import InfoBox from "@/components/InfoBox.vue"
import AppLoader from "@/components/base/AppLoader.vue"

export default {
    name: "Paypal",
    components: {
        //AppBreadcrumb,
        AppSidebar,
        AppMarketLeader,
        HeadTitle,
        InfoBox,
        AppLoader
    },
    data() {
        return {
            loaded: false,
            loading: false,
            isLeaving: true
        }
    },
    mounted() {
        this.setCurrentStep(5)
        this.setCurrentPath("PayPal")

        this.gtmEventPayment("PayPal")

        const script = document.createElement("script")
        script.src = "https://www.paypal.com/sdk/js?client-id=" + process.env.VUE_APP_PAYPAL_CLIENTID + "&currency=EUR&disable-funding=credit,card"
        script.addEventListener("load", this.setLoaded)
        document.body.appendChild(script)
    },
    created() {
        // let self = this
        // window.onbeforeunload = function() {
        //     if (self.isLeaving) {
        //         return self.$t("error.confirmAlertBeforeLeaveSite")
        //     }
        // }
    },
    computed: {
        ...mapState(["orderId"]),
        ...mapState("order", ["order"]),
        ...mapState("customer", ["customer"])
    },
    methods: {
        ...mapMutations(["setCurrentStep", "setCurrentPath"]),
        setLoaded() {
            let self = this

            window.paypal
                .Buttons({
                    createOrder: (data, actions) => {
                        self.loading = true
                        //self.isLeaving = false
                        return actions.order.create({
                            purchase_units: [
                                {
                                    //invoice_number: this.orderId,
                                    description: self.orderId,
                                    amount: {
                                        value: self.order.prices.price
                                    }
                                }
                            ]
                            // redirect_urls: {
                            //     return_url: "https://beta.eurococ.eu/en/checkout/thank-you",
                            //     cancel_url: "https://beta.eurococ.eu/en/checkout/paypal"
                            // }
                        })
                    },
                    onApprove: function(data, actions) {
                        // This function captures the funds from the transaction.
                        return actions.order.capture().then(details => {
                            // This function shows a transaction success message to your buyer.
                            console.log(details)
                            self.$router.push({ name: "ThankYou" })
                            self.loading = false
                            //alert("Transaction completed by " + details.payer.name.given_name)
                        })
                    },
                    onCancel: function() {
                        //self.isLeaving = true
                        self.loading = false
                    },
                    onError: function() {
                        self.loading = false
                        self.$router.push({ name: "PaymentDeclined" })
                    }
                })
                .render(this.$refs.paypal)
        }
    }
}
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-orange {
    color: $orange;
}

.order {
    font-size: 18px;
    line-height: 27px;
    color: $darkGray;

    i {
        font-style: normal;
        color: $orange;
    }
}

.btns {
    max-width: 300px;
}

p {
    font-size: 16px;
    line-height: 24px;
}

.loading {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
}
</style>
