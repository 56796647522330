<template>
    <form action="" method="post" @submit.prevent="">
        <div v-if="!inmodal">
            <!-- EMAIL -->
            <form-group :loading="loading" class="mb-0" :class="{ '--invalid': issetObj('email', errors.base) }">
                <form-label>
                    {{ $t('customer.email') }}
                </form-label>
                <input
                    type="email"
                    name="email"
                    ref="email"
                    class="form-input"
                    v-model="currentCustomer.email"
                    @input="searchForUser()"
                    :class="{ '--invalid': errors.email }"
                    autocomplete="email"
                />
                <form-error-message :errors="errors.base" field="email"></form-error-message>
            </form-group>

            <!-- NEWSLETTER -->
            <form-group class="mt-12">
                <label class="form-checkbox">
                    <input type="checkbox" name="newsletter" value="1" class="form-checkbox__input" v-model="currentCustomer.sendNotify" />
                    <span class="form-checkbox__checkmark"></span>
                    <span class="form-checkbox__label" v-html="$t('vehicle.agreeWithPersonalData')"></span>
                </label>
            </form-group>
        </div>

        <div class="only-mobile" v-if="!inmodal && !showCustomerForm">
            <app-sidebar></app-sidebar>
        </div>

        <!-- CUSTOMER INFO FORM -->
        <div v-if="showCustomerForm || inmodal">
            <head-title class="mt-64 mt-md-96 next-section" v-if="!inmodal">
                {{ $t('customer.invoicing') }}
            </head-title>
            <info-box class="mt-16 mb-0 info-box__signin" v-show="currentCustomer.registred && !showLoginForm && currentCustomer.registerId == ''">
                <a href="#" @click.prevent="showLoginForm = true">{{ $t('customer.login') }}</a>
                {{ $t('customer.saveTime') }}
            </info-box>

            <div class="mt-16 login-form" v-show="showLoginForm">
                <div class="login-form__headline">
                    {{ $t('customer.loginHeadline') }}
                </div>
                <div class="login-form__close" @click.prevent="showLoginForm = false"></div>
                <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('userData', errors) }">
                    <form-label>
                        {{ $t('customer.passwordLogin') }}
                    </form-label>
                    <input type="password" class="form-input" v-model="loginpass" @keyup.enter="loginUser()" />
                    <form-error-message :errors="errors" field="userData"></form-error-message>
                </form-group>
                <div class="d-flex align-items-center">
                    <a :href="`https://my.eurococ.eu/${$i18n.locale}/forgotten-password`" class="mt-8 login-form__link">
                        {{ $t('base.forgotPassword') }}
                    </a>
                    <form-button class="ml-auto mt-16 --secondary" @click="loginUser()" :loading="sendingForm">
                        {{ $t('base.loginBtn') }}
                    </form-button>
                </div>
            </div>

            <!-- PURCHASER -->
            <form-group :class="{ 'mt-32': !inmodal, 'mt-0': inmodal, '--invalid': issetObj('personType', errors.base) }" class="--invoice">
                <form-label>
                    {{ $t('customer.purchaseAs') }}
                </form-label>
                <div class="form-radios">
                    <label class="form-tabradio --w165">
                        <input
                            type="radio"
                            :name="inmodal ? 'type-modal' : 'type'"
                            value="individual"
                            v-model="currentCustomer.personType"
                            class="form-tabradio__input"
                        />
                        <span class="form-tabradio__checkmark"></span>
                        <span class="form-tabradio__label">
                            {{ $t('customer.private') }}
                        </span>
                    </label>
                    <label class="form-tabradio --w165">
                        <input
                            type="radio"
                            :name="inmodal ? 'type-modal' : 'type'"
                            value="company"
                            v-model="currentCustomer.personType"
                            class="form-tabradio__input"
                        />
                        <span class="form-tabradio__checkmark"></span>
                        <span class="form-tabradio__label">
                            {{ $t('customer.company') }}
                        </span>
                    </label>
                </div>
                <form-error-message align="left" :errors="errors.base" field="personType"></form-error-message>
            </form-group>

            <!-- SALUTATION -->
            <form-group class="mt-0" :class="{ '--invalid': issetObj('title', errors.base) }">
                <form-label>
                    {{ $t('customer.salutation') }}
                </form-label>
                <div class="form-radios">
                    <label class="form-tabradio --w122">
                        <input
                            type="radio"
                            :name="inmodal ? 'salutation-modal' : 'salutation'"
                            value="1"
                            v-model="currentCustomer.title"
                            class="form-tabradio__input"
                        />
                        <span class="form-tabradio__checkmark"></span>
                        <span class="form-tabradio__label">
                            {{ $t('customer.mr') }}
                        </span>
                    </label>
                    <label class="form-tabradio --w122">
                        <input
                            type="radio"
                            :name="inmodal ? 'salutation-modal' : 'salutation'"
                            value="2"
                            v-model="currentCustomer.title"
                            class="form-tabradio__input"
                        />
                        <span class="form-tabradio__checkmark"></span>
                        <span class="form-tabradio__label">
                            {{ $t('customer.mrs') }}
                        </span>
                    </label>
                </div>
                <form-error-message align="left" :errors="errors.base" field="title"></form-error-message>
            </form-group>

            <!-- FIRSTNAME -->
            <form-group :class="{ '--invalid': issetObj('name', errors.base) }">
                <form-label>
                    {{ $t('customer.firstname') }}
                </form-label>
                <input
                    type="text"
                    class="form-input"
                    name="firstname"
                    v-model="currentCustomer.name"
                    @update:modelValue="currentCustomer.name = capitalizeString($event)"
                />
                <form-error-message :errors="errors.base" field="name"></form-error-message>
            </form-group>

            <!-- LASTNAME -->
            <form-group :class="{ '--invalid': issetObj('surname', errors.base) }">
                <form-label>
                    {{ $t('customer.lastname') }}
                </form-label>
                <input
                    type="text"
                    class="form-input --capitalize"
                    name="lastname"
                    v-model="currentCustomer.surname"
                    @update:modelValue="currentCustomer.surname = capitalizeString($event)"
                />
                <form-error-message :errors="errors.base" field="surname"></form-error-message>
            </form-group>

            <!-- COMPANY -->
            <form-group v-show="currentCustomer.personType == 'company'" :class="{ '--invalid': issetObj('companyName', errors.invoice) }">
                <form-label>
                    {{ $t('customer.companyName') }}
                </form-label>
                <input type="text" class="form-input" name="company" v-model="currentCustomer.invoiceData.companyName" />
                <form-error-message :errors="errors.invoice" field="companyName"></form-error-message>
            </form-group>

            <!-- STREET -->
            <form-group class="mb-0" :class="{ '--invalid': issetObj('street', errors.invoice) }">
                <form-label>
                    {{ $t('customer.streetHouseNo') }}
                </form-label>
                <input
                    type="text"
                    class="form-input"
                    name="street"
                    v-model="currentCustomer.invoiceData.street"
                    @update:modelValue="currentCustomer.invoiceData.street = capitalizeString($event)"
                    :maxlength="maxDeliveryNote"
                />
                <form-error-message :errors="errors.invoice" field="street"></form-error-message>
                <div class="d-flex text-small-label justify-content-end">{{ getInvoiceStreetLength() }}/{{ maxDeliveryNote }}</div>
            </form-group>

            <!-- NO HOUSE NUMBERS -->
            <form-group class="mt-12">
                <label class="form-checkbox">
                    <input
                        type="checkbox"
                        name="name"
                        value="1"
                        class="form-checkbox__input"
                        v-model="currentCustomer.invoiceData.streetNumberNone"
                    />
                    <span class="form-checkbox__checkmark"></span>
                    <span class="form-checkbox__label">
                        {{ $t('customer.noHouseNumbers') }}
                    </span>
                </label>
            </form-group>
            <info-box class="mb-16" v-show="currentCustomer.invoiceData.streetNumberNone || getInvoiceStreetLength() >= 30">
                {{ $t('customer.noHouseNumbersInfo') }}
            </info-box>

            <!-- ZIP/CITY -->
            <div class="row">
                <div class="col-12 col-md-4">
                    <form-group class="mt-0 mb-24 mb-md-0" :class="{ '--invalid': issetObj('postalCode', errors.invoice) }">
                        <form-label>
                            {{ $t('customer.zip') }}
                        </form-label>
                        <input type="text" name="zip" class="form-input" v-model="currentCustomer.invoiceData.postalCode" />
                        <form-error-message :errors="errors.invoice" field="postalCode"></form-error-message>
                    </form-group>
                </div>
                <div class="col-12 col-md-8">
                    <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('city', errors.invoice) }">
                        <form-label>
                            {{ $t('customer.city') }}
                        </form-label>
                        <input
                            type="text"
                            name="city"
                            class="form-input"
                            v-model="currentCustomer.invoiceData.city"
                            @update:modelValue="currentCustomer.invoiceData.city = capitalizeString($event)"
                        />
                        <form-error-message :errors="errors.invoice" field="city"></form-error-message>
                    </form-group>
                </div>
            </div>

            <!-- COUNTRY -->
            <form-group :class="{ '--invalid': issetObj('country', errors.invoice) }">
                <form-label>
                    {{ $t('customer.country') }}
                </form-label>
                <!-- <select class="form-select" name="country" v-model="currentCustomer.invoiceData.country">
                    <option value="0" disabled>{{ $t("customer.selectCountry") }}</option>
                    <option v-for="(country, index) in enums.countries" :key="index" :value="country.id">
                        {{ country.name }}
                    </option>
                </select> -->
                <classic-select-box
                    name="country"
                    @selected="updateInvoiceCountry"
                    :options="enums.countries"
                    :allow-search="true"
                    label-param="name"
                    value-param="id"
                    :preselectedOption="preselectedInvoiceCountry"
                />
                <form-error-message :errors="errors.invoice" field="country"></form-error-message>
            </form-group>

            <!-- SAME DELIVERY -->
            <form-group>
                <label class="form-checkbox">
                    <input type="checkbox" name="sameDelivery" value="1" class="form-checkbox__input" v-model="currentCustomer.deliveryIsSame" />
                    <span class="form-checkbox__checkmark"></span>
                    <span class="form-checkbox__label">
                        {{ $t('customer.sameDeliveryAddress') }}
                    </span>
                </label>
            </form-group>

            <!-- PHONE -->
            <div class="row">
                <div class="col-12">
                    <form-label>
                        {{ $t('customer.phone') }}
                    </form-label>
                </div>
                <div class="col-4 col-md-3">
                    <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('cellPhonePrefix', errors.base) }">
                        <!-- <select class="form-select --small" name="cellPhonePrefix" v-model="currentCustomer.cellPhonePrefix">
                        <option value="" disabled>{{ $t("base.select") }}</option>
                        <option v-for="(country, index) in enums.cellPhonePrefixes" :key="index" :value="country.prefix"> {{ country.prefix }} {{ country.country }} </option>
                    </select> -->
                        <classic-select-box
                            name="cellPhonePrefix"
                            @selected="updatePhonePrefix"
                            :options="enums.cellPhonePrefixes"
                            :allow-search="true"
                            :preselectedOption="preselectedPhonePrefix"
                            active-text="suffix"
                        ></classic-select-box>
                        <form-error-message :errors="errors.base" field="cellPhonePrefix"></form-error-message>
                    </form-group>
                </div>
                <div class="col-8 col-md-9">
                    <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('cellPhoneNumber', errors.base) }">
                        <input type="text" name="cellPhoneNumber" v-model="currentCustomer.cellPhoneNumber" class="form-input --big" />
                        <form-error-message :errors="errors.base" field="cellPhoneNumber"></form-error-message>
                    </form-group>
                </div>
            </div>

            <!-- VAT -->
            <form-group v-show="currentCustomer.personType == 'company' && !isOutsideEU" :class="{ '--invalid': issetObj('vatId', errors.invoice) }">
                <form-label>
                    {{ $t('customer.vat') }}
                    <span class="form-label__opt">{{ $t('base.optional') }}</span>
                </form-label>

                <!-- <div class="d-flex mb-8 text-small-label">{{ $t("customer.prefixVat") }}</div> -->
                <input type="text" name="vat" v-model="currentCustomer.invoiceData.vatId" class="form-input --vat" />
                <span class="vat-prefix">
                    <template v-if="selectedInvoiceCountry.vatCode">{{ selectedInvoiceCountry.vatCode }} -</template>
                    <template v-else>
                        -- -
                    </template>
                </span>
                <form-error-message :errors="errors.invoice" field="vatId"></form-error-message>
            </form-group>

            <!-- SIREN/SIRET -->
            <form-group v-show="currentCustomer.personType == 'company' && isOutsideEU" :class="{ '--invalid': issetObj('vatId', errors.invoice) }">
                <form-label>
                    {{ $t('customer.sirenSiret') }}
                </form-label>
                <!-- <div class="d-flex mb-8 text-small-label">{{ $t("customer.prefixVat") }}</div> -->
                <input type="text" name="vat" v-model="currentCustomer.invoiceData.companyId" class="form-input" />
                <form-error-message :errors="errors.invoice" field="companyId"></form-error-message>
            </form-group>

            <!-- DELIVERY STREET -->
            <div v-show="!currentCustomer.deliveryIsSame">
                <head-title class="mt-64 --delivery">
                    {{ $t('customer.deliveryAddress') }}
                </head-title>

                <form-group class="mb-0" :class="{ '--invalid': issetObj('companyName', errors.delivery) }">
                    <form-label>
                        {{ $t('customer.companyName') }}
                        <span class="form-label__opt" v-show="currentCustomer.personType == 'individual'">{{ $t('base.optional') }}</span>
                        <form-label-info>
                            <div class="tooltip-content">
                                {{ $t('tooltips.deliveryName') }}
                            </div>
                        </form-label-info>
                    </form-label>
                    <input type="text" class="form-input" name="del_company" v-model="currentCustomer.deliveryAddress.companyName" />
                    <form-error-message :errors="errors.delivery" field="companyName"></form-error-message>
                </form-group>

                <form-group class="mb-0" :class="{ '--invalid': issetObj('name', errors.delivery) }">
                    <form-label>
                        {{ $t('customer.firstname') }}
                        <form-label-info>
                            <div class="tooltip-content">
                                {{ $t('tooltips.deliveryName') }}
                            </div>
                        </form-label-info>
                    </form-label>
                    <input
                        type="text"
                        class="form-input"
                        name="del_name"
                        v-model="currentCustomer.deliveryAddress.name"
                        @update:modelValue="currentCustomer.deliveryAddress.name = capitalizeString($event)"
                    />
                    <form-error-message :errors="errors.delivery" field="name"></form-error-message>
                </form-group>

                <form-group class="mb-0" :class="{ '--invalid': issetObj('surname', errors.delivery) }">
                    <form-label>
                        {{ $t('customer.lastname') }}
                        <form-label-info>
                            <div class="tooltip-content">
                                {{ $t('tooltips.deliveryName') }}
                            </div>
                        </form-label-info>
                    </form-label>
                    <input
                        type="text"
                        class="form-input"
                        name="del_surname"
                        v-model="currentCustomer.deliveryAddress.surname"
                        @update:modelValue="currentCustomer.deliveryAddress.surname = capitalizeString($event)"
                    />
                    <form-error-message :errors="errors.delivery" field="surname"></form-error-message>
                </form-group>

                <form-group class="mb-0" :class="{ '--invalid': issetObj('street', errors.delivery) }">
                    <form-label>
                        {{ $t('customer.streetHouseNo') }}
                    </form-label>
                    <input
                        type="text"
                        class="form-input"
                        name="street"
                        v-model="currentCustomer.deliveryAddress.street"
                        @update:modelValue="currentCustomer.deliveryAddress.street = capitalizeString($event)"
                        :maxlength="maxDeliveryNote"
                    />
                    <form-error-message :errors="errors.delivery" field="street"></form-error-message>
                    <div class="d-flex text-small-label justify-content-end">{{ getDeliveryStreetLength() }}/{{ maxDeliveryNote }}</div>
                </form-group>

                <!-- DELIVERY NO HOUSE NUMBERS -->
                <form-group class="mt-12">
                    <label class="form-checkbox">
                        <input
                            type="checkbox"
                            name="delivery_no_house_numbers"
                            value="1"
                            class="form-checkbox__input"
                            v-model="currentCustomer.deliveryAddress.streetNumberNone"
                        />
                        <span class="form-checkbox__checkmark"></span>
                        <span class="form-checkbox__label">
                            {{ $t('customer.noHouseNumbers') }}
                        </span>
                    </label>
                </form-group>
                <info-box class="mb-16" v-show="currentCustomer.deliveryAddress.streetNumberNone || getDeliveryStreetLength() >= 30">
                    {{ $t('customer.noHouseNumbersInfo') }}
                </info-box>

                <!-- DELIVERY ZIP/CITY -->
                <div class="row">
                    <div class="col-12 col-md-4">
                        <form-group class="mt-0 mb-24 mb-md-0" :class="{ '--invalid': issetObj('postalCode', errors.delivery) }">
                            <form-label>
                                {{ $t('customer.zip') }}
                            </form-label>
                            <input type="text" name="zip" class="form-input" v-model="currentCustomer.deliveryAddress.postalCode" />
                            <form-error-message :errors="errors.delivery" field="postalCode"></form-error-message>
                        </form-group>
                    </div>
                    <div class="col-12 col-md-8">
                        <form-group class="mt-0 mb-0" :class="{ '--invalid': issetObj('city', errors.delivery) }">
                            <form-label>
                                {{ $t('customer.city') }}
                            </form-label>
                            <input
                                type="text"
                                name="city"
                                class="form-input"
                                v-model="currentCustomer.deliveryAddress.city"
                                @update:modelValue="currentCustomer.deliveryAddress.city = capitalizeString($event)"
                            />
                            <form-error-message :errors="errors.delivery" field="city"></form-error-message>
                        </form-group>
                    </div>
                </div>

                <!-- DELIVERY COUNTRY -->
                <form-group :class="{ '--invalid': issetObj('country', errors.delivery) }">
                    <form-label>
                        {{ $t('customer.country') }}
                    </form-label>
                    <!-- <select class="form-select" name="country" v-model="currentCustomer.deliveryAddress.country">
                        <option value="0" disabled>{{ $t("customer.selectCountry") }}</option>
                        <option v-for="(country, index) in enums.countries" :key="index" :value="country.id">
                            {{ country.name }}
                        </option>
                    </select> -->
                    <classic-select-box
                        name="country"
                        @selected="updateDeliveryCountry"
                        :options="enums.countries"
                        :allow-search="true"
                        label-param="name"
                        value-param="id"
                        :preselectedOption="preselectedDeliveryCountry"
                    />
                    <form-error-message :errors="errors.delivery" field="country"></form-error-message>
                </form-group>
            </div>

            <!-- ADDRESS NOTE -->
            <form-group class="mb-4">
                <form-label>
                    {{ $t('customer.addressNote') }}
                    <span class="form-label__opt">{{ $t('base.optional') }}</span>
                    <form-label-info>
                        <div class="tooltip-content">
                            {{ $t('tooltips.addressNote') }}
                        </div>
                    </form-label-info>
                </form-label>
                <input type="text" name="addressNote" v-model="addressNote" class="form-input" :maxlength="maxDeliveryNote" />
            </form-group>
            <div class="d-flex text-small-label justify-content-end">{{ getAddressNoteLength() }}/{{ maxDeliveryNote }}</div>

            <!-- CREATE ACCOUNT -->
            <form-group v-show="!currentCustomer.registred" v-if="!inmodal">
                <label class="form-checkbox">
                    <input type="checkbox" name="createAccount" value="1" class="form-checkbox__input" v-model="currentCustomer.createAccount" />
                    <span class="form-checkbox__checkmark"></span>
                    <span class="form-checkbox__label">
                        {{ $t('customer.createAccount') }}
                    </span>
                </label>
            </form-group>

            <!-- PASSWORD -->
            <form-group
                v-show="!currentCustomer.registred && currentCustomer.createAccount"
                v-if="!inmodal"
                :class="{ '--invalid': issetObj('password', errors.base) }"
            >
                <form-label>
                    {{ $t('customer.password') }}
                </form-label>
                <input type="password" name="password" v-model="password" class="form-input" />
                <form-error-message :errors="errors.base" field="password"></form-error-message>
            </form-group>

            <!-- BTN -->
            <div class="only-mobile">
                <app-sidebar></app-sidebar>
            </div>

            <div class="d-flex justify-content-end mt-48">
                <!-- <router-link :to="{ name: 'DeliveryOptions' }"> -->
                <form-button @click="goToNextStep()" :loading="sendingForm" v-if="!inmodal">
                    <template v-if="currentCustomer.freeDelivery && currentCustomer.type == 'S'">
                        {{ $t('customer.continueToVehicle') }}
                    </template>
                    <template v-else-if="currentCustomer.freeDelivery">
                        {{ $t('customer.continueToPayment') }}
                    </template>
                    <template v-else-if="currentCustomer.type == 'S'">
                        {{ $t('customer.continueToDelivery') }}
                    </template>
                    <template v-else>
                        {{ $t('customer.continueToDeliveryPayment') }}
                    </template>
                </form-button>

                <form-button @click="saveDataFromModal()" :loading="sendingForm" v-if="inmodal">
                    {{ $t('customer.changeAddress') }}
                </form-button>
                <!-- </router-link> -->
            </div>
        </div>
    </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import AppSidebar from '@/components/base/AppSidebar.vue';
import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormGroup from '@/components/form/FormGroup.vue';
import FormLabel from '@/components/form/FormLabel.vue';
import FormLabelInfo from '@/components/form/FormLabelInfo.vue';
import FormButton from '@/components/form/FormButton.vue';
import FormErrorMessage from '@/components/form/FormErrorMessage.vue';
import InfoBox from '@/components/InfoBox.vue';
import ClassicSelectBox from '@/components/select/ClassicSelectBox';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    name: 'CustomerForm',
    props: ['inmodal'],
    components: {
        HeadTitle,
        FormGroup,
        FormLabel,
        FormLabelInfo,
        FormButton,
        FormErrorMessage,
        InfoBox,
        ClassicSelectBox,
        AppSidebar,
    },
    data() {
        const currentCustomer = this.copyObj(this.$store.state.customer.customer);

        return {
            timer: null,
            emailRegExp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            loading: false,
            showCustomerForm: false,
            maxDeliveryNote: 35,
            password: null,
            showLoginForm: false,
            loginpass: null,
            errors: {},
            sendingForm: false,
            currentCustomer,
            isOutsideEU: false,
            preselectedPhonePrefix: null,
            preselectedInvoiceCountry: null,
            preselectedDeliveryCountry: null,
            phonePrefix: '',
            phone: '',
            addressNote: '',
        };
    },
    mounted() {
        // setTimeout(() => {
        //     if (this.currentCustomer.invoiceData.country) {
        //         let country = this.enums.countries.filter(elem => {
        //             if (elem.id == this.currentCustomer.invoiceData.country) return elem;
        //         });
        //         if (country[0]) {
        //             this.preselectedInvoiceCountry = country[0];
        //         }
        //     }
        // }, 2000);
    },
    created() {
        if (this.currentCustomer.email != '') {
            this.showCustomerForm = true;
        }

        this.checkEuCountry(this.currentCustomer.invoiceData.country);

        if (this.currentCustomer.createAccount && this.currentCustomer.registerId != '') {
            this.currentCustomer.createAccount = false;
        }

        if (this.currentCustomer.cellPhonePrefix) {
            let prefix = this.enums.cellPhonePrefixes.filter(elem => {
                if (elem.value == this.currentCustomer.cellPhonePrefix) return elem;
            });

            if (prefix[0]) {
                this.preselectedPhonePrefix = prefix[0];
            }
        }

        if (this.currentCustomer.invoiceData.country) {
            let country = this.enums.countries.filter(elem => {
                if (elem.id == this.currentCustomer.invoiceData.country) return elem;
            });

            if (country[0]) {
                this.preselectedInvoiceCountry = country[0];
            }
        }

        if (this.currentCustomer.deliveryAddress.country) {
            let country = this.enums.countries.filter(elem => {
                if (elem.id == this.currentCustomer.deliveryAddress.country) return elem;
            });

            if (country[0]) {
                this.preselectedDeliveryCountry = country[0];
            }
        }

        if (this.order.order.addressNote) {
            this.addressNote = this.copyObj(this.order.order.addressNote);
        }
    },
    watch: {
        changeDeliveryAddress() {
            if (this.changeDeliveryAddress) {
                this.currentCustomer.deliveryIsSame = false;

                this.$nextTick(() => {
                    const el = document.querySelector('.title.--delivery');

                    if (el) {
                        el.scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        });
                    }

                    return;
                });
            } else {
                if (this.customer.deliveryIsSame) {
                    this.currentCustomer.deliveryIsSame = true;
                    this.$nextTick(() => {
                        const el = document.querySelector('.--invoice');

                        if (el) {
                            el.scrollIntoView({
                                block: 'center',
                                behavior: 'smooth',
                            });
                        }

                        return;
                    });
                }
            }
        },
        customer: {
            deep: true,

            //ak nacitalo customera z window.userId v App.vue, vlozim ho do currentCustomer
            handler() {
                this.currentCustomer = this.copyObj(this.$store.state.customer.customer);
                this.showCustomerForm = true;
            },
        },
        'currentCustomer.personType': function(val) {
            this.personTypeGTM(val);
        },
        'currentCustomer.invoiceData.country': function(val) {
            this.enums.countries.filter(country => {
                if (country.id == val) {
                    //setujem selectnutu krajinu kvoli prefixu
                    this.preselectedInvoiceCountry = country;
                }
            });

            this.checkEuCountry(val);

            //preselectnut predvolbu podla vybranej country
            let cntry = this.selectedInvoiceCountry.name;
            let prefix = this.enums.cellPhonePrefixes.filter(function(elem) {
                if (elem.text.includes(cntry)) return elem;
            });

            if (prefix[0]) {
                this.preselectedPhonePrefix = prefix[0];
            }

            if (this.preselectedPhonePrefix) {
                if (prefix[0]) {
                    this.currentCustomer.cellPhonePrefix = prefix[0].value;
                }
            }
        },
        'customer.deliveryAddress.country': function(val) {
            this.enums.countries.filter(country => {
                if (country.id == val) {
                    //setujem selectnutu krajinu kvoli prefixu
                    this.preselectedDeliveryCountry = country;
                    this.setSelectedDeliveryCountry(country);
                }
            });

            this.getDeliveryPrices();
        },
        'customer.email': function(val) {
            if (val) {
                this.currentCustomer.email = val;
            }
        },
    },
    computed: {
        ...mapState('customer', ['customer']),
        ...mapState('order', ['order']),
        ...mapState(['enums', 'selectedInvoiceCountry', 'selectedDeliveryCountry', 'changeDeliveryAddress', 'currentPath']),
        customerFirstName: {
            get() {
                return this.currentCustomer.name;
            },
            set(value) {
                this.currentCustomer.name = this.capitalizeString(value);
            },
        },
    },
    methods: {
        ...mapMutations('customer', ['setCustomer', 'setRegisterId', 'setCountry']),
        ...mapMutations('order', ['setAddressNote', 'setOrderType', 'setClientId', 'setDeliveryOptionsPrices', 'setDeliveryMethod']),
        ...mapMutations(['setShowAddressModal', 'setSelectedInvoiceCountry', 'setSelectedDeliveryCountry', 'setShowDeliveryModal']),
        updatePhonePrefix(val) {
            this.currentCustomer.cellPhonePrefix = val;
        },
        updateInvoiceCountry(val) {
            this.currentCustomer.invoiceData.country = val;
        },
        updateDeliveryCountry(val) {
            this.currentCustomer.deliveryAddress.country = val;
        },
        checkEuCountry(val) {
            this.enums.countries.filter(country => {
                if (country.id == val) {
                    //setujem selectnutu krajinu kvoli prefixu
                    this.setSelectedInvoiceCountry(country);

                    //zobrazujem VAT alebo SIREN/SIRET
                    if (country.eu != 1) {
                        this.isOutsideEU = true;
                    } else {
                        this.isOutsideEU = false;
                    }
                }
            });
        },
        goToNextStep() {
            //this.setCustomer(this.currentCustomer)
            //this.$router.push({ name: "DeliveryOptions" })

            this.sendingForm = true;

            //ak je rovnaka delivery address ako invoice address, prekopirujem hodnoty
            if (this.currentCustomer.deliveryIsSame) {
                this.currentCustomer.deliveryAddress.companyName = this.currentCustomer.invoiceData.companyName;
                this.currentCustomer.deliveryAddress.name = this.currentCustomer.name;
                this.currentCustomer.deliveryAddress.surname = this.currentCustomer.surname;
                this.currentCustomer.deliveryAddress.street = this.currentCustomer.invoiceData.street;
                this.currentCustomer.deliveryAddress.streetNumberNone = this.currentCustomer.invoiceData.streetNumberNone;
                this.currentCustomer.deliveryAddress.postalCode = this.currentCustomer.invoiceData.postalCode;
                this.currentCustomer.deliveryAddress.city = this.currentCustomer.invoiceData.city;
                this.currentCustomer.deliveryAddress.country = this.currentCustomer.invoiceData.country;
            }

            //zduplikujem cislo do delivery
            this.currentCustomer.deliveryAddress.cellPhoneNumber = this.currentCustomer.cellPhoneNumber;
            this.currentCustomer.deliveryAddress.cellPhonePrefix = this.currentCustomer.cellPhonePrefix;

            // if (this.currentCustomer.createAccount) {
            //     this.currentCustomer.password = this.password
            // }

            let validateData = {
                lang: this.$i18n.locale,
                data: this.copyObj(this.currentCustomer),
                fields: {},
            };

            validateData.data.password = this.password;

            //doplnim prefix do vatId
            if (!this.isOutsideEU && this.currentCustomer.personType == 'company') {
                let vatId = this.selectedInvoiceCountry.vatCode + this.currentCustomer.invoiceData.vatId;
                validateData.data.invoiceData.vatId = vatId;
            }

            //zmazem login heslo
            this.loginpass = null;

            //parsujem cislo kvoli backendu
            validateData.data.registred = parseInt(validateData.data.registred);

            //validujem user data
            this.$axios.post(process.env.VUE_APP_URL_USER_VALIDATE, validateData).then(response => {
                //console.log(response.data)

                if (response.status == '200') {
                    //ak nechce vytvorit ucet, pokracujem dalej
                    if (!this.currentCustomer.createAccount) {
                        //zmazem hesla
                        this.password = null;
                        this.loginpass = null;

                        //ulozim data
                        this.setCustomer(this.currentCustomer);
                        this.setAddressNote(this.addressNote);

                        //presmerujem na dalsi krok
                        if (this.currentCustomer.freeDelivery && this.currentCustomer.type == 'S') {
                            this.$router.push({ name: 'VehicleInfo' });
                        } else if (this.currentCustomer.freeDelivery) {
                            this.$router.push({ name: 'PaymentOptions' });
                        } else if (this.currentCustomer.type == 'S') {
                            this.$router.push({ name: 'DeliveryOptions' });
                        } else {
                            this.$router.push({ name: 'DeliveryOptions' });
                        }

                        this.sendingForm = false;
                    } else {
                        this.currentCustomer.registred = 1;
                        let data = this.copyObj(this.currentCustomer);
                        data.password = this.password;
                        //console.log(data)

                        //doplnim prefix do vatId
                        if (!this.isOutsideEU && this.currentCustomer.personType == 'company') {
                            let vatId = this.selectedInvoiceCountry.vatCode + this.currentCustomer.invoiceData.vatId;
                            data.invoiceData.vatId = vatId;
                        }

                        //registrujem usera, ak zaskrtol check a zadal heslo
                        this.$axios.post(process.env.VUE_APP_URL_USER_CREATE, data).then(response => {
                            //console.log(response.data)

                            //zmazem hesla
                            this.password = null;
                            this.loginpass = null;

                            if (response.status == '200') {
                                this.registerGTM();

                                //ulozim id
                                this.currentCustomer.registerId = response.data.id;
                                this.currentCustomer.type = 'R';

                                this.setOrderType('R');
                                this.setClientId(response.data.id);

                                //zrusim register info
                                this.currentCustomer.password = '';
                                this.currentCustomer.createAccount = false;

                                //ulozim data
                                this.setCustomer(this.currentCustomer);
                                this.setAddressNote(this.addressNote);

                                //presmerujem na dalsi krok
                                if (this.currentCustomer.freeDelivery && this.currentCustomer.type == 'S') {
                                    this.$router.push({ name: 'VehicleInfo' });
                                } else if (this.currentCustomer.freeDelivery) {
                                    this.$router.push({ name: 'PaymentOptions' });
                                } else if (this.currentCustomer.type == 'S') {
                                    this.$router.push({ name: 'DeliveryOptions' });
                                } else {
                                    this.$router.push({ name: 'DeliveryOptions' });
                                }
                            }

                            this.sendingForm = false;
                        });
                    }
                }

                if (response.status == '400') {
                    this.sendingForm = false;

                    this.errors = response.errors;

                    this.sendErrorsToGTM(this.errors, 1, this.currentCustomer.deliveryIsSame);

                    this.showValidationMessages();
                }

                //zmazem hesla
                this.password = null;
                this.loginpass = null;
            });
        },
        loginUser() {
            this.sendingForm = true;

            let params = {
                username: this.currentCustomer.email,
                password: this.loginpass,
            };

            this.$axios.post(process.env.VUE_APP_URL_USER_LOGIN, {}, { auth: params }).then(response => {
                if (response.status == '200') {
                    this.loginGTM();

                    let obj = response.data;
                    let objAdditional = this.copyObj(this.$store.state.base.baseCustomerAdditional);
                    let newObj = Object.assign(obj, objAdditional);

                    //Ak sa nenachadza personType, doplnim ho
                    if (newObj.personType == '') {
                        newObj.personType = newObj.invoiceData.companyName ? 'company' : 'individual';
                    }

                    //odstranim prve dva znaky z vat ak su pismena
                    let letters = /^[a-zA-Z]+$/;
                    if (newObj.invoiceData.vatId) {
                        if (letters.test(newObj.invoiceData.vatId.substring(0, 2))) {
                            newObj.invoiceData.vatId = newObj.invoiceData.vatId.substring(2);
                        }
                    }

                    //zmenim adresu na nie je rovnaka ako invoice
                    //newObj.deliveryIsSame = false

                    //vlozim data do VUEX
                    this.setCustomer(newObj);

                    //nasetujem aktualneho customera
                    this.currentCustomer = newObj;

                    //zrusim loading
                    this.sendingForm = false;

                    //skryjem login form
                    this.showLoginForm = false;

                    //nastavim udaje v objednavke
                    this.setOrderType(newObj.type);
                    this.setClientId(newObj.registerId);
                }

                if (response.status == '404') {
                    this.errors = response.errors;

                    this.sendErrorsToGTM(this.errors, 1);

                    this.showValidationMessages();

                    //zrusim loading
                    this.sendingForm = false;
                }

                //zmazem hesla
                this.password = null;
                this.loginpass = null;
            });
        },
        searchForUser() {
            this.currentCustomer.email = this.currentCustomer.email
                .toLowerCase()
                .trim()
                .replace(/\s/g, '');

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(() => {
                if (this.currentCustomer.email == '' || !this.emailRegExp.test(this.currentCustomer.email)) {
                    this.errors.email = true;
                } else {
                    this.errors.email = null;
                    this.checkIfUserExists();
                }
            }, 500);
        },
        checkIfUserExists() {
            this.loading = true;

            //zresetujem registraciu
            this.password = '';
            this.currentCustomer.password = '';
            this.currentCustomer.createAccount = false;

            //tu si zavolam api na checkUser
            let params = { email: this.currentCustomer.email, lang: this.$i18n.locale, product_id: this.order.order.item.productId };
            this.$axios.get(process.env.VUE_APP_URL_USER_STATUS, { params: params }).then(response => {
                //console.log(response.data)

                if (response.status == '200') {
                    this.currentCustomer.registred = response.data.status;
                    //this.currentCustomer.type = response.data.data.type
                    this.loading = false;
                    this.showCustomerForm = true;

                    this.$refs.email.blur();

                    this.$nextTick(() => {
                        const el = document.querySelector('.next-section');

                        if (el) {
                            el.scrollIntoView({
                                block: 'center',
                                behavior: 'smooth',
                            });
                        }

                        return;
                    });

                    //GTM
                    if (this.currentCustomer.registred) {
                        this.offerLoginGTM();
                    }
                } else {
                    this.loading = false;
                    this.showCustomerForm = true;
                }
            });
        },
        getAddressNoteLength() {
            return this.addressNote.length;
        },
        getInvoiceStreetLength() {
            return this.currentCustomer.invoiceData.street.length;
        },
        getDeliveryStreetLength() {
            return this.currentCustomer.deliveryAddress.street.length;
        },
        getDeliveryPrices() {
            //zresetujem options
            this.setDeliveryMethod('');
            this.setDeliveryOptionsPrices({});

            let params = {
                country: 0,
            };

            if (this.customer.deliveryAddress !== undefined) {
                params.country = this.customer.deliveryAddress.country;
            }

            this.$axios.get(process.env.VUE_APP_URL_ENUM_DELIVERY, { params: params }).then(response => {
                if (response.status == '200') {
                    //ulozim hodnoty do VUEX
                    this.setDeliveryOptionsPrices(response.data);

                    if (this.inmodal && this.currentPath != 'DeliveryOptions') {
                        this.setShowDeliveryModal(true);
                    }
                } else {
                    toast(this.$t('errors.enumsDeliveryNotLoaded'));
                }
            });
        },
        saveDataFromModal() {
            this.sendingForm = true;

            //ak je rovnaka delivery address ako invoice address, prekopirujem hodnoty
            if (this.currentCustomer.deliveryIsSame) {
                this.currentCustomer.deliveryAddress.companyName = this.currentCustomer.invoiceData.companyName;
                this.currentCustomer.deliveryAddress.name = this.currentCustomer.name;
                this.currentCustomer.deliveryAddress.surname = this.currentCustomer.surname;
                this.currentCustomer.deliveryAddress.street = this.currentCustomer.invoiceData.street;
                this.currentCustomer.deliveryAddress.streetNumberNone = this.currentCustomer.invoiceData.streetNumberNone;
                this.currentCustomer.deliveryAddress.postalCode = this.currentCustomer.invoiceData.postalCode;
                this.currentCustomer.deliveryAddress.city = this.currentCustomer.invoiceData.city;
                this.currentCustomer.deliveryAddress.country = this.currentCustomer.invoiceData.country;
            }

            //zduplikujem cislo do delivery
            this.currentCustomer.deliveryAddress.cellPhoneNumber = this.currentCustomer.cellPhoneNumber;
            this.currentCustomer.deliveryAddress.cellPhonePrefix = this.currentCustomer.cellPhonePrefix;

            let validateData = {
                lang: this.$i18n.locale,
                data: this.copyObj(this.currentCustomer),
                fields: {},
            };

            //doplnim prefix do vatId
            if (!this.isOutsideEU && this.currentCustomer.personType == 'company') {
                let vatId = this.selectedInvoiceCountry.vatCode + this.currentCustomer.invoiceData.vatId;
                validateData.data.invoiceData.vatId = vatId;
            }

            //validujem user data
            this.$axios.post(process.env.VUE_APP_URL_USER_VALIDATE, validateData).then(response => {
                this.sendingForm = false;

                if (response.data.status == '200') {
                    this.setCustomer(this.currentCustomer);
                    this.setAddressNote(this.addressNote);
                    this.errors = {};
                    this.setShowAddressModal(false);
                }

                if (response.data.status == '400') {
                    this.errors = response.data.errors;

                    this.showValidationMessages();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.text-small-label {
    color: $lightGray;
    font-size: 12px;
    line-height: 15px;
}

.login-form {
    background: $selectBg;
    padding: 24px;
    display: block;
    position: relative;
    @include border-radius($rad);
}

.login-form__headline {
    color: $orange;
    font-size: 18px;
    font-weight: $fwBold;
    margin-bottom: 16px;
}

.login-form__close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 24px;
    right: 24px;
    cursor: pointer;
    background-color: $ultraLightGray;
    -webkit-mask: url($basePath+'icons/modal-close.svg') no-repeat center;
    mask: url($basePath+'icons/modal-close.svg') no-repeat center;
    @include transition();

    &:hover {
        @include rotate(90deg);
    }
}

.login-form__link {
    color: $blue;
    font-size: 13px;
    height: 100%;

    @include less-than(md) {
        line-height: 16px;
    }
}

.vat-prefix {
    position: absolute;
    left: 16px;
    top: 45px;
}

.info-box__signin {
    display: flex;

    @include less-than(md) {
        display: block;
    }
}
</style>
