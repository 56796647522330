<template>
    <div>
        <section class="main-section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 pt-0 pt-md-32 text-center">
                        <!-- HEADLINE -->
                        <head-title class="mb-8">
                            <span class="text-orange">{{ $t('thankyou.headline') }}</span>
                        </head-title>
                        <span class="order">
                            {{ $t('payment.orderNumber') }}:
                            <i>{{ orderId ? orderId : order_id }}</i>
                        </span>

                        <p class="mt-32">
                            {{ $t('thankyou.processing') }}
                            <br />
                            {{ $t('thankyou.sentOrderConfirm', { email: customer.email ? customer.email : customer_email }) }}
                            <br />
                            {{ $t('thankyou.updated') }}
                            <br />
                        </p>

                        <div class="d-flex justify-content-center">
                            <form-button class="mt-32 --secondary" @click.prevent="goToHomePage()">
                                {{ $t('thankyou.placeAnotherOrder') }}
                            </form-button>
                            <form-button class="ml-16 mt-32" v-if="customer.type != 'N'" @click.prevent="goToPrivateZone()">
                                {{ $t('thankyou.seeAllMyOrders') }}
                            </form-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';

import HeadTitle from '@/components/headings/HeadTitle.vue';
import FormButton from '@/components/form/FormButton.vue';

export default {
    name: 'PaymentInfo',
    props: ['order_id', 'customer_email'],
    components: {
        HeadTitle,
        FormButton,
    },
    data() {
        return {};
    },
    mounted() {
        this.setCurrentStep(5);
        this.setCurrentPath('ThankYou');

        if (this.laterPayment) {
            this.baseGTM('Thank You');
        } else if (this.customer.type == 'S') {
            this.gtmThankYouCustomerS('Thank You');
        } else {
            this.gtmThankYou('Thank You');
        }

        // console.log(this.order_id)
        // console.log(this.customer)
    },
    created() {},
    computed: {
        ...mapState(['orderId']),
        ...mapState('order', ['order']),
        ...mapState('customer', ['customer']),
    },
    methods: {
        ...mapMutations(['setCurrentStep', 'setCurrentPath']),
        goToHomePage() {
            if (this.customer.registerId) {
                window.location.href = `https://my.eurococ.eu/${this.$i18n.locale}/myzone/new-order?registerId=${this.customer.registerId}`;
            } else {
                window.location.href = process.env.VUE_APP_BASE_URL + this.$i18n.locale;
            }
        },
        goToPrivateZone() {
            window.location.href = process.env.VUE_APP_PRIVATE_ZONE;
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    margin-top: 64px;
}

.text-orange {
    color: $orange;
}

.order {
    font-size: 18px;
    line-height: 27px;
    color: $darkGray;

    i {
        font-style: normal;
        color: $orange;
    }
}

p {
    font-size: 16px;
    line-height: 24px;
}
</style>
