import { useToast } from 'vue-toastification';

const toast = useToast();

export default class KlarnaService {
    apiCreateSession = 'https://lara-api.eurococ.eu/shop-api/klarna/payment/createSession';
    apiUpdateSession = 'https://lara-api.eurococ.eu/shop-api/klarna/payment/updateSession';
    apiCreateOrder = 'https://lara-api.eurococ.eu/shop-api/klarna/payment/createOrder';
    klarnaSDK = 'https://x.klarnacdn.net/kp/lib/v1/api.js';
    thankYouPage = `${process.env.VUE_APP_BASE_URL}${window.locale}/checkout/thank-you`;

    constructor(obj) {
        this.order = obj.order;
        this.orderId = obj.orderId;
    }

    async createSession(updateSession) {
        if (!updateSession && window.klarna) return;

        const pluck = (arr, key) => arr.map(i => i[key]);

        let order = this.order;

        if (updateSession) {
            order = {
                session_id: window.sessionStorage.getItem('klarna_session_id'),
                order: this.order.order,
            };
        }

        let response = await window.$axios.post(updateSession ? this.apiUpdateSession : this.apiCreateSession, order);

        if (!response.success) {
            toast(`ErrorCode ${response.httpCode}`);
            return;
        }

        let responseData = response.responseData;

        if (responseData !== null) {
            window.klarna = {
                paymentSession: responseData.session_id,
                clientToken: responseData.client_token,
                paymentMethods: pluck(responseData.payment_method_categories, 'identifier'),
                orderData: response.orderData,
            };
        }

        if (updateSession) {
            window.klarna.orderData = this.order.order;
        }

        if (!updateSession) {
            window.sessionStorage.setItem('klarna_session_id', responseData.session_id);
        }

        return true;
    }

    getPaymentOptions() {
        let availableMethods = [];

        for (let i = 0; i < window.klarna.paymentMethods.length; i++) {
            let method = window.klarna.paymentMethods[i];

            availableMethods.push({
                name: `payment.klarna_${method}`,
                value: `klarna_${method}`,
                badge: '',
                logo: 'klarna.svg',
                info: '',
            });
        }

        return availableMethods;
    }

    async initKlarnaSDK(method) {
        // The following method initializes the Klarna Payments JS library

        window.klarnaAsyncCallback = function() {
            Klarna.Payments.init({
                client_token: window.klarna.clientToken,
            });
            console.log('Payments initialized');

            //The following method loads the payment_method_category in the container with the id of 'klarna_container'
            Klarna.Payments.load(
                {
                    container: '#klarna_container',
                    payment_method_category: method,
                },
                function(res) {
                    console.log('Load function called');
                    console.debug(res);
                }
            );
        };

        if (!this.isScriptAlreadyInDOM()) {
            const script = document.createElement('script');
            script.src = this.klarnaSDK;
            document.body.appendChild(script);
        }
    }

    async authorize(method, messages) {
        let self = this;

        Klarna.Payments.authorize(
            {
                payment_method_category: method,
            },
            window.klarna.orderData,

            async function(res) {
                if (!res.authorization_token) {
                    // toast(messages.authFailed);
                }

                window.klarna.orderData['merchant_urls'] = {
                    confirmation: self.thankYouPage,
                };

                self.createOrder(res, messages);

                if (!res.authorization_token) {
                    window.$store.commit('setKlarnaFailed', true);
                    return false;
                }
            }
        );
    }

    async createOrder(res, messages) {
        let r = await window.$axios.post(
            this.apiCreateOrder,
            {
                token: res.authorization_token,
                orderId: this.orderId,
                data: window.klarna.orderData,
            },
            {
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
            }
        );

        if (!r.success) {
            // toast(messages.orderFailed);
            window.$store.commit('setKlarnaFailed', true);
            return 'failed';
        }

        toast(messages.success);

        var redirect_url = r.data.redirect_url;
        window.location.href = redirect_url;
    }

    isScriptAlreadyInDOM() {
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--; ) {
            if (scripts[i].src == this.klarnaSDK) return true;
        }
        return false;
    }
}
