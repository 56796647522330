<template>
    <modal :show-modal="showEmailModal" @close="setShowEmailModal(false)">
        <template v-slot:header>
            {{ $t("customer.emailChange") }}
        </template>

        <template v-slot:body>
            <!-- EMAIL -->
            <form-group :loading="loading" class="mb-0 mt-0" :class="{ '--invalid': issetObj('email', errors) }">
                <form-label>
                    {{ $t("customer.yourEmail") }}
                </form-label>
                <input type="email" class="form-input" v-model="email" />
                <form-error-message :errors="errors.base" field="email"></form-error-message>
            </form-group>

            <!-- BTN -->
            <div class="d-flex justify-content-end">
                <form-button class="--secondary mt-24" @click="changeEmail()">
                    {{ $t("customer.changeEmail") }}
                </form-button>
            </div>
        </template>
    </modal>
</template>

<script>
import { mapState } from "vuex"
import { mapMutations } from "vuex"

import FormGroup from "@/components/form/FormGroup.vue"
import FormLabel from "@/components/form/FormLabel.vue"
import FormErrorMessage from "@/components/form/FormErrorMessage.vue"
import FormButton from "@/components/form/FormButton.vue"

import Modal from "./Modal"

export default {
    name: "ModalDeliveryOptions",
    components: {
        Modal,
        FormGroup,
        FormLabel,
        FormErrorMessage,
        FormButton
    },
    data() {
        return {
            errors: {},
            emailRegExp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            loading: false,
            email: ""
        }
    },
    methods: {
        ...mapMutations("customer", ["setEmail"]),
        ...mapMutations(["setShowEmailModal"]),
        changeEmail() {
            if (this.emailRegExp.test(this.email)) {
                this.setEmail(this.email)
                this.setShowEmailModal(false)
                this.errors = {}
            } else {
                this.errors = { email: "" }
            }
        }
    },
    computed: {
        ...mapState("customer", ["customer"]),
        ...mapState(["showEmailModal"])
    },
    watch: {
        showEmailModal(newValue) {
            if (newValue == true) {
                this.email = this.customer.email
            }
        }
    }
}
</script>
