// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/icons/car-manual.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-section[data-v-1448f216]{margin-top:64px}.text-small-label[data-v-1448f216]{color:#6a7084;font-size:12px;line-height:15px}.form-group[data-v-1448f216]{transition:all .6s cubic-bezier(.22,1,.36,1)}.--inactive[data-v-1448f216]{pointer-events:none;filter:alpha(opacity=0.4);-moz-opacity:.4;-khtml-opacity:.4;opacity:.4}.car-manual[data-v-1448f216]{position:relative;padding:24px 48px 24px 24px;background:#fff!important;border:1px solid #ffd79a;border-radius:4px;display:flex;align-items:center;gap:24px}.car-manual__image[data-v-1448f216]{width:120px;height:120px;min-width:120px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain}.car-manual__content[data-v-1448f216]{display:flex;flex-direction:column;gap:16px}.car-manual__content>span[data-v-1448f216]{font-size:14px;line-height:1.4}", ""]);
// Exports
module.exports = exports;
