<template>
    <form id="hipay-form">
        <div id="hipay-hostedfields-form"></div>
        <br />
        <button type="submit" id="hipay-submit-button" class="hipay-submit-btn --primary" :class="{ '--disabled': submitDisabled }" disabled="true">
            {{ $t("base.pay") }}
        </button>
        <div id="hipay-error-message"></div>
    </form>
</template>

<script>
import { mapState } from "vuex"

export default {
    name: "HiPayForm",
    components: {},
    data() {
        return {
            submitDisabled: true
        }
    },
    mounted() {
        //console.log(window.HiPay)

        setTimeout(() => {
            if (!this.isMyScriptLoaded("https://libs.hipay.com/js/sdkjs.js")) {
                const script = document.createElement("script")
                script.src = "https://libs.hipay.com/js/sdkjs.js"
                script.id = "hipay_script"
                script.addEventListener("load", this.setLoaded)
                document.body.appendChild(script)
            } else {
                var elem = document.getElementById("hipay_script")
                elem.remove()

                const script = document.createElement("script")
                script.src = "https://libs.hipay.com/js/sdkjs.js"
                script.id = "hipay_script"
                script.addEventListener("load", this.setLoaded)
                document.body.appendChild(script)
            }
        }, 1000)
    },
    created() {},
    computed: {
        ...mapState(["orderId"])
    },
    methods: {
        isMyScriptLoaded(url) {
            var scripts = document.getElementsByTagName("script")
            for (var i = scripts.length; i--; ) {
                if (scripts[i].src == url) return true
            }
            return false
        },
        setLoaded() {
            let self = this

            let user = process.env.VUE_APP_HIPAY_USER
            let pass = process.env.VUE_APP_HIPAY_PASS

            let hipay = window.HiPay({
                username: user,
                password: pass,
                environment: "production",
                lang: self.$i18n.locale
            })

            let config = {
                template: "auto",
                selector: "hipay-hostedfields-form",
                styles: {
                    base: {
                        // default field styling
                        color: "#212a4b",
                        fontSize: "15px",
                        fontWeight: 400,
                        placeholderColor: "#999999",
                        iconColor: "#999",
                        caretColor: "#000"
                    },
                    invalid: {
                        // invalid field styling
                        color: "#ba051b",
                        caretColor: "#ba051b"
                    }
                }
            }

            let cardInstance = hipay.create("card", config)

            cardInstance.on("change", function(event) {
                console.log(event)
                /* Display error(s), if any */
                document.getElementById("hipay-error-message").innerHTML = event.error
                /* Enable / disable submit button */
                document.getElementById("hipay-submit-button").disabled = !event.valid

                if (event.valid == false) {
                    self.submitDisabled = true
                } else {
                    self.submitDisabled = false
                }
            })

            /* Get the form */
            let cardForm = document.getElementById("hipay-form")

            /* Add event listener on the submit button when clicked */
            cardForm.addEventListener("submit", function(event) {
                event.preventDefault()
                /* Tokenize your card information when the submit button is clicked */
                cardInstance.getPaymentData().then(
                    function(response) {
                        let bi = window.btoa(new URLSearchParams(response.browser_info).toString())
                        let redirect_url = "/payment/hipay-pay.php?t=" + response.token + "&oid=" + self.orderId + "&bi=" + bi + "&lng=" + self.$i18n.locale

                        location.href = redirect_url
                    },

                    function(errors) {
                        /* Display first error */
                        document.getElementById("hipay-error-message").innerHTML = errors[0].error
                    }
                )
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.hipay-submit-btn {
    cursor: pointer;
    border: 0;
    width: auto;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include transition();
    @include appearance(none);

    &.--disabled {
        pointer-events: none;
        @include opacity(0.5);
    }

    &.--primary {
        color: $white;
        background: $green;
        text-transform: uppercase;
        font-weight: $fwSemi;
        padding: 0 32px;
        @include border-radius($rad);

        @include less-than(md) {
            display: flex;
            width: 100%;
        }

        &:hover {
            background: $darkGray;
        }
    }
}
</style>
