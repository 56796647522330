import axios from 'axios';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

$axios.interceptors.request.use(function(request) {
    // window.$store.commit('setCurrentApiState', 'loading');

    return request;
});

const errorInterceptor = error => {
    return Promise.reject(error);
};

const responseInterceptor = response => {
    // window.$store.commit('setCurrentApiState', null);

    return response.data;
};

$axios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default $axios;
