// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/base/logo-en.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/base/logo-fr.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/images/base/logo-de.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/images/base/logo-it.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@/assets/images/base/logo-es.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".header__wrapper[data-v-4838c66a]{display:flex;align-items:center;padding:32px 0}@media(max-width:1023.9px){.header__wrapper[data-v-4838c66a]{padding:12px 16px;position:fixed;width:100%;top:0;left:0;background:#fff;z-index:100}.header__wrapper.--shadow[data-v-4838c66a]{box-shadow:0 0 16px 0 rgba(0,0,0,.1)}}.header__logo[data-v-4838c66a]{width:165px;height:103px;display:block;margin-right:45px;background-repeat:no-repeat;background-position:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.header__logo.--fr[data-v-4838c66a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.header__logo.--de[data-v-4838c66a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.header__logo.--it[data-v-4838c66a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.header__logo.--es[data-v-4838c66a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}@media(max-width:1023.9px){.header__logo[data-v-4838c66a]{width:76px;height:49px;background-size:100% auto;margin-right:12px}}.header__title[data-v-4838c66a]{font-weight:600;font-size:36px;line-height:54px;margin:0;padding:0;color:#091647}@media(max-width:1023.9px){.header__title[data-v-4838c66a]{font-size:18px;line-height:27px}}", ""]);
// Exports
module.exports = exports;
